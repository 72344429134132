import {HotspotService} from './hotspot-service';
export default class UploadDestinationServiceV2 extends HotspotService{

  async getUploadDestination() {
    try {
      let response = await this.axios.request({
      method: "get",
      url: `${this.API_BASE_URL}/api/v1/uploadDestination`
    });
    return response.data;
    } catch (error) {
      return(error);
    }
  }

  async insertUploadDestination(payload) {
    try {
      let response = await this.axios.request({
      method: "post",
      url: `${this.API_BASE_URL}/api/v1/uploadDestination`,
      data: {...payload},
    });
    return response.data;
    } catch (error) {
      return(error);
    }
  }
}
