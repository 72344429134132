<template>
  <transition name="modal commonModal">
    <div class="modal-mask" id="AddUploadDestination">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body" 
          :class="isLoading ? 'loading' : ''"
            :style="[
              isLoading
                ? { 'background-image': 'url(' + loadingIcon + ')' }
                : '',
            ]"
            >
            <slot name="body">
              <div class="uploadDestinationList">
                <h5>{{ $t("ADD_IMPORT_DESTINATION") }}</h5>
                <Form
                  @submit="addUploadDestination"
                  :validation-schema="validateRules"
                  v-slot="{ errors }"
                >
                  <div class="formBody">
                    <div class="col-12 p-0">
                      <p class="controlHeader">{{ $t("NAME") }}</p>
                      <div class="p-inputgroup">
                        <span
                          :class="`p-float-label ${
                            errors.name ? 'error_label' : ''
                          }`"
                        >
                          <Field
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Name"
                            v-model="name"
                            :class="`form-control ${name ? 'p-filled' : ''} `"
                          />
                          <label for="name" class="placeholder">
                            {{ $t("NAME") }}
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="col-12 p-0">
                      <p class="controlHeader">{{ $t("DESCRIPTION") }}</p>
                      <div class="p-inputgroup">
                        <span
                          :class="`p-float-label ${
                            errors.description ? 'error_label' : ''
                          }`"
                        >
                          <Field
                            name="description"
                            id="description"
                            type="text"
                            placeholder="Description"
                            v-model="description"
                            :class="`form-control ${
                              description ? 'p-filled' : ''
                            } `"
                          />
                          <label for="description" class="placeholder">
                            {{ $t("DESCRIPTION") }}
                          </label>
                        </span>
                      </div>
                      <p class="optionalLable">{{ $t("OPTIONAL") }}</p>
                    </div>
                    <div class="col-12 p-0">
                      <p class="controlHeader">
                        {{ $t("CLOUD_DESTINATION") }}
                      </p>
                      <div class="p-inputgroup">
                        <span
                          :class="`p-float-label ${
                            errors.selectedDestination ? 'error_label' : ''
                          }`"
                        >
                          <Field
                            v-slot="{ field }"
                            v-model="selectedDestination"
                            name="selectedDestination"
                            value="value"
                          >
                            <Dropdown
                              id="cboSelectDestination"
                              name="selectedDestination"
                              v-model="selectedDestination"
                              :options="cloudDestination"
                              optionLabel="name"
                              :model-value="field.value"
                              @change="
                                getSelectedCloudRegion(selectedDestination),
                                  field.onChange.forEach((fn) =>
                                    fn($event.value)
                                  )
                              "
                              @input="
                                field.onInput.forEach((fn) => fn($event.value))
                              "
                              :class="{
                                'p-filled': errors.selectedDestination,
                              }"
                            />
                          </Field>
                          <label for="cboSelectDestination" class="placeholder">
                            {{ $t("PLEASE_SELECT") }}
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="col-12 p-0">
                      <p class="controlHeader">{{ $t("REGION") }}</p>
                      <div class="p-inputgroup">
                        <span
                          :class="`p-float-label ${
                            errors.selectedRegion ? 'error_label' : ''
                          }`"
                        >
                          <Field
                            v-slot="{ field }"
                            v-model="selectedRegion"
                            name="selectedRegion"
                            value="value"
                          >
                            <Dropdown
                              id="cboSelectRegion"
                              name="selectedRegion"
                              v-model="selectedRegion"
                              :options="cloudRegion"
                              optionLabel="value"
                              :model-value="field.value"
                              @change="
                                field.onChange.forEach((fn) => fn($event.value))
                              "
                              :class="{ 'p-filled': errors.selectedRegion }"
                              @input="
                                field.onInput.forEach((fn) => fn($event.value))
                              "
                            />
                          </Field>

                          <label for="cboSelectRegion" class="placeholder">
                            {{ $t("PLEASE_SELECT") }}
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="col-12 mt-20 mb-0 p-0">
                      <button class="btn btn-seagate-primary">
                        {{ $t("GLOAL_SAVE") }}
                      </button>
                      <button
                        class="btn btn-outline-secondary"
                        @click="$emit('close'), windowOverflow()"
                      >
                        {{ $t("CANCEL") }}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Dropdown from "primevue/dropdown";
import * as yup from "yup";
import { Form, Field } from "vee-validate";
import UploadDestinationServiceV2 from "../../services/uploadDestination-service-v2.js";
export default {
  components: {
    Dropdown,
    Form,
    Field,
  },
  emits: ["close", "openPopup"],
  setup() {
    const validateRules = yup.object().shape({
      name: yup.string().required().max(30),
      selectedDestination: yup.object().required(),
      selectedRegion: yup.object().required(),
      description: yup.string().min(0).max(300),
    });
    return {
      validateRules,
    };
  },
  data() {
    return {
      name: "",
      description: "",
      cloudRegion: [],
      selectedDestination: null,
      selectedRegion: null,
      descriptionRules: yup
        .string()
        .notRequired()
        .when("descriptionRules", {
          is: (value) => value.length,
          then: (rule) => rule.max(3),
        }),
      isLoading: false, // Add isLoading data property
      loadingIcon: `${this.$CDN_URL}images/loader.gif`,
    };
  },
  methods: {
    async addUploadDestination() {
      this.isLoading = true;
      let uploaddestination = {
        uploadDestination: {
          name: this.name,
          destination: this.selectedDestination.name,
          region: this.selectedRegion.value,
          description: this.description,
        },
      };
      let getDetails = {
        uploadDestination: {
          name: this.name,
          destination: this.selectedDestination,
          region: this.selectedRegion,
          description: this.description,
        },
      };
      this.$store.commit('getEditUploadDestionation', getDetails)
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let uploadDestinationServiceV2 = new UploadDestinationServiceV2(user);
      let message = "";
      await uploadDestinationServiceV2
        .insertUploadDestination(uploaddestination)
        .then((data) => {
          if (
            data.messages[0] ===
            "UploadDestination details created successfully"
          ) {
            let payload = {
              name: data.data.name,
              destination: data.data.destination,
              region: data.data.region,
              description: data.data.description,
              image: data.data.image ? data.data.image: ''
            }
            this.$store.commit("updateUploadDestination", payload);
            message = {
              severity: "info",
              content:
                this.$t("CLOUD_DESTINATION_SAVED"),
            };
            this.$store.commit("setResponseMessage", message);
            this.$emit("close");
            this.windowOverflow();
            this.$emit("openPopup");
          } else {
            message = {
              severity: "error",
              content: data.messages[0],
            };
            this.$store.commit("setResponseMessage", message);
            this.$emit("close");
            this.windowOverflow();
            this.$emit("openPopup");
          }
        })
        .catch(() => {
          message = {
            severity: "error",
            content: this.$t("ERROR-DIALOG-BODY"),
          };
          this.$store.commit("setResponseMessage", message);
          this.$emit("close");
          this.$emit("openPopup");
          this.windowOverflow();
        });
    },
    getSelectedCloudRegion(selectedDestination) {
      let cloudArray = this.$store.state.masterConfigurationData.data.filter(
        (project) => project.name === selectedDestination.name
      );
      for (var i = 0; i < cloudArray.length; i++) {
        this.cloudRegion = cloudArray[i].regions;
      }
    },
    windowOverflow() {
      document.body.style.overflowY = "scroll";
    },
  },
  async mounted() {
    if(this.editUploadDestination){
        let storeData = this.editUploadDestination;
        this.name = storeData.name;
        this.description = storeData.description;
        this.selectedDestination = storeData.destination;
        this.selectedRegion = storeData.region;
    }
      document.body.style.overflow = "hidden";
  },
  watch: {
    selectedDestination() {
      this.getSelectedCloudRegion(this.selectedDestination);
    },
  },
  computed: {
    cloudDestination() {
      return this.$store.state.masterConfigurationData.data;
    },
    editUploadDestination(){
      return this.$store.state.showUploadDestinationDetails.uploadDestination
    }
  },
};
</script>
<style lang="scss" scoped>
.formBody {
	width: 100%;
}
.p-dropdown-panel.p-component {
	z-index: 99999 !important;
}
.minHeightRow {
	#addUploadDestinationModal {
		.p-dropdown {
			.p-dropdown-label {
				padding: 0.5rem 0 0 !important;
			}
		}
	}
}
.required {
	&::before {
		content: " * ";
		color: $red_color;
	}
}
.input {
	.hasError {
		border-color: $red_color;
	}
}
.p-dropdown {
	.hasError {
		border-color: $red_color;
	}
}
.is-invalid {
	border: 1px solid $red_color !important;
}
.closePopup {
	.bi {
		font-size: 25px;
		margin-top: -15px;
		display: inline-block;
	}
}
.optionalLable {
	font-size: 11px;
	margin-top: -6px;
	padding-left: 15px;
	color: $option_label_color;
}
#rightContent {
	.p-dropdown {
		&::v-deep {
			.p-dropdown-trigger {
				background: $transparent_color !important;
				color: $transparent_color !important;
			}
		}
	}
}
.modal-body {
	margin: 0px auto;
	padding: 0px !important;
}
.modal-container {
	width: 600px;
	margin: 50px auto;
	position: relative;
}
.modal-wrapper {
	height: 100vh;
	overflow-y: auto;
	display: block;
}
.modal-body.loading {
  opacity: 0.5;
  z-index: 10000;
  pointer-events: none;
  background-repeat: no-repeat;
  background-position: center center;
}

.loader {
  text-align: center;
}
</style>