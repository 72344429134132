<template>
  <div>
    <ConfirmationLogout
      v-if="openModal"
      @openPopup="openModal = false"
    />
  </div>
</template>

<script>
const config = require("@/services/settings.json");
import ConfirmationLogout from "./ConfirmationLogout.vue";

export default {
  name: "AutoLogout",
  data: function () {
    return {
      events: ["click", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      showWarning: false,
      warningTime:
        config.SessionTimeout * 60 * 1000 - config.WarningSessionTimeout * 60 * 1000,
      WarningSessionTimeout: config.WarningSessionTimeout,
      SessionTimeout: config.SessionTimeout,
      openModal: false
    };
  },
  components: {
    ConfirmationLogout
  },
  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimers);
    });
    this.setTimers();
  },
  unmounted() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimers);
    });
    this.resetTimers();
  },
  methods: {
    resetTimers: function () {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
    setTimers: function () {
      this.warningTimer = setTimeout(
        this.warningMessage,
        this.WarningSessionTimeout * 60 * 1000
      );
      this.logoutTimer = setTimeout(
        this.logoutUser,
        this.SessionTimeout * 60 * 1000
      );
      this.showWarning = false;
    },
    warningMessage: function () {
      this.showWarning = true;
      this.openModal = true;
    },
    logoutUser: async function () {
      this.setCookie("logoutFromHS", true, 500, '.seagate.com');
      await this.$auth.logout();
    },
    setCookie(cname, cvalue, exdays, domain) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        if(domain) {
          document.cookie = cname + "=" + cvalue + ";" + (exdays === 0 ? '' : expires) + ";path=/;domain=" + domain;
        } else {
          document.cookie = cname + "=" + cvalue + ";" + (exdays === 0 ? '' : expires) + ";path=/";
        }
    }
  },
};
</script>
