<template>
  <div>
    <div class="col-12 pb-2 pl-4 wrapper">
      <h5 class="font-size-16 font-weight-bold">
        {{ $t("PREREQUISITES_STEP") }}
      </h5>
      <p class="font-size-14">
        {{ $t("PREREQUISITES_DESC") }}
      </p>
    </div>
    <div class="col-12 pt-4 pb-2 pl-4 wrapper d-flex">
      <h5 class="font-size-16 font-weight-medium greenText">
        {{ $t("STEP_1") }}
      </h5>
      <p class="font-size-14 ml-10">
        {{ $t("STEP_1_DESC") }}
      </p>
    </div>
    <div class="col-12 pt-2 pb-2 pl-4 wrapper d-flex">
      <h5 class="font-size-16 font-weight-medium greenText">
        {{ $t("STEP_2") }}
      </h5>
      <p class="font-size-14 ml-10">
        {{ $t("STEP_2_DESC") }}
      </p>
    </div>
    <div class="col-12 pt-2 pb-2 pl-4 wrapper d-flex">
      <h5 class="font-size-16 font-weight-medium greenText">
        {{ $t("STEP_3") }}
      </h5>
      <p class="font-size-14 ml-10">
        {{ $t("STEP_3_DESC") }}
      </p>
    </div>
    <div class="col-12 pt-2 pb-4 pl-4 wrapper d-flex">
      <h5 class="font-size-16 font-weight-medium greenText">
        {{ $t("STEP_4") }}
      </h5>
      <p class="font-size-14 ml-10">
        {{ $t("STEP_4_DESC") }}
      </p>
    </div>
    <div class="col-12 pt-2 pb-2 pl-4 wrapper d-flex">
      <h5 class="font-size-16 font-weight-bold">
        {{ $t("INFORMATION_HEADING") }}
      </h5>
    </div>
    <div class="col-12 pt-1 pb-1 pl-4">
      <span>{{ $t("AWS_ROLE_NAME") }}: </span>
      <span>{{ awsRoleName }}</span>
    </div>
    <div class="col-12 pt-1 pb-1 pl-4">
      <span>{{ $t("LYVE_ACCOUNT_ID") }}: </span>
      <span>{{ s3AccountId }}</span>
    </div>
    <div class="col-12 pt-1 pb-1 pl-4">
      <span>{{ $t("EXTERNAL_ID") }}: </span>
      <span>{{ $store.state.selectedProject.projectID }}</span>
    </div>
    <div class="col-12 pt-1 pb-1 pl-4">
      <span>{{ $t("YOUR_S3_ACCOUNT_ID") }}</span>
    </div>
    <div class="col-12 pt-1 pb-1 pl-4">
      <span>{{ $t("YOUR_S3_BUCKET_NAME") }}</span>
    </div>
    <div class="col-12 pt-1 pb-1 pl-4">
      <span>{{ $t("YOUR_DESTINATION_FOLDER_NAME") }}</span>
    </div>

    <div class="col-12 pt-4 pb-2 pl-4 wrapper d-flex">
      <p class="font-size-14" id="cloudManualGuide">
        {{ $t("CLOUD_IMPORT_SERVICE_USER_GUIDE_1")}}
        <b><a class="font-size-14 font-weight-bold" target="_blank" :href="cloud_guide_url">{{ $t("CLOUD_IMPORT_SERVICE_USER_GUIDE_2") }}.
            <img
              class="ml-0"
              :src="`${$CDN_URL}images/OpenNewWindow.svg`"
              width="14" />
          </a>
        </b>
      </p>
    </div>

    <div class="col-12 pt-4 pb-2 pl-4 wrapper">
      <p class="font-size-14 informationicondetails d-flex">
        <i class="bi bi-info-circle fs-4"></i>
        <span class="ml-10">{{ $t("IAM_ROLE_OR_POLLICIES") }}</span>
      </p>
    </div>

    <div class="col-12 pt-4 pb-2 pl-4 wrapper">
      <div class="form-check">
        <input
          id="prequisitescheck"
          type="checkbox"
          v-model="validateBtnShow"
          class="custom-control-input"
          @change="nextDisabled = !nextDisabled"
        />
        <label
          class="custom-control-label prequisitescheck ml-10 font-size-14"
          for="prequisitescheck"
        >
          {{ $t("PREREQUISITES_CHECK_BOX_TEXT") }}</label
        >
      </div>
    </div>

    <div class="col-12 pt-2 pb-2 pl-4 wrapper prerequisites">
      <div class="col-6 mt-20 mb-0 p-0">
        <button :disabled="nextDisabled" class="btn btn-seagate-primary" @click="addImportDestination()">
          {{ $t("NEXT") }}
        </button>
        <button class="btn btn-outline-secondary" @click="closeModel()">
          {{ $t("CANCEL") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
const config = require("@/services/settings.json");
export default {
  data() {
    return {
      awsRoleName: process.env.VUE_APP_AWS_ROLE,
      s3AccountId: process.env.VUE_APP_S3_ACCOUNT_ID,
      nextDisabled: true,
      cloud_guide_url: config.CLOUD_IMPORT_SERVICE_GUIDE
    };
  },
  methods: {
    closeModel() {
      this.$emit("closeSubmitModel", { position: (this.showModal = false) });
    },
    addImportDestination() {
      this.$store.commit(
        "setCurrentStep",
        eval(this.$store.state.currentStep + 1)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
#cloudManualGuide {
  max-width: 80%;
}
.informationicondetails {
  background: $border-color_1 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 10px;
  width: 470px;

}
.informationicondetails i {
  font-size: 18px;
  margin-top: -4px;
}
.prequisitescheck {
  line-height: 24px;
}
</style>
