import {HotspotService} from './hotspot-service';
export default class EnterCredentialsServiceV2 extends HotspotService{
  async getValidateCredentials(payload) {
    try {
      let response = await this.axios.request({
        method: "POST",
        url: `${this.API_BASE_URL}/api/v1/Credential/Validate`,
        data: { payload }
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async saveConfigurePlan(payload) {
    try {
      let response = await this.axios.request({
        method: "put",
        url: `${this.API_BASE_URL}/api/v1/Plan/BucketInfo`,
        data: { payload }
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async submitConfigurePlanStep2(payload) {
      try {
        let response = await this.axios.request({
          method: "put",
          url: `${this.API_BASE_URL}/api/v1/Plan/Status`,
          data: { payload }
        });
        return response.data;
      } catch (error) {
        return(error);
      }
  }

  async getConfigPlanPrefillData(planId) {
    try {
      let response = await this.axios.request({
        method: "get",
        url: `${this.API_BASE_URL}/api/v1/Credentials`,
        params:{planId}
      });
      return response.data;
    } catch (error) {
      return(error)
    }
  }
}