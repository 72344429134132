<template>
  <!-- eslint-disable -->
  <section
    id="mainContent"
    class="innerPages createWorkFlowWrapper"
    v-if="isLoading"
  >
    <div class="loading">
      <img :src="`${$CDN_URL}images/loader.gif`" />
    </div>
  </section>
  <section id="mainContent" class="innerPages createWorkFlowWrapper" v-else>
    <div v-if="!(this.$route.query.newProject && this.$route.query.newProject == 'true') && this.projects.length <= 0">
      <ImportPlans />
    </div>
    <div v-else>
      <div class="mainHeadingWrapper">
        <div class="container-fluid">
          <div class="col-lg-12 col-md-12 pt-33 pb-33 pl-0 text-left">
            <h3>{{ $t("YOUR_IMPORT_PLANS") }}</h3>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div id="rightContent">
          <div
            id="minHeightRow"
            class="row d-flex justify-content-between myHomePage"
          >
            <div class="col-md-12 mb-0">
              <div class="configureShipping">
                <div class="mb-25 row" v-if="projects.length"><ViewProjects /></div>
                <div class="mb-50 row" v-if="projects.length"><ViewEndPoints /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  <script>
import ViewEndPoints from "../components/end-point/ViewEndPoints";
import ViewProjects from "../components/project/ViewProjects";
import ImportPlans from "../views/ImportPlans.vue";
import ProjectServiceV2 from "../services/project-service-v2.js";

export default {
  components: {
    ViewProjects,
    ViewEndPoints,
    ImportPlans
  },
  data() {
    return {
      isLoading: true,
      projects:[]
    };
  },
  async mounted() {
    try {
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      if (accessToken) {
        let user = { token: accessToken };
        let projectServiceV2 = new ProjectServiceV2(user);
        projectServiceV2.getProjects().then((data) => {
          if (data.code === "200" && data.type === "Success") {
            this.$store.commit("setProjects", data.data);
            this.projects = data.data;
            this.isLoading = false;
          }
        });
      } else {
        window.location.href = process.env.VUE_APP_LYVE_URL;
      }
    } catch (error) {
      window.location.href = process.env.VUE_APP_LYVE_URL;
    }
  }
};
</script>
<style lang="scss" scoped>
.loading {
	min-height: 71vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
