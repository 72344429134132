<template>
  <div class="actionButton" v-if="availableStatus.includes(status)">
    <div v-if="progressStatus.includes(status)" class="spinner-wrapper">
      <img :src="loadingIcon" class="status-spinner" />
    </div>
    <div v-else>
      <input
        class="btn"
        :class="getActionClass(status)"
        type="button"
        @click="getButtonAction(status)"
        :value="getActionLabel(status)"
        :disabled="isDisabled"
      />
    </div>
  </div>
  <div v-else>&nbsp;</div>
</template>
<script>
import PlanConnectivityServiceV2 from "../services/planConnectivityServiceV2.js";
import errorHandler from "@/utils/errorHandler/errorHandler";
export default {
  props: {
    status: {
      type: String,
      default: "",
    },
    trackingId: {
      type: String,
    },
    planID: {
      type: Object,
    },
  },
  data() {
    return {
      isDisabled: false,
      availableStatus: [
        "Ready to Return",
        "Cloud Validation",
        "Validation Successful",
        "Waiting for Pickup",
        "In Transit",
        "Received",
        "In Queue",
        "In Progress",
        "Awaiting Confirmation",
        "Ready for Cryptographic Erase",
        "Crypto Error",
        "Completed"
      ],
      progressStatus: ["Cloud Validation"],
      progressSpinner: false,
      loadingIcon: require(`../assets/images/loadingicon.svg`),
    };
  },
  methods: {
    getActionClass(status) {
      switch (status) {
        case "Waiting for Pickup":
          return "successButton";
        case "Awaiting Confirmation":
          return "yellowButton"
        default:
          return "primary";
      }
    },
    getActionLabel(status) {
      switch (true) {
        case status === "Ready to Return":
          return this.$t("PRINT_SHIPPING_LABEL");
        case status === "Validation Successful":
          return this.$t("PRINT_SHIPPING_LABEL");
        case status === "Waiting for Pickup":
          return this.$t("REPRINT_SHIPPING_LABEL");
        case (
            status === "In Transit" ||
            status === "Received" ||
            status === "In Queue" ||
            status === "Ready for Cryptographic Erase" ||
            status === "Crypto Error" ||
            status === "In Progress" ||
            status === "Awaiting Confirmation" ||
            status === "Completed"
          ):
          return this.$t("CHECK_IMPORT_STATUS");
      }
    },
    getButtonAction(status) {
      switch (true) {
        case status === "Ready to Return":
          this.printShippingLabel();
          break;
        case status === "Waiting for Pickup":
          this.rePrintShippingLabel();
          break;
        case (
            status === "In Transit" ||
            status === "Received" ||
            status === "In Queue" ||
            status === "Ready for Cryptographic Erase" ||
            status === "Crypto Error" ||
            status === "In Progress" ||
            status === "Awaiting Confirmation" ||
            status === "Completed"
          ):
          this.checkImportStatus();
          break;
      }
    },
    async callCredentialValidate() {
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let payload = {
        _id: this.planID._id,
        status: "Cloud Validation",
      };
      let planConnectivityServiceV2 = new PlanConnectivityServiceV2(user);
      planConnectivityServiceV2.updatePlanStatus(payload).then((data) => {
        if (data) {
          this.$store.commit("updatePlanStatus", {
            ID: this.planID._id,
            status: "Cloud Validation",
          });
          this.progressSpinner = true;
        }
      });
    },
    async printShippingLabel() {
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let payload = { shipping: { planID: this.planID._id } };
      let planConnectivityServiceV2 = new PlanConnectivityServiceV2(user);
      planConnectivityServiceV2.printShippingLabel(payload).then((data) => {
        if (data && data.code === "200") {
          this.$store.commit("updatePlan", {
            _id: this.planID._id,
            trackingNumber: data.data.trackingNumber,
            status: "Waiting for Pickup",
          });
          this.rePrintShippingLabel();
        } else if (data && data.code === "400" && data.messages) {
          errorHandler(this);
        }
      });
    },
    rePrintShippingLabel() {
      let routeData = this.$router.resolve({
        name: "shipping-label",
        params: { id: this.planID._id },
      });
      window.open(routeData.href, "_blank");
    },
    checkImportStatus() {
      this.$router.push({
          name: 'plan-details',
          params: { id: this.planID._id }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.btn {
	height: 38px;
	width: 76.1%;
	max-width: 180px;
	padding: 9px 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	&:disabled {
		color: $disabled_btn_color !important;
		border: 1px solid $light_grey_color;
		background-color: $white_color !important;
	}
}
.primary {
	background: $primary_color;
	color: $black_color !important;
	border: 1px solid $primary_color;
	border-radius: 2px !important;
}
.editCredentials {
	background: $white_color;
	color: $error_color !important;
	border: 1px solid $error_color;
	border-radius: 2px !important;
}
.successButton {
	background: $white_color;
	color: $primary_color !important;
	border: 1px solid $primary_color;
	border-radius: 2px !important;
}
.yellowButton {
  background: $alert_color;
	color: $black_color !important;
	border: 1px solid $alert_color;
	border-radius: 2px !important;
}
.btn.disabled {
	color: $disabled_btn_color !important;
	border: 1px solid $light_grey_color;
	background-color: $white_color !important;
}
.spinner-wrapper {
	height: 38px;
	width: 76.1%;
	max-width: 180px;
	padding: 9px 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}
@media screen and (min-width: 576px) and (max-width: 1199px) {
	.btn.btn-seagate-primary#btnAction {
		width: 100%;
	}
	.btn.btn-seagate-primary#btnConfigureData {
		width: 100%;
	}
}

</style>
