import {createI18n} from 'vue-i18n';
import store from '@/store/store';
import enus from './locales/en-us.json';
import engb from './locales/en-gb.json';
import de from './locales/de.json';
import it from './locales/it.json';
import pl from './locales/pl.json';
import es from './locales/es.json';
import nl from './locales/nl.json';
import fr from './locales/fr.json';
import jp from './locales/jp.json';

const i18n = createI18n({
  locale: store.getters.getDefaultLocale,
  messages: {
    "en-us": enus,
    "en-gb": engb,
    "de-de" : de,
    "it-it" : it,
    "pl-pl" : pl,
    "es-es" : es,
    "nl-nl" : nl,
    "fr-fr" : fr,
    "ja-jp": jp
  }
});

export default i18n;