export function getProjectBasedOnSearchCriteria(filter, projects) {
  if (!filter.projectName && !filter.destination) {
    return projects;
  }
  else if (filter.projectName.length > 2 && filter.destination.length == 0) {
    return projects.filter(p => {
      return p.projectInfo.name.toLowerCase().indexOf(filter.projectName.toLowerCase()) != -1;
    });
  } else if (!filter.projectName && filter.destination.length > 0) {
    return projects.filter(p => {
      return filter.destination.filter(cloud => (cloud.name.toLowerCase().indexOf(p.uploadDestination.provider.toLowerCase()) != -1) ? p.uploadDestination.provider : ''
      ).length > 0 ? p.uploadDestination.provider : '';
    });

  } else if (filter.projectName && filter.destination.length > 0) {
    if (filter.projectName.length > 2) {
      return projects.filter(p => {
        return filter.destination.filter(cloud => (cloud.name.toLowerCase().indexOf(p.uploadDestination.provider.toLowerCase()) != -1) && p.projectInfo.name.toLowerCase().indexOf(filter.projectName.toLowerCase()) != -1 ? p.uploadDestination.provider : ''
        ).length > 0 ? p.uploadDestination.provider : '';
      });
    } else {
      return projects.filter(p => {
        return filter.destination.filter(cloud => (cloud.name.toLowerCase().indexOf(p.uploadDestination.provider.toLowerCase()) != -1) ? p.uploadDestination.provider : ''
        ).length > 0 ? p.uploadDestination.provider : '';
      });
    }
  }
  return projects;
}
export function getUploadDestinationNameBasedOnSearchCriteria(filter, endPoints) {
  if (!filter.name && !filter.destination) {
    return endPoints;
  }
  else if (filter.name && filter.destination.length == 0) {
    if (filter.name.length > 2) {
      return endPoints.filter(p => {
        return p.name.toLowerCase().indexOf(filter.name.toLowerCase()) != -1;
      });
    }
  } else if (!filter.name && filter.destination.length > 0) {
    return endPoints.filter(p => {
      return filter.destination.filter(cloud => (cloud.name.toLowerCase().indexOf(p.destination.toLowerCase()) != -1) ? p.destination : ''
      ).length > 0 ? p.destination : '';
    });

  } else if (filter.name && filter.destination.length > 0) {
    if (filter.name.length > 2) {
      return endPoints.filter(p => {
        return filter.destination.filter(cloud => (cloud.name.toLowerCase().indexOf(p.destination.toLowerCase()) != -1) && p.name.toLowerCase().indexOf(filter.name.toLowerCase()) != -1 ? p.destination : ''
        ).length > 0 ? p.destination : '';
      });
    } else {
      return endPoints.filter(p => {
        return filter.destination.filter(cloud => (cloud.name.toLowerCase().indexOf(p.destination.toLowerCase()) != -1) ? p.destination : ''
        ).length > 0 ? p.destination : '';
      });
    }
  }
  return endPoints;
}