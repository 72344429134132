import {HotspotService} from './hotspot-service';
export default class PlanServiceV2 extends HotspotService{

  async getPlans() {
    try {
      let response = await this.axios.request({
        method: "get",
        url: `${this.API_BASE_URL}/api/v1/Plan`
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async getPlan(planId) {
    try {
      let response = await this.axios.request({
        method: "get",
        url: `${this.API_BASE_URL}/api/v1/Plan/detail?_id=${planId}`
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async updateBasicInfo(payload) {
    try {
      let response = await this.axios.request({
        method: "put",
        url: `${this.API_BASE_URL}/api/v1/Plan/BasicInfo`,
        data:{payload},
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async updateEndpoint(payload) {
    try {
      let response = await this.axios.request({
        method: "put",
        url: `${this.API_BASE_URL}/api/v1/Plan/Endpoint`,
        data:{payload},
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async updateUploadDestination(payload) {
    try {
      let response = await this.axios.request({
        method: "put",
        url: `${this.API_BASE_URL}/api/v1/Plan/UploadDestination`,
        data: payload,
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async updateCredentials(payload) {
    try {
      let response = await this.axios.request({
        method: "put",
        url: `${this.API_BASE_URL}/api/v1/Plan/Credentials`,
        data:{payload},
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async updateStatus(payload) {
    try {
      let response = await this.axios.request({
        method: "put",
        url: `${this.API_BASE_URL}/api/v1/Plan/Status`,
        data:{payload},
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async updateSource(payload) {
    try {
      let response = await this.axios.request({
        method: "put",
        url: `${this.API_BASE_URL}/api/v1/Plan/Source`,
        data:{payload},
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async uploadAcknoledgement(payload) {
    try {
        let response = await this.axios.request({
            method: "put",
            url: `${this.API_BASE_URL}/api/v1/Plan/Upload/Acknowledgement`,
            data: {
              "payload" : payload
            },
        });
        return response.data;
    } catch (error) {
        return(error);
    }
  }
}

