import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import CreateWorkflow from '../views/CreateWorkflow';
import ShippingLabel from '../views/ShippingLabel';
import PlanDetails from '../views/PlanDetails';
const routes = [
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/create-workflow/:projectId?',
    name: 'create-workflow',
    component: CreateWorkflow
  },
  {
    path: '/shipping-label/:id',
    name: 'shipping-label',
    component: ShippingLabel
  },
  {
    path: '/plan-details/:id',
    name: 'plan-details',
    component: PlanDetails
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

export default router;