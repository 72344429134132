<template>
  <transition name="modal" v-if="show">
    <div class="container" :class="stepper ? 'plan-stepper-wrapper': 'confirmImportModal'">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-body customScrollbar">
              <slot name="body">
                <div class="uploadDestinationList">
                  <div class="row pr-4 pl-4">
                    <div class="col-md-4 plan-stepper" v-if="stepper">
                      <VerticalStepper
                        :currentStep="$store.state.currentStep"
                      ></VerticalStepper>
                    </div>
                    <div class="text-left pr-0" :class="stepper ? 'col-md-8' : 'col-md-12'">
                      <div>
                        <EnterCredentials
                          @closeEnterCredentialModal="closeModelEmit"
                          @updateCurrentStepImportDestinationToEnterCredentionals="currentStepEmit"
                          @currentStep-emit="currentStepEmit"
                          @editCredentialCloseModel="closeModelEmit"
                          @closeReviewModel="closeOrderReviewModel"
                          @editPencilIconOnReviewPage="
                            editCredientialsNavigateFromReviewPage
                          "
                          @disableStepper="disableStepper"
                        />
                      </div>
                      <div class="form-wrapper"></div>
                    </div>
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div>
    <EnterCredentialSucessModel
      v-if="showSucessModal"
      @closeSubmitModel="closeSucessPopup"
    />
  </div>
</template>
<script>
import VerticalStepper from "../VerticalStepper.vue";
import EnterCredentials from "../create-plan/EnterCredentials.vue";
import EnterCredentialSucessModel from "../create-plan/SucessModel.vue";
export default {
  components: {
    VerticalStepper,
    EnterCredentials,
    EnterCredentialSucessModel
  },
  data() {
    return {
      currentStep: 1,
      //Parameter from the child component
      closeModel: false,
      show: true,
      showSucessModal: false,
      stepper: true
    };
  },
  props: ["msterConfigData", "selectedProject"],
  beforeMount() {
    this.currentStep = this.$store.state.currentStep;
    this.$store.commit("SetEnterCredentialselectedProject", this.selectedProject);
  },
  methods: {
    closeModelEmit() {
      this.selectedPostion = true;
      this.$emit("closeEnterCredentialModal-emit", this.closeModel);
    },
    currentStepEmit() {
      this.currentStep = this.$store.state.currentStep;
    },
    editCredientialsNavigateFromReviewPage() {
      this.currentStep = this.$store.state.currentStep;
    },
    editImportdestinationfromReviewPage(){
      this.currentStep = this.$store.state.currentStep;
    },
    closeOrderReviewModel() {
      this.show = false;
      this.showSucessModal = true;
    },
    closeSucessPopup() {
      this.showSucessModal = false;
      this.$emit("closeEnterCredentialModal-emit", this.closeModel);
    },
    disableStepper() {
      this.stepper = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.plan-stepper-wrapper {
  padding: 30px 30px;
  border: 2px solid $close_btn_border_color;
  border-radius: 5px;
  border-radius: 5px;
  .plan-stepper {
    border-right: 2px solid $stepper_right_border_color;
    display: flex;
    align-items: flex-start;
  }
}
#rightContent {
  .p-dropdown#cboSelectDestination {
    span.p-dropdown-label.p-inputtext.p-placeholder {
      padding: 0.5rem 0 0 !important;
    }
  }
  .uploadDestinationList {
    .p-dropdown {
      border: 1px solid $dropdown_border_color;
      padding: 0px 15px 0px;
      background-image: unset;
      background-color: $white_color;
      min-height: 40px;
      &:focus {
        border-bottom: 1px solid $dropdown_border_color !important;
      }
    }
    .p-dropdown.p-dropdown-label {
      padding-top: 0.5rem !important;
    }
    .p-dropdown.p-invalid {
      border: 1px solid $red_color !important;
    }
  }
  .p-dropdown {
    .p-dropdown-trigger {
      margin-top: 7px;
    }
  }
}
.controlHeader {
  padding-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: $control_header_color;
}
.uploadDestinationList {
  .p-inputtext {
    font-size: 0.9rem;
  }
  padding: 42px;
  h5 {
    color: $upload_sub_heading_color;
  }
}
.formBody {
  width: 100%;
}
.p-dropdown-panel.p-component {
  z-index: 99999 !important;
}
.minHeightRow {
  #addUploadDestinationModal {
    .p-dropdown {
      .p-dropdown-label {
        padding: 0.5rem 0 0 !important;
      }
    }
  }
}
.required {
  &::before {
    content: " * ";
    color: $red_color;
  }
}
#addUploadDestinationModal.modal {
  z-index: 101 !important;
  background: rgba(0, 0, 0, 0.8);
}
.modal-mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $black_background_color;
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 960px;
  margin: 0px auto;
  background-color: $white_color;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: $font-family;
  max-height: 90vh;
  padding: 10px 0;
}
.modal-header {
  h3 {
    margin-top: 0;
    color: $green_title_color;
  }
}
.modal-body {
  margin: 0 3px !important;
  padding: 0px;
  max-height: calc(90vh - 20px);
  overflow-x: auto;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
  .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.modal-leave-active {
  opacity: 0;
  .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.input {
  .hasError {
    border-color: $red_color;
  }
}
.p-dropdown {
  .hasError {
    border-color: $red_color;
  }
}
.is-invalid {
  border: 1px solid $red_color !important;
}
.closePopup {
  .bi {
    font-size: 25px;
    margin-top: -15px;
    display: inline-block;
  }
}
.customScrollbar {
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  }
}
</style>