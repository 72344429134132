<template>
  <transition name="modal commonModal">
    <div class="modal-mask edit-credentials">
      <div class="modal-wrapper">
        <div class="modal-container" :class="[editSuccess ? 'success' : '', formView === 'ErrorView' ? 'error' : '']">
          <div v-if="formView === 'ErrorView'">
            <component
              v-bind:is="formView"
              @closeModel="closeModel"
            ></component>
          </div>
          <div v-else
            class="modal-body customScrollbar"
            :class="dataLoading ? 'loading' : ''"
            :style="[
              dataLoading
                ? { 'background-image': 'url(' + loadingIcon + ')' }
                : '',
            ]"
          >
            <slot name="body">
              <div
                class="edit-wrapper formBody text-left col-8 mx-auto"
                v-if="!editSuccess"
              >
              <component
                v-bind:is="formView"
                :code="getProjectCode"
                :authType="getProjectAuthType"
                :storageProtocol="getProjectStorageProtocol"
                :editProject="editProject"
                :type="'edit'"
                @closeModel="closeModel"
                @showUpdateConnectivity="editSuccess = true"
                @dataLoading="loadingEvent"
              ></component>
              </div>
              <div v-else>
                <span>
                  <img
                    class="mb-20"
                    :src="
                      require(`@/assets/images/troubleshoot-in-progress.svg`)
                    "
                    width="40"
                  />
                </span>
                <div class="title mb-15">
                  {{ $t("VALIDATING_CLOUD_CONNECTION") }}
                </div>
                <p>
                  {{ $t("VALIDATING_DESCRIPTION") }}
                </p>
                <button
                  class="btn mx-auto d-block mt-3 btn-seagate-primary"
                  @click="$emit('closeEditCredentials')"
                >
                  {{ $t("OK") }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>

import EnterCredential from "@/services/enterCredentialsServiceV2";
import S3AssumeRoleAwsView from "./storage-protocol/S3AssumeRoleAwsView.vue";
import BlobAzureView from "./storage-protocol/BlobAzureView.vue";
import S3AccessKeyView from "./storage-protocol/S3AccessKeyView.vue";
import ErrorView from "./storage-protocol/ErrorView.vue";
export default {
  data() {
    return {
      credentials: { accessKey: "", secretKey: "", bucket: "", directory: "",accountId:"" },
      editSuccess: false,
      dataLoading: true,
      loadingIcon: `${this.$CDN_URL}images/loader.gif`
    };
  },
  props: ["editProject"],
  components: {
    S3AssumeRoleAwsView,
    BlobAzureView,
    S3AccessKeyView,
    ErrorView
  },
  beforeMount() {
    this.$store.commit("SetEnterCredentialConfigPlanData", null);
  },
  async mounted() {
    if(this.formView !== 'ErrorView') {
      let planId = this.$store.getters.getPlansFromProjectId(this.editProject.projectID)[0];
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let enterCredential = new EnterCredential(user);
      enterCredential
        .getConfigPlanPrefillData(planId._id)
        .then((data) => {
          if (data.code === "200" && data.type === "Success") {
            this.$store.commit("SetEnterCredentialConfigPlanData", data.data);
            this.dataLoading = false;
          } else if (data.type === "Failure") {
            this.$store.commit("SetEnterCredentialConfigPlanData", null);
            this.dataLoading = false;
          }
        });
    } else {
      this.dataLoading = false;
    }
  },
  computed: {
    formView() {
      let authType = this.getProjectAuthType;
      let storageProtocol = this.getProjectStorageProtocol;
      let code  = this.getProjectCode;

      switch (storageProtocol) {
        case 'blob'://possible values blob/S3
          switch (authType) {
            case 'shared key':
              switch (code) {
                case 'azure':
                  return 'BlobAzureView';
                default:
                  this.$emit('disableStepper');
                  return 'ErrorView';
              }
            default:
              this.$emit('disableStepper');
              return 'ErrorView';
          }
        case 's3':
          switch (authType) {
            case 'access key':
              return 'S3AccessKeyView';
            case 'aws arn assumed role':
              return 'S3AssumeRoleAwsView';
            default:
              this.$emit('disableStepper');
              return 'ErrorView';
          }
        default:
          this.$emit('disableStepper');
          return 'ErrorView';
      }
    },
    getProjectAuthType() {
      if (this.editProject && this.editProject.projectID) {
        let plan = this.$store.getters.getPlansFromProjectId(this.editProject.projectID);
        return (plan.length && plan[0].authType) ? plan[0].authType.trim().toLowerCase() : false;
      }
      return false;
    },
    getProjectStorageProtocol() {
      if (this.editProject && this.editProject.projectID) {
          let plan = this.$store.getters.getPlansFromProjectId(this.editProject.projectID);
          return (plan.length && plan[0].storageProtocol) ? plan[0].storageProtocol.trim().toLowerCase() : false;
        }
        return false;
    },
    getProjectCode() {
      if (this.editProject && this.editProject.projectID) {
          let plan = this.$store.getters.getPlansFromProjectId(this.editProject.projectID);
          return (plan.length && plan[0].Code) ? plan[0].Code.trim().toLowerCase() : false;
        }
        return false;
    },
  },
  methods: {
    loadingEvent(e) {
      this.dataLoading = e;
    },
    closeModel() {
      this.$emit("closeEditCredentials");
    }
  },
};
</script>
<style lang="scss" scoped>
.modal-container {
  width: 850px;
  padding: 10px 0;
  text-align: center;
}
.modal-container.success {
  width: 600px;
  padding: 20px 90px;
}
.modal-container.error {
  padding: 60px 50px;
  width: 600px;
}
.modal-body {
  margin: 0 3px !important;
  padding: 0px;
  max-height: calc(90vh - 20px);
  overflow-x: auto;
}
.modal-body.loading {
  opacity: 0.5;
  z-index: 10000;
  pointer-events: none;
  background-repeat: no-repeat;
  background-position: center center;
}
.edit-wrapper {
  padding: 50px 0;
}
.title {
  font-size: 18px;
  font-weight: 600;
}
.customScrollbar {
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  }
}
</style>