import EnterCredential from "@/services/enterCredentialsServiceV2";
import PlanConnectivityServiceV2 from "@/services/planConnectivityServiceV2.js";
import errorHandler from "@/utils/errorHandler/errorHandler";
export default {
  created() {
    this.validateBtnShow = false;
  },
  beforeMount() {
    let configData = this.$store.getters.getDestinationConfig(this.authType, this.storageProtocol, this.code);
    if (configData && configData.length) {
      this.config = configData[0];
    }
    this.setPreFillEnterCredentialDetails()
  },
  props: ["code", "authType", "storageProtocol", "editProject", "type"],
  data() {
    return {
      selectedProject: this.$store.state.selectedProject,
      isDisabledNextButton: true,
      progressSpinner: false,
      progressCompleted: false,
      validateBtnShow: false,
      suspended: false,
      isDisabled: true,
      modelClose: true,
      defaultValidateCredentialsValue: false,
      errorTerms: -1,
      clickedTNC: []
    }
  },
  watch: {
    "$store.state.enterCredentialValue"() {
      this.setPreFillEnterCredentialDetails();
    },
    credentials: {
      handler(newVal) {
        this.$emit("credentials", newVal);
      },
      deep: true,
    }
  },
  computed: {
    canEnableValidationCredentialsButton() {
      return this.enableValidationCredentials();
    },
    canCheckReadAndAcceptCheckBox() {
      return this.enableNextButtonOnCheckBox();
    }
  },
  methods: {
    checkReadAndAcceptCheckBox(url) {
      if (url && url.trim() !== '') {
        this.canCheckReadAndAcceptCheckBox;
      } else {
        this.clickedTNC.includes(url) ? "" : this.clickedTNC.push(url);
        this.errorTerms = -1;
        this.canCheckReadAndAcceptCheckBox;
      }
    },
    openIPAddressAccessGuidURL(url, index) {
      this.clickedTNC.includes(index) ? "" : this.clickedTNC.push(index);
      window.open(url);
      this.errorTerms = -1;
    },
    enableNextButtonOnCheckBox() {
      if (this.clickedTNC.length > 0) {
        this.errorTerms = -1;
        if (this.validateBtnShow && this.progressCompleted) {
          this.isDisabledNextButton = false;
        } else if (!this.validateBtnShow && this.progressCompleted) {
          this.isDisabledNextButton = true;
        }
      } else {
        document.getElementById("txtreadaccept").checked = false;
        this.validateBtnShow = false;
        this.errorTerms = 1;
      }
    },
    canHideSucessAndErrorMessage() {
      this.showProjectValidationError = false;
      this.defaultValidateCredentialsValue = true;
      if (this.suspended) {
        this.suspended = false;
        this.isDisabled = false;
        this.isDisabledNextButton = true;
      }
      if (this.progressCompleted) {
        this.progressCompleted = false;
        this.isDisabled = false;
        this.isDisabledNextButton = true;
      }
      if (this.credentialDetails) {
        this.isDisabled = false;
      }
    },
    async commonValidation(param) {
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let validateCredentials = new EnterCredential(user);

      validateCredentials.getValidateCredentials(param).then((data) => {
        if (
          data &&
          data.messages &&
          data.messages.length &&
          data.messages[0] === "Credentials are valid" &&
          data.type === "Success"
        ) {
          this.progressSpinner = false;
          this.progressCompleted = true;
          if (this.validateBtnShow) {
            this.isDisabledNextButton = false;
          }
          this.suspended = false;
        } else if (
          data &&
          data.messages &&
          data.messages.length &&
          data.messages[0] === "Invalid Credentials!" &&
          data.type === "Failed"
        ) {
          this.progressSpinner = false;
          this.suspended = true;
          this.progressCompleted = false;
          this.isDisabledNextButton = true;
        } else {
          this.progressSpinner = false;
          this.isDisabledNextButton = true;
          errorHandler(this);
        }
      });
    },
    async updateConnectivity(projectID) {
      let plans =
        this.$store.getters.getProjectPlansWithCredentialsDisruptionAndPermissionIssue(
          projectID
        );
      //here we are taking first plan id
      let planID = plans[0]._id;
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let payload = { _id: planID, status: "Cloud Validation" };
      let planConnectivityServiceV2 = new PlanConnectivityServiceV2(user);
      planConnectivityServiceV2.updatePlanStatus(payload).then((data) => {
        if (data) {
          this.updatePlanStatus(this.editProject.projectID);
          this.$emit('showUpdateConnectivity');
          this.$emit('dataLoading', false);
        } else {
          this.$emit('dataLoading', false);
        }
      });
    },
    updatePlanStatus(projectId) {
      let plans =
        this.$store.getters.getProjectPlansWithCredentialsDisruptionAndPermissionIssue(
          projectId
        );
      let self = this;
      plans.forEach(function (plan) {
        let payload = {
          ID: plan._id,
          status: "Cloud Validation",
        };
        self.$store.commit("updatePlanStatus", payload);
      });
    },
    closeModel() {
      this.$emit("closeModel");
    }
  }
};