<template>
  <div class="col-md-12 mb-0 mt-0">
    <div class="titleBox">
      <h5>{{ $t("IMPORT_DESTINATIONS") }}</h5>
    </div>
    <div class="col-md-12 mb-0 mt-0 pl-0 pr-0">
      <div class="projectFilterBox mt-3">
        <div class="row">
          <div class="col-md-6 pr-0 pl-0">
            <div class="searchBoxCustomWidth pr-0 col-md-6">
              <div class="inputFieldIconsGroup withCloseButton p-float-label">
                <div class="iconBox">
                  <i class="bi bi-search"></i>
                </div>
                <div
                  class="iconBox closeIconBox"
                  id="imgClearFilter"
                  @click="clearFilter()"
                  v-if="filter.name.length > 2"
                >
                  <img :src="`${$CDN_URL}images/icons/alert-close-black.svg`" />
                </div>
                <input
                  type="text"
                  v-model="filter.name"
                  class="form-control"
                  :class="filter.name ? 'p-filled' : ''"
                  id="txtSearchName"
                />
                <label for="inputtext">{{
                  $t("SEARCH_BY_IMPORT_DESTINATIONS_NAME")
                }}</label>
              </div>
            </div>
            <div class="multiSelectCustomWidth pr-0 col-md-6">
              <div class="projectStatus p-float-label">
                <MultiSelect
                  id="codUploadDestination"
                  v-model="selectedCloud"
                  :options="cloudDestination"
                  option-label="name"
                  display="chip"
                  :class="{
                    capitalize: true,
                    'no-multiselect-header': true,
                  }"
                  @show="addLable()"
                  @change="handleFilters($event, 'selectedCloud')"
                />
                <label for="inputtext">{{ $t("CLOUD_DESTINATION")}}</label>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 btndiv">
            <button
              class="btn p-0 font16Bold"
              id="btnAddUpdateDestination"
              @click="(showModal = true), clearModalData(), windowOverflow()"
            >
              <i class="bi bi-plus-circle"></i
              >{{ $t("ADD_IMPORT_DESTINATION") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="configureShipping pt-3">
      <div class="row">
        <div class="col-md-8 mb-0 mt-0 pl-0 pr-0"></div>
        <div class="col-md-4 mb-0 mt-0 pl-0 pr-0 text-right"></div>
      </div>
      <div class="table-responsive">
        <table
          class="table tableStyle devicesTable mb-0"
          id="tblUploadDestinations"
        >
          <thead>
            <tr>
              <th class="cloudDestinationName">{{ $t("NAME") }}</th>
              <th class="cloudDestination">{{ $t("CLOUD_DESTINATION") }}</th>
              <th class="cloudDestinationRegion">{{ $t("REGION") }}</th>
              <th class="cloudDestinationDescription">
                {{ $t("DESCRIPTION") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(endPoint, index) in getFilteredUploadDestination.sort(
                (a, b) => (a.name > b.name ? 1 : -1)
              )"
              :key="index"
            >
              <td class="cloudDestinationName">
                {{ endPoint.name }}
              </td>
              <td class="cloudDestination">
                <span id="importIcons">
                  <img :src="endPoint.image" width="40" class="mr-10" @error="commonErrorImage">
                  <span class="destinationDescription">{{ endPoint.destination }}</span>
                </span>
              </td>
              <td class="cloudDestinationRegion">
                {{ endPoint.region }}
              </td>
              <td class="action cloudDestinationDescription">
                {{ endPoint.description ? endPoint.description : "-" }}
              </td>
            </tr>
            <tr
              class="findSearchMessage"
              align="left"
              v-if="
                getFilteredUploadDestination.length <= 0 &&
                this.endPoints.length > 0
              "
            >
              <td colspan="6">
                {{ $t("NO_MATCHING_RECORDS_UPLOAD_DESTINATIONS") }}
              </td>
            </tr>
            <tr
              v-if="
                getFilteredUploadDestination.length <= 0 &&
                this.endPoints.length <= 0
              "
              align="left"
            >
              <td colspan="4">{{ $t("NO_RECORD_FOUND") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal -->

    <UploadDestination
      v-if="showModal"
      @close="showModal = false"
      @openPopup="openModal = true"
    />
    <ConfirmationPopup
      v-if="openModal"
      @openPopup="openModal = false"
      @close="(showModal = true), (openModal = false)"
    />
    <!----------Modal end here-------->
  </div>
</template>
  <script>
import MultiSelect from "primevue/multiselect";
import { getUploadDestinationNameBasedOnSearchCriteria } from "../../utils/logic/projectsFilter/SearchFilter.js";
import UploadDestination from "./UploadDestination.vue";
import ConfirmationPopup from "./ConfirmationPopup.vue";
import UploadDestinationServiceV2 from "../../services/uploadDestination-service-v2.js";
export default {
  name: "ViewEndPoints",
  components: {
    UploadDestination,
    ConfirmationPopup,
    MultiSelect,
  },
  data() {
    return {
      filter: {
        name: "",
        destination: "",
      },
      cloudDestination: [],
      selectedCloud: [],
      actionList: null,
      showDiv: null,
      showModal: false,
      openModal: false,
    };
  },
  computed: {
    getFilteredUploadDestination() {
      return this.filterUploadDestination(this.filter);
    },
    endPoints() {
      return this.$store.state.uploadDestinations;
    },
  },
  methods: {
    filterUploadDestination(filter) {
      return getUploadDestinationNameBasedOnSearchCriteria(
        filter,
        this.endPoints
      );
    },
    clearFilter() {
      //search field gets clear when click on 'X' (remove) icon.
      if (this.filter.name) {
        this.filter.name = "";
        return;
      }
    },
    handleFilters() {
      this.filter.destination = this.selectedCloud;
    },
    addLable() {
      let div = document.getElementsByClassName("p-multiselect-header"),
        p = document.createElement("p");
      p.classList.add("headerText");
      p.style.position = "absolute";
      p.style.top = "8px";
      p.style.left = "44px";
      p.style.fontSize = "12px";
      p.innerHTML = this.$t("SELECTALL");
      div[0].append(p);
    },
    windowOverflow() {
      document.body.style.overflow = "hidden";
    },
    clearModalData() {
      this.$store.state.showUploadDestinationDetails = {};
    },
  },
  async mounted() {
    let accessToken = await this.$auth.getTokenSilently();
    accessToken = `Bearer ${accessToken}`;
    let user = { token: accessToken };
    this.cloudDestination = this.$store.state.masterConfigurationData.data;
    let uploadDestinationServiceV2 = new UploadDestinationServiceV2(user);
    uploadDestinationServiceV2.getUploadDestination().then((data) => {
      this.$store.commit("setUploadDestination", data.data);
    });
  },
};
</script>
<style lang="scss" scoped>
.projectFilterBox {
	.row {
		>div {
			border-left: 1px $white_color;
		}
	}
}
.devicesFilterBox {
	.row {
		>div {
			border-left: 1px $white_color;
		}
	}
}
.searchBoxCustomWidth {
	max-width: 360px !important;
}
.multiSelectCustomWidth {
	max-width: 360px !important;
}
.btn#btnAddUpdateDestination {
	.bi-plus-circle {
		font-size: 24px;
		color: $primary_color;
		margin-right: 8px;
		line-height: 21px;
	}
}
@media (max-width: 1599px) {
  .p-multiselect{
    &::v-deep {
      .p-multiselect-label-container {
        max-width: 238px !important;
      }
    }
  }
}
</style>
