import {HotspotService} from './hotspot-service';
export default class ProjectServiceV2 extends HotspotService{

  async getProjects() {
    try {
      let response = await this.axios.request({
        method: "get",
        url: `${this.API_BASE_URL}/api/v1/Project`,
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async updateCredentials(payload) {
    try {
      let response = await this.axios.request({
        method: "put",
        url: `${this.API_BASE_URL}/api/v1/Project/Credentials`,
        data: { payload },
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async updateStatus(payload) {
    try {
      let response = await this.axios.request({
        method: "put",
        url: `${this.API_BASE_URL}/api/v1/Project/Status`,
        data: { payload },
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }
}

