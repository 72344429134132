<template>
  <div
    class="enterCredentials"
    :class="dataLoading ? 'loading' : ''"
    :style="[
      dataLoading ? { 'background-image': 'url(' + loadingIcon + ')' } : '',
    ]"
  >
    <div v-if="formView === 'ErrorView'">
      <component
        v-bind:is="formView"
        @closeModel="closeModel"
      ></component>
    </div>
    <div v-else>
      <div v-if="$store.state.currentStep === 0">
        <ImportDestination
          :selectedProject="selectedProject"
          @closeImportDestination="editCredentialModel"
          @updateCurrentStepFromImportDestination = "updateCurrentSteps"
        />
      </div>
      <div v-if="$store.state.currentStep === 1 && (getProjectCode === 'aws' && getProjectAuthType === 'aws arn assumed role' && getProjectStorageProtocol === 's3')">
        <PrerequisitesView
          @closeSubmitModel="editCredentialModel"
        ></PrerequisitesView>
      </div>
      <div v-if="($store.state.currentStep === 1 && (getProjectCode !== 'aws' || getProjectAuthType !== 'aws arn assumed role' || getProjectStorageProtocol !== 's3')) || ($store.state.currentStep === 2 && (getProjectCode === 'aws' && getProjectAuthType === 'aws arn assumed role' && getProjectStorageProtocol === 's3'))" class="formBody">
        <component
          v-bind:is="formView"
          :code="getProjectCode"
          :authType="getProjectAuthType"
          :storageProtocol="getProjectStorageProtocol"
          @closeModel="closeModel"
          @dataLoading="loadingEvent"
          :type="'add'"
        ></component>
      </div>
      <div v-if="($store.state.currentStep === 2 && (getProjectCode !== 'aws' || getProjectAuthType !== 'aws arn assumed role' || getProjectStorageProtocol !== 's3')) || ($store.state.currentStep === 3 && (getProjectCode === 'aws' && getProjectAuthType === 'aws arn assumed role' && getProjectStorageProtocol === 's3'))">
        <ReviewAndSubmitVue
          :selectedProject="selectedProject"
          :code="getProjectCode"
          :authType="getProjectAuthType"
          :storageProtocol="getProjectStorageProtocol"
          @editCredentialNavigation="editCredentialSate"
          @closeSubmitModel="editCredentialModel"
          @closeReviewOrderModel="closeReviewOrderPopup"
          @editPencilIconOnReviewPageImportDestination="editImportDestinationfromReviewAndSubmitPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ReviewAndSubmitVue from "./ReviewAndSubmit.vue";
import ImportDestination from "../create-plan/ImportDestination.vue";
import PrerequisitesView from "./PrerequisitesView.vue";
import EnterCredential from "../../services/enterCredentialsServiceV2";
import S3AssumeRoleAwsView from "./storage-protocol/S3AssumeRoleAwsView.vue";
import BlobAzureView from "./storage-protocol/BlobAzureView.vue";
import S3AccessKeyView from "./storage-protocol/S3AccessKeyView.vue";
import ErrorView from "./storage-protocol/ErrorView.vue";
export default {
  data() {
    return {
      modelClose: true,
      currentStep: this.$store.state.currentStep,
      plans: null,
      enterCredentialData: null,
      dataLoading: true,
      loadingIcon: `${this.$CDN_URL}images/loader.gif`,
      selectedProject: this.$store.state.selectedProject
    };
  },
  components: {
    ReviewAndSubmitVue,
    ImportDestination,
    PrerequisitesView,
    S3AssumeRoleAwsView,
    BlobAzureView,
    S3AccessKeyView,
    ErrorView
  },
  computed: {
    formView() {
      let authType = this.getProjectAuthType;
      let storageProtocol = this.getProjectStorageProtocol;
      let code  = this.getProjectCode;

      switch (storageProtocol) {
        case 'blob'://possible values blob/S3
          switch (authType) {
            case 'shared key':
              switch (code) {
                case 'azure':
                  return 'BlobAzureView';
                default:
                  this.$emit('disableStepper');
                  return 'ErrorView';
              }
            default:
              this.$emit('disableStepper');
              return 'ErrorView';
          }
        case 's3':
          switch (authType) {
            case 'access key':
              return 'S3AccessKeyView';
            case 'aws arn assumed role':
              return 'S3AssumeRoleAwsView';
            default:
              this.$emit('disableStepper');
              return 'ErrorView';
          }
        default:
          this.$emit('disableStepper');
          return 'ErrorView';
      }
    },
    getProjectAuthType() {
      if (this.$store.state.selectedProject && this.$store.state.selectedProject.projectID) {
        let plan = this.$store.getters.getPlansFromProjectId(this.$store.state.selectedProject.projectID);
        return (plan.length && plan[0].authType) ? plan[0].authType.trim().toLowerCase() : false;
      }
      return false;
    },
    getProjectStorageProtocol() {
      if (this.$store.state.selectedProject && this.$store.state.selectedProject.projectID) {
          let plan = this.$store.getters.getPlansFromProjectId(this.$store.state.selectedProject.projectID);
          return (plan.length && plan[0].storageProtocol) ? plan[0].storageProtocol.trim().toLowerCase() : false;
        }
        return false;
    },
    getProjectCode() {
      if (this.$store.state.selectedProject && this.$store.state.selectedProject.projectID) {
          let plan = this.$store.getters.getPlansFromProjectId(this.$store.state.selectedProject.projectID);
          return (plan.length && plan[0].Code) ? plan[0].Code.trim().toLowerCase() : false;
        }
        return false;
    }
  },
  beforeMount() {
    this.$store.commit("SetEnterCredentialConfigPlanData", null);
  },
  async mounted() {
    if(this.formView !== 'ErrorView'){
      this.$store.commit("SetEnterCredentialConfigPlanData", null);
      let planId = this.$store.getters.getPlansFromProjectId(this.$store.state.selectedProject.projectID)[0];
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let enterCredential = new EnterCredential(user);
      enterCredential
        .getConfigPlanPrefillData(planId._id)
        .then((data) => {
          if (data.code === "200" && data.type === "Success") {
            this.enterCredentialData = data.data;
            this.$store.commit("SetEnterCredentialConfigPlanData", data.data);
            this.dataLoading = false;
          } else if (data.type === "Failure") {
            this.$store.commit("SetEnterCredentialConfigPlanData", null);
            this.dataLoading = false;
          }
        });
    } else {
      this.dataLoading = false;
    }
  },
  methods: {
    loadingEvent(e) {
      this.dataLoading = e;
    },
    closeModel() {
      this.$store.commit("SetEnterCredentialConfigPlanData", null);
      this.$emit("closeEnterCredentialModal", this.modelClose);
    },
    closeSubmitModel() {
      this.closeModel();
      this.closeModel = true;
    },
    editCredentialSate() {
      this.currentStep = this.$store.state.currentStep;
      this.$emit("editPencilIconOnReviewPage", this.currentStep);
    },
    editCredentialModel() {
      this.$emit("editCredentialCloseModel", (this.showModal = false));
    },
    editImportDestinationfromReviewAndSubmitPage() {
      this.currentStep = 0;
      this.$store.commit("setCurrentStep", this.currentStep);
      this.validateBtnShow = false;
    },
    closeReviewOrderPopup() {
      this.$emit("closeReviewModel", (this.showModal = false));
    },
    updateCurrentSteps(){
      this.currentStep = this.$store.state.currentStep;
      this.$emit("updateCurrentStepImportDestinationToEnterCredentionals", { position: this.currentStep });
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.5;
  z-index: 100;
  pointer-events: none;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>