<template>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div>
        <div class="modal-header_new">
          <div class="selectCountry">
            <h3 class="modal-title mt-0">
              {{ $t("SELECT_YOUR_COUNTRY_REGION") }}
            </h3>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="rememberMySelection">
            <div class="checkboxButtonsBox form-check-inline">
              <div class="form-check pl-0">
                <input
                  id="exampleCheck1"
                  type="checkbox"
                  checked="checked"
                  class="custom-control-input form-check-input"
                />
                <label
                  class="custom-control-label form-check-label"
                  for="exampleCheck1"
                >
                  {{ $t("REMEMBER_MY_COUNTRY_LANG") }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="col-md-12 pl-0 pr-0">
          <ul>
            <li>
              <strong>North America</strong>
            </li>
            <li
              v-for="(country, id) in country_language_us"
              :key="id"
              :class="`${
                country.country == $store.getters.getDefaultCountry
                  ? 'selectedLanguage'
                  : ''
              }`"
              @click="updateLocale(country)"
              data-dismiss="modal"
            >
              {{ country.country }}&nbsp;({{ country.language }})
            </li>
            <li>
              <strong>Europe</strong>
            </li>
            <li
              v-for="(country, id) in country_language_eu"
              :key="id"
              :class="`${
                country.country == $store.getters.getDefaultCountry
                  ? 'selectedLanguage'
                  : ''
              }`"
              @click="updateLocale(country)"
              data-dismiss="modal"
            >
              {{ country.country.trim() }}&nbsp;({{ country.language }})
            </li>
            <li>
              <strong>Asia-Pacific (APAC)</strong>
            </li>
            <li
              v-for="(country, id) in country_language_asia"
              :key="id"
              :class="`${
                country.country == $store.getters.getDefaultCountry
                  ? 'selectedLanguage'
                  : ''
              }`"
              @click="updateLocale(country)"
              data-dismiss="modal"
            >
              {{ country.country.trim() }}&nbsp;({{ country.language }})
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import country from '@/localization/country.json';
export default {
  data() {
    return {
      country_language_us: country.country_language_us,
      country_language_eu: country.country_language_eu,
      country_language_asia: country.country_language_asia
    }
  },
  methods: {
    updateLocale(country) {
      this.$store.commit('setDefaultLocale', country.locale);
      this.$store.commit('setDefaultCountry', country.country);
      this.$store.commit('setDefaultLanguage', country.language);
      this.$i18n.locale = country.locale;
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-body {
  margin: 0;
}
.custom-control-label {
  position: initial;
  &:before,
  &:after {
    left: 0 !important;
  }
}
</style>