<template>
  <section
    id="mainContent"
    class="innerPages planDetailsWrapper"
  >
    <div class="mainHeadingWrapper">
      <div class="container-fluid">
        <div class="col-lg-12 col-md-12 pt-33 pb-33 pl-0 text-left">
          <h3>{{ plan.projectInfo?.name }}</h3>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div id="rightContent">
        <div
          id="minHeightRow"
          class="row d-flex justify-content-between"
        >
          <div class="col-md-12 mb-0">
            <div>
              <div class="crumb mb-20">
                <router-link :to="{ name: 'create-workflow'}">
                  <span class="greenText font-weight-bold">
                    <img :src="`${$CDN_URL}images/icons/back-arrow.svg`" width="16">
                    <span class="back ml-10"> {{ $t('BACK_TO_IMPORT_PLANS') }}</span>
                  </span>
                </router-link>
              </div>
              <div class="projectDetailsMainPage" v-if="plan?._id">
                <ProjectDetails :project="project" :plan="plan"/>
              </div>
              <div class="projectDetailsMainPage">
                <div class="mb-25"><h5>{{ $t('CLOUD_IMPORT_DETAILS') }}</h5></div>
                <div class="grayOuterBox" v-if="plan?._id">
                  <ViewPlan :plan="plan"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ProjectDetails from "../components/view-plan/ProjectDetails";
import ViewPlan from "../components/view-plan/ViewPlan";
import PlanServiceV2 from "@/services/plan-service-v2.js";

export default {
  data() {
    return {
      plan: {}
    }
  },
  components: {
    ProjectDetails,
    ViewPlan
  },
  async mounted() {
    let planId = this.$route.params.id;
    let plan = this.$store.getters.getPlanById(planId);
    if (plan.length) {
      this.plan = plan[0];
    } else {
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let planServiceV2 = new PlanServiceV2(user);
      await planServiceV2.getPlan(planId).then((data) => {
        this.plan = data.data[0];
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.projectDetailsMainPage {
  padding-bottom: 25px !important;
}
.grayOuterBox {
  background: $border-color_1;
  border: 1px solid $disabled_btn_color;
  padding: 20px;
  border-radius: 4px;
}
</style>