<template>
  <div class="error-view text-center">
    <div class="col-12 midSize">
      <h5 class="font-size-18 font-weight-medium">
        {{ $t("ERROR-DIALOG-TITLE") }}
      </h5>
    </div>
    <div class="col-12 p-3">
      <p> {{ $t("ERROR-DIALOG-BODY-CONFIGURE") }} </p>
    </div>
    <div class="pt-3">
      <button
        class="btn btn-primary"
        @click="$emit('closeModel')"
      >
        <b>{{ $t("OK") }}</b>
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.error-view {
  margin: -25px;
}
</style>
