<template>
  <div class="col-md-12 mb-10 mt-3">
    <div class="titleBox">
      <h5>{{ $t("IMPORT_PLANS") }}</h5>
      <p>{{ $t("IF_A_DIFFERENT_MEMBER") }}</p>
    </div>
    <div class="col-md-12 mb-0 mt-0 pl-0 pr-0">
      <div class="projectFilterBox mt-3">
        <div class="row">
          <div class="col-md-6 pr-0 pl-0">
            <div class="searchBoxCustomWidth pr-0 col-md-6">
              <div class="inputFieldIconsGroup withCloseButton p-float-label">
                <div class="iconBox">
                  <i class="bi bi-search"></i>
                </div>
                <div
                  class="iconBox closeIconBox"
                  id="imgClearFilter"
                  @click="clearFilter()"
                  v-if="filter.projectName.length > 2"
                >
                  <img :src="`${$CDN_URL}images/icons/alert-close-black.svg`" />
                </div>
                <input
                  type="text"
                  v-model="filter.projectName"
                  class="form-control"
                  :class="filter.projectName ? 'p-filled' : ''"
                  id="txtSearchName"
                />
                <label for="inputtext">{{ $t("SEARCHBY_PROJECTNAME") }}</label>
              </div>
            </div>
            <div class="multiSelectCustomWidth pr-0 col-md-6">
              <div class="projectStatus p-float-label">
                <MultiSelect
                  id="codCloudDestination"
                  v-model="selectedCloud"
                  :options="cloudDestination"
                  option-label="name"
                  display="chip"
                  :class="{
                    capitalize: true,
                    'no-multiselect-header': false,
                  }"
                  @show="addLable()"
                  @change="handleFilters($event, 'selectedCloud')"
                />
                <label for="inputtext">{{ $t("CLOUD_DESTINATION")}} </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="configureShipping pt-3">
      <div class="table-responsive">
        <table
          class="table tableStyle devicesTable uploadPlansTable mb-0"
          id="tblUploadPlans"
        >
          <thead>
            <tr>
              <th colspan="3" class="projectColumn">{{ $t("PROJECT") }}</th>
              <th class="devicesColumn">{{ $t("DEVICES") }}</th>
              <th class="cloudDestinationColumn">
                {{ $t("CLOUD_DESTINATION") }}
              </th>
              <th class="actionColumn">{{ $t("ACTION") }}</th>
            </tr>
          </thead>
          <tbody v-show="getFilteredProject.length > 0">
            <div v-for="project in getFilteredProject" :key="project">
              <tr
                :class="
                  project.contentVisible
                    ? 'expandedRowsArray'
                    : 'nonexpandedRowsArray'
                "
              >
                <td
                  class="expanding-arrow"
                  @click="expandRow(project)"
                  :key="project"
                >
                  <i
                    class="fa fa-angle-up"
                    aria-hidden="true"
                    v-if="project.contentVisible"
                  ></i>
                  <i class="fa fa-angle-down" aria-hidden="true" v-else></i>
                </td>
                <td>
                    {{ project.projectInfo.name }}
                  <div
                    class="alertIcon"
                    v-if="
                      $store.getters
                        .getProjectPlansStatus(project.projectID)
                        .some((v) => this.projectAlertStatus.includes(v))
                    "
                  >
                    <i class="bi bi-exclamation-triangle"></i>
                  </div>
                </td>
                <td class="devicesColumn">
                  {{ getPlanOfProject(project["projectID"]).length }}
                </td>
                <td class="cloudDestinationColumn">
                  <span id="importIcons">
                    <img :src="getDestinationImage(project?.uploadDestination?.provider)" width="40" class="mr-10" @error="commonErrorImage">
                    <span class="destinationDescription">
                      {{ project?.uploadDestination?.provider }}                      
                    </span>
                    <span
                      v-tooltip="{
                        value: project.cloudtoolTipData,
                        escape: true,
                        class:'custom-tooltip-cloud '
                      }"
                      ><i class="bi bi-info-circle pl-2"></i
                    ></span>
                  </span>
                </td>
                <td class="actionColumn action position-relative">
                  <div
                    v-if="
                      $store.getters
                        .getProjectPlansStatus(project.projectID)
                        .includes('Validation Failed') &&
                      $store.getters
                        .getProjectPlansFailedReason(project.projectID)
                        .includes('Firewall Issue')
                    "
                  >
                    <button
                      :data-planid="getTroubleshootPlanId(project.projectID)"
                      class="btn mx-auto btn-outline-danger"
                      @click="
                        (showTroubleshootModal = true),
                          (troubleshootPlanId = getTroubleshootPlanId(
                            project.projectID
                          ))
                      "
                    >
                      {{ $t("TROUBLESHOOT") }}
                    </button>
                  </div>
                  <div
                    v-else-if="
                      !$store.getters
                        .getProjectPlansStatus(project.projectID)
                        .includes('Draft')
                    "
                  >
                    <button
                      class="btn mx-auto"
                      :class="
                        $store.getters
                          .getProjectPlansStatus(project.projectID)
                          .includes('Validation Failed') &&
                        ($store.getters
                          .getProjectPlansFailedReason(project.projectID)
                          .includes('Invalid Credentials') ||
                          $store.getters
                            .getProjectPlansFailedReason(project.projectID)
                            .includes('Insufficient Permissions') ||
                          $store.getters
                          .getProjectPlansFailedReason(project.projectID)
                          .includes('Service Disruption'))
                          ? 'btn-outline-danger'
                          : 'btn-outline-success'
                      "
                      :disabled="
                        $store.getters
                          .getProjectPlansStatus(project.projectID)
                          .includes('In Progress')
                      "
                      @click="
                        editProject = project;
                        showEditCredentials = true;
                      "
                    >
                      {{ ifAwsArnPlan(project.projectID) ? $t("EDIT_ACCESS_DETAILS") :  $t("EDIT_CREDENTIALS")}}                                          
                    </button>
                  </div>
                  <div v-else-if="getPlanOfProject(project['projectID']).length > 0">
                    <button
                      id="btnConfigureData"
                      class="btn btn-seagate-primary"
                      @click="selectedProjectDetails(project)"
                    >
                      {{ $t("CONFIGURE_PLAN") }}
                    </button>
                  </div>
                </td>
              </tr>
              <tr
                v-if="project.contentVisible"
                :key="project"
                class="expanding-row"
              >
                <td colspan="5" style="padding-right: 0px !important">
                  <table
                    style="width: 100%; font-size: small"
                    class="nonScrolable uploadPlansDeviceTable"
                  >
                    <thead style="font-weight: 500">
                      <tr>
                        <th colspan="2" class="productnameColumn">
                          {{ $t("PRODUCT_NAME") }}
                        </th>
                        <th class="devicesColumn">{{ $t("SERIAL_NUMBER") }}</th>
                        <th class="statusColumn">{{ $t("STATUS") }}</th>
                        <th class="TrackingCloumn">{{ $t("TRACKING_NO") }}</th>
                        <th class="uploadActionColumn">{{ $t("ACTION")}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(devices, index) in getPlanOfProject(
                          project['projectID']
                        )"
                        :key="index"
                      >
                        <td
                          style="width: 0px !important; padding: 0px !important"
                        ></td>
                        <td class="productnameColumn">
                          <a>{{ devices.name }}</a>
                        </td>
                        <td class="serialColumn">
                          <div>
                            {{
                              devices.deviceSerialNumber
                                ? devices.deviceSerialNumber
                                : "-"
                            }}
                          </div>
                        </td>
                        <td class="statusColumn">
                          <div class="statusWrapper d-flex align-items-center">
                            <span class="status">{{ $t(devices.status) }}</span>                            
                            <span
                              v-tooltip="{
                                value: devices.toolTipData,
                                class: 'custom-tooltip',
                              }"
                              ><i class="bi bi-info-circle"></i
                            ></span>
                          </div>
                        </td>
                        <td class="TrackingCloumn">
                          <div
                            class="showLink"
                            v-if="
                              showUPSTrackingLink.includes(devices.status) &&
                              devices.trackingNumber
                            "
                          >
                            <a
                              :href="
                                'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' +
                                devices.trackingNumber +
                                '&requester=ST/'
                              "
                              target="_blank"
                            >
                              {{ devices.trackingNumber }}
                              <img
                                class="ml-8"
                                :src="`${$CDN_URL}images/OpenNewWindow.svg`"
                                width="14"
                              />
                            </a>
                          </div>
                          <div v-else>-</div>
                        </td>
                        <td class="actionColumn">
                          <ActionButton
                            :status="devices.status"
                            :trackingId="devices.trackingNumber"
                            :planID="devices"
                            @openEnterCrendentials="
                              selectedProjectDetails(project)
                            "
                            @click="selectedPlanDetails = devices._id"
                            @openConfirmImportPopup="
                              showConfirmImportModal = true
                            "
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </div>
          </tbody>
          <tbody v-if="getFilteredProject.length <= 0 || projects.length <= 0">
            <tr
              class="findSearchMessage"
              align="left"
              v-if="getFilteredProject.length <= 0 && projects.length > 0"
            >
              <td colspan="6">{{ $t("NO_MACHING-RECORDS") }}</td>
            </tr>
            <tr
              class="findSearchMessage"
              align="left"
              v-if="projects.length <= 0 && getFilteredProject.length <= 0"
            >
              <td colspan="6">{{ $t("NO_RECORD_FOUND") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal -->
    <ConfigurePlan
      v-if="showModal"
      @closeEnterCredentialModal-emit="SetModelClose"
      :selectedProject="selectedProject"
      :msterConfigData="cloudDestination"
    />
    <ConfirmImportModal
      v-if="showConfirmImportModal"
      :selectedPlanDetails="selectedPlanDetails"
      @closeModel="showConfirmImportModal = false"
    ></ConfirmImportModal>
    <EditCredential
      v-if="showEditCredentials"
      :editProject="editProject"
      @closeEditCredentials="showEditCredentials = false"
    />
    <TroubleshootModal
      v-if="showTroubleshootModal"
      :troubleshootPlanId="troubleshootPlanId"
      @closeModel="closeTroubleshootModal"
    ></TroubleshootModal>
    <!----------Modal end here-------->
  </div>
</template>
<script>
import { getProjectBasedOnSearchCriteria } from "../../utils/logic/projectsFilter/SearchFilter.js";
import ConfigurePlan from "./ConfigurePlans.vue";
import MultiSelect from "primevue/multiselect";
import MasterConfigV2 from "../../services/master-configuration-service-v2";
import PlanServiceV2 from "../../services/plan-service-v2.js";
import ActionButton from "../../controls/ActionButton.vue";
import EditCredential from "../../components/create-plan/EditCredentials.vue";
import ConfirmImportModal from "./ConfirmImportModal.vue";
import TroubleshootModal from "./TroubleshootModal.vue";
export default {
  name: "ViewProjects",
  data() {
    return {
      plans: [],
      filter: {
        projectName: "",
        destination: "",
      },
      selectedCloud: null,
      cloudDestination: [],
      expandedRowsArray: [],
      selectedProject: [],
      showModal: false,
      showDiv: null,
      showConfigurePlan: false,
      currentStep: 1,
      showEditCredentials: false,
      showUPSTrackingLink: [
        "Waiting for Pickup",
        "In Transit",
        "Received",
        "In Queue",
        "In Progress",
        "Awaiting Confirmation",
        "Crypto Error",
        "Ready for Cryptographic Erase",
        "Completed",
      ],
      projectAlertStatus: [
        "Draft",
        "Ready to Return",
        "Validation Failed",
        "Crypto Error",
        "Awaiting Confirmation",
      ],
      showConfirmImportModal: false,
      selectedPlanDetails: [],
      showTroubleshootModal: false,
      troubleshootPlanId: "",
      editProject: {},
      region_tooltip:'',
      destination_tooltip:''
    };
  },
  computed: {
    projects() {
      return this.$store.state.projects;
    },
    getPlans() {
      return this.$store.state.plans;
    },
    getFilteredProject() {
      return this.filterProject(this.filter);
    },
    getPlanOfProject() {
      return (id) =>{
        const devices = this.$store.state.plans.filter((planProject) => planProject.projectID === id);
        return devices.map((device)=> {
          device.toolTipData = this.getToolTipData(device)
          return device;
        })
      }
    },
    ifAwsArnPlan() {
       return (id) =>{ 
        let plan = this.$store.getters.getPlansFromProjectId(id);
        if (
            plan &&
            plan.length &&
            plan[0].authType && plan[0].authType.trim().toLowerCase() === 'aws arn assumed role' &&
            plan[0].Code && plan[0].Code.trim().toLowerCase() === 'aws' &&
            plan[0].storageProtocol && plan[0].storageProtocol.trim().toLowerCase() === 's3'
          ) {
            return true;
          } else {
            return false;
          }
        }
    },
    getDestinationImage() {
      return (provider) => 
        this.$store.getters.getDestinationImage(provider);        
    }
  },
  components: {
    ConfigurePlan,
    MultiSelect,
    ActionButton,
    EditCredential,
    ConfirmImportModal,
    TroubleshootModal,
  },
  methods: { 
    isTroubleshootBtnActive(projectId) {
      let plans =
        this.$store.getters.getProjectPlansWithFirewallIssue(projectId);
      return plans.length;
    },
    getTroubleshootPlanId(projectId) {
      let plans =
        this.$store.getters.getProjectPlansWithFirewallIssue(projectId);
      //here we are taking first id in case we have multiple
      return plans[0]._id;
    },
    expandRow(project) {      
      for (var i in this.projects) {
        if (this.projects[i]._id === project._id) {
          this.projects[i].contentVisible = !this.projects[i].contentVisible;
        } else {
          this.projects[i].contentVisible = false;
        }
      }
    },
    SetModelClose() {
      this.showModal = false;
    },
    setCurrentProject(project) {
      this.$store.commit("setCurrentProject", project);
      this.$router.push("/create-project");
    },
    clearFilter() {
      //search field gets clear when click on 'X' (remove) icon.
      if (this.filter.projectName) {
        this.filter.projectName = "";
        return;
      }
    },
    filterProject(filter) {
     const destinationtext = this.destination_tooltip
     const regiontext = this.region_tooltip
     const projects = this.projects.map((project)=>{
          let destination =  project?.uploadDestination?.uploadDestinationInfo?.name || '';
          let region = project?.uploadDestination?.region || ''; 
          project.cloudtoolTipData = destinationtext + destination + "<br/>" + region + regiontext;
          return project;
       }); 
      return getProjectBasedOnSearchCriteria(filter, projects);
    },
    handleFilters() {
      this.filter.destination = this.selectedCloud;
    },
    addLable() {
      let div = document.getElementsByClassName("p-multiselect-header"),
        p = document.createElement("p");
      p.classList.add("headerText");
      p.style.position = "absolute";
      p.style.top = "8px";
      p.style.left = "44px";
      p.style.fontSize = "12px";
      p.innerHTML = this.$t("SELECTALL");
      div[0].append(p);
    },
    selectedProjectDetails(project) {
      this.$store.commit("setCurrentStep", this.currentStep);
      this.selectedProject = project;
      this.showModal = true;
    },
    closeTroubleshootModal() {
      this.showTroubleshootModal = false;
    },
    triggerConfigurePlan(projectId) {
      let project = this.$store.getters.getProjectById(projectId);
      if (project && project.length) {
        this.selectedProjectDetails(project[0]);
      }
      this.$router.replace("/create-workflow");
    },
     getToolTipData(device) {
        let toolTipData = '';      
        switch (device?.status) {
          case "Draft":
            toolTipData = this.$t("TOOLTIP_DRAFT");
            break;
          case "Plan Accepted":
            toolTipData = this.$t("TOOLTIP_PLAN_ACCEPTED");
            break;
          case "Ready to Return":
            toolTipData = this.$t("TOOLTIP_READY_TO_RETURN");
            break;
          case "Cloud Validation":
            toolTipData = this.$t("TOOLTIP_CLOUD_VALIDATION");
            break;
          case "Validation Successful":
            toolTipData = this.$t("TOOLTIP_VALIDATION_SUCCESSFUL");
            break;
          case "Validation Failed":          
            if (device?.reason === "Firewall Issue") {
              toolTipData = this.$t("TOOLTIP_VALIDATION_FAILED_FIREWALL");
            } else if (device?.reason === "Service Disruption") {
              toolTipData = this.$t("TOOLTIP_VALIDATION_FAILED_DISRUPTION");
            } else if (device?.reason === "Insufficient Permissions") {
              toolTipData = this.$t("TOOLTIP_VALIDATION_FAILED_PERMISSION");
            } else {
              toolTipData = this.$t("TOOLTIP_VALIDATION_FAILED_CREDENTIALS");
            }
            break;
          case "Waiting for Pickup":
            toolTipData = this.$t("TOOLTIP_WAITING_FOR_PICKUP");
            break;
          case "In Transit":
            toolTipData = this.$t("TOOLTIP_IN_TRANSIT");
            break;
          case "Received":
            toolTipData = this.$t("TOOLTIP_RECEIVED");
            break;
          case "In Queue":
            toolTipData = this.$t("TOOLTIP_IN_QUEUE");
            break;
          case "Awaiting Confirmation":
            toolTipData = this.$t("TOOLTIP_AWAITING_CONFIRMATION");
            break;
          case "Ready for Cryptographic Erase":
            toolTipData = this.$t("TOOLTIP_READY_FOR_CRYPTOGRAPHIC_ERASE");
            break;
          case "Crypto Error":
            toolTipData = this.$t("TOOLTIP_CRYPTO_ERROR");
            break;
          case "Completed":
            toolTipData = this.$t("TOOLTIP_COMPLETED");
            break;
          case "In Progress":
            toolTipData = this.$t("TOOLTIP_IN_PROGRESS");
            break;
          default:
            toolTipData = "";
            break;
        }   

        return toolTipData;     
    }
  },
  async mounted() {
    let accessToken = await this.$auth.getTokenSilently();
    accessToken = `Bearer ${accessToken}`;
    let user = { token: accessToken };
    let masterConfigV2 = new MasterConfigV2(user);
    masterConfigV2.getMasterConfiguration().then((data) => {
      this.$store.commit("setMasterConfiguration", data);
      this.cloudDestination = this.$store.state.masterConfigurationData.data;
      this.cloudDestination = this.cloudDestination.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    });
    let planServiceV2 = new PlanServiceV2(user);
    await planServiceV2.getPlans().then((data) => {
      this.$store.commit("setPlans", data.data);
      this.plans = this.$store.state.plans;
    });
    //check if projectId is present in url
    if (this.$route.params.projectId) {
      this.triggerConfigurePlan(this.$route.params.projectId);
    }

    this.region_tooltip = this.$t("REGION_TOOLTIP")
    this.destination_tooltip = this.$t("IMPORT_DESTINATION_TOOLTIP")
  },
};
</script>
<style lang="scss" scoped>
.table-responsive {
	.table.uploadPlansTable {
		.projectColumn {
			width: 24.67%;
			max-width: 350px;
		}
		.devicesColumn {
			width: 14.11%;
			max-width: 200px;
		}
		.cloudDestinationColumn {
			width: 40.85%;
			max-width: 580px;
		}
		.actionColumn {
			width: 20.42%;
			max-width: 300px;
		}
		tbody {
			tr {
				td {
					table.uploadPlansDeviceTable {
						thead {
							th {
								border: 0px;
							}
							tr {
								height: 40px;
							}
						}
						tbody {
							tr {
								height: 60px;
							}
						}
					}
				}
			}
		}
	}
	overflow: inherit !important;
}
.productnameColumn {
	width: 23.1%;
	max-width: 270px;
}
.serialColumn {
	width: 16%;
	max-width: 200px;
}
.statusColumn {
	width: 15%;
	max-width: 200px;
	.bi-info-circle {
		padding: 0px 0px 0 5px;
	}
}
.TrackingCloumn {
	width: 26%;
	max-width: 380px;
}
.uploadActionColumn {
	width: 20.42%;
	max-width: 300px;
}
.uploadPlansDeviceTable {
	thead {
		th {
			border-color: $background-color;
		}
	}
}
#tblUploadPlans.uploadPlansTable {
	tbody {
		tr {
			td.expanding-arrow {
				padding: 10px 0px 10px 10px !important;
				width: 20px;
				padding: 10px 0px 10px 10px !important;
				width: 20px;
			}
		}
		tr.expanding-arrow {
			.fa {
				color: $primary_color;
				font-size: 1.5rem;
			}
		}
	}
}
.col-md-6 {
	max-width: 100%;
}
button.btn {
	height: 30px;
	margin-right: 0px;
	&:disabled {
		border: 1px solid $disabled_btn_color !important;
		background: $white_color 0% 0% no-repeat padding-box !important;
		border: 1px solid $disabled_btn_color !important;
	}
}
input.btn {
	height: 30px;
	margin-right: 0px;
}
.btn.btn-seagate-primary#btnAction {
	height: 38px;
	width: 80%;
	max-width: 180px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.btn.btn-seagate-primary#btnConfigureData {
	height: 38px;
	width: 80%;
	max-width: 180px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.btn {
	padding: 2px 25px;
	font-size: small;
}
.p-tooltip {
	&::v-deep {
		.p-tooltip-text {
			padding: 0.5rem 0.8rem !important;
		}
	}
}
.alertIcon {
	font-size: 17px;
	color: $alert_color;
	display: inline-block;
	float: right;
}
.dropdown-toggle {
	&::after {
		display: none;
	}
}
.dropdown-menu.menuListItem.show {
	transform: translate3d(-1px, 2px, 0px) !important;
}
.dropdown.verticalDots.show {
	position: unset !important;
}
.dropup.verticalDots.show {
	position: unset !important;
}
.navbar-nav {
	li {
		&:hover {
			>.dropdown-menu {
				display: block;
			}
		}
	}
}
.noScroll {
	tbody {
		overflow: inherit !important;
	}
}
.dropdown {
	&:hover {
		>.dropdown-menu {
			display: block;
		}
	}
}
.btn.dropdown-toggle {
	height: 46px;
}
.hidden {
	display: none;
}
button.btn.btn-outline-danger {
	height: 40px;
	background: $white_color;
	color: $error_color;
	width: 80%;
	max-width: 180px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.btn-outline-danger {
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				background: $white_color;
				color: $error_color;
			}
		}
	}
}
button.btn.btn-outline-success {
	height: 40px;
	background: $white_color;
	color: $primary_color;
	width: 80%;
	max-width: 180px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.btn-outline-success {
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				background: $white_color;
				color: $primary_color;
			}
		}
	}
}
.searchBoxCustomWidth {
	max-width: 360px !important;
}
.multiSelectCustomWidth {
	max-width: 360px !important;
}
@media (max-width: 1599px) {
  .p-multiselect{
   &::v-deep {
		.p-multiselect-label-container {
			max-width: 238px !important;
		}
	}
  }
}

</style>
