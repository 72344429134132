import {HotspotService} from './hotspot-service';
export default class MasterConfigurationServiceV2 extends HotspotService{

  async getMasterConfiguration() {
    try {
      let response = await this.axios.request({
        method: "get",
        url: `${this.API_BASE_URL}/api/v1/uploadDestinationConfig`
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }
}
