<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <div
      v-if="!showModal"
      class="col-md-12 mb-0 mt-0 pl-0 pr-0 importDestination"
    >
      <div class="projectFilterBox mt-3">
        <div class="row">
          <div class="col-md-8 pr-0 pl-0">
            <div class="searchBoxCustomWidth pr-0 col-md-7">
              <div class="inputFieldIconsGroup withCloseButton p-float-label">
                <div class="iconBox">
                  <i class="bi bi-search"></i>
                </div>
                <div
                  class="iconBox closeIconBox"
                  id="imgClearFilter"
                  @click="clearFilter()"
                  v-if="filter.name.length > 2"
                >
                  <img :src="`${$CDN_URL}images/icons/alert-close-black.svg`" />
                </div>
                <input
                  type="text"
                  v-model="filter.name"
                  class="form-control"
                  :class="filter.name ? 'p-filled' : ''"
                  id="txtSearchName"
                />
                <label for="inputtext">{{
                  $t("SEARCH_BY_IMPORT_DESTINATIONS_NAME")
                }}</label>
              </div>
            </div>
            <div class="multiSelectCustomWidth pr-0 col-md-5">
              <div class="projectStatus p-float-label">
                <MultiSelect
                  id="codUploadDestination"
                  v-model="selectedCloud"
                  :options="cloudDestination"
                  option-label="name"
                  display="chip"
                  :class="{
                    capitalize: true,
                    'no-multiselect-header': true,
                  }"
                  @show="addLable()"
                  @change="handleFilters($event, 'selectedCloud')"
                />
                <label for="inputtext">{{ $t("CLOUD_DESTINATION") }}</label>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <button
              class="btn pl-0 pr-2 greenText"
              id="btnAddUpdateDestination"
              @click="(showModal = true), clearModalData(), windowOverflow()"
            >
              <i class="bi bi-plus-circle"></i
              >{{ $t("ADD_IMPORT_DESTINATION") }}
            </button>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table
          class="devicesTable"
          :class="{
            table: true,
            disabled: getFilteredUploadDestination,
          }"
        >
          <thead class="aaaa">
            <tr>
              <th width="20%" class="cloudDestinationName">{{ $t("NAME") }}</th>
              <th width="30%" class="cloudDestination">{{ $t("CLOUD_DESTINATION") }}</th>
              <th width="20%" class="cloudDestinationRegion">{{ $t("REGION") }}</th>
              <th width="30%" class="cloudDestinationDescription">
                {{ $t("DESCRIPTION") }}
              </th>
            </tr>
          </thead>
          <tbody v-if="getFilteredUploadDestination.length" style="height: 200px">
            <tr
              v-for="(endPoint, index) in getFilteredUploadDestination.sort(
                (a, b) => (a.name > b.name ? 1 : -1)
              )"
              :key="index"
            >
              <td>
                <div class="d-flex">
                  <input class="radioBTN" name="select-endPoint"
                  type="radio"
                  v-model="selectedUploadDestination"
                  :value="endPoint"
                  :id="'radio_'+endPoint._id"
                  :checked="endPoint.name === $store.state.selectedProject?.uploadDestination?.uploadDestinationInfo?.name"
                  />
                  <label :for="'radio_'+endPoint._id">
                    <span class="impName">{{ endPoint.name }}</span>
                  </label>
                </div>
              </td>
              <td class="cloudDestination">
                <span id="importIcons">
                  <img :src="endPoint.image ? endPoint.image : commonErrorImage" width="40" class="mr-10" @error="commonErrorImage">
                  <span class="destinationDescription">{{ endPoint.destination }}</span>
                </span>
              </td>
              <td class="cloudDestinationRegion">
                {{ endPoint.region }}
              </td>
              <td class="action cloudDestinationDescription">
                {{ endPoint.description ? endPoint.description : "-" }}
              </td>
            </tr>
            <tr
              class="findSearchMessage"
              align="left"
              v-if="
                getFilteredUploadDestination.length <= 0 &&
                this.endPoints.length > 0
              "
            >
              <td colspan="6">
                {{ $t("NO_MATCHING_RECORDS_UPLOAD_DESTINATIONS") }}
              </td>
            </tr>
            <tr
              v-if="
                getFilteredUploadDestination.length <= 0 &&
                this.endPoints.length <= 0
              "
              align="left"
            >
              <td colspan="4">{{ $t("NO_RECORD_FOUND") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6 mt-20 mb-0 p-0">
        <button class="btn btn-seagate-primary" @click="addImportDestination()">
          {{ $t("NEXT") }}
        </button>
        <button
          class="btn btn-outline-secondary"
          @click="closeImportDestinationModel()"
        >
          {{ $t("CANCEL") }}
        </button>
      </div>
    </div>
    <!-- Modal -->
    <addUploadDestinationfield
      v-if="showModal"
      @close="showModal = false"
      @openPopup="openModal = true"
      @closeAddDestinationtableModal="closeImportDestinationModel"
    />
    <ConfirmationPopup
      v-if="openModal"
      @openPopup="openModal = false"
      @close="(showModal = true), (openModal = false)"
    />
    <!----------Modal end here-------->
  </div>
</template>

<script>
import { getUploadDestinationNameBasedOnSearchCriteria } from "../../utils/logic/projectsFilter/SearchFilter.js";
import UploadDestinationServiceV2 from "../../services/uploadDestination-service-v2.js";
import MultiSelect from "primevue/multiselect";
import ConfirmationPopup from "../end-point/ConfirmationPopup.vue";
import addUploadDestinationfield from "./AddUploadDestination.vue";
import PlanServiceV2 from "../../services/plan-service-v2.js";
import errorHandler from "@/utils/errorHandler/errorHandler";
export default {
  data() {
    return {
      filter: {
        name: "",
        destination: "",
      },
      UpdateSelectedProject: {
        uploadDestination: { provider: null}
      },
      cloudDestination: [],
      selectedCloud: [],
      actionList: null,
      showDiv: null,
      showModal: false,
      openModal: false,
      selectedUploadDestination: {
        "provider": this.$store.state.selectedProject.uploadDestination.provider,
        "region": this.$store.state.selectedProject.uploadDestination.region,
        "name": this.$store.state.selectedProject.uploadDestination.uploadDestinationInfo.name,
        "description": this.$store.state.selectedProject.uploadDestination.uploadDestinationInfo.description
      },
      currentStep:0
    };
  },
  components: {
    ConfirmationPopup,
    MultiSelect,
    addUploadDestinationfield,
  },
  computed: {
    getFilteredUploadDestination() {
      return this.filterUploadDestination(this.filter);
    },
    endPoints() {
      return this.$store.state.uploadDestinations;
    },
  },
  methods: {
    closeImportDestinationModel(){
      this.$emit("closeImportDestinationtableModal",this.showModal=false);
    },
    async addImportDestination(){
      let project = this.$store.state.selectedProject;
      let uploadDestination = {
        "provider": this.selectedUploadDestination.destination ? this.selectedUploadDestination.destination : this.selectedUploadDestination.provider,
        "region": this.selectedUploadDestination.region,
        "uploadDestinationInfo": {
          "name": this.selectedUploadDestination.name,
          "description": this.selectedUploadDestination.description
        }
      }
      let plans = this.$store.getters.getPlansFromProjectId(
        project.projectID
      );
      project = {...project, "uploadDestination": uploadDestination}
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let payload = {
        "projectID": project.projectID,
        "endpointID": this.selectedUploadDestination._id ? this.selectedUploadDestination._id : this.getEndpointId(),
        "uploadDestination": uploadDestination,
      };
      let planServiceV2 = new PlanServiceV2(user);
      planServiceV2.updateUploadDestination(payload).then((data) => {
        if (data.type === "Success" && data.code === "200") {
          this.currentStep = 1;
          this.$store.commit("SetEnterCredentialselectedProject", project);
          this.$store.commit("updateProject", project);
          this.$store.commit("setCurrentStep", this.currentStep);
          this.updatePlans(plans, uploadDestination);
          //this.$emit("clearCredentials");
          this.$emit("currentStepFromUploadDestinationTable", { position: this.currentStep });
        } else {
          errorHandler(this);
        }
      });
    },
    updatePlans(plans, uploadDestination) {
      let cloudArray = this.$store.state.masterConfigurationData.data.filter(
        (project) => project.name === uploadDestination.provider
      );
      let cloudRegion = cloudArray[0].regions;
      let endpointURL = '';
      for (var i = 0; i < cloudRegion.length; i++) {
        if (cloudRegion[i].value === uploadDestination.region) {
          endpointURL = cloudRegion[i].url;
          break;
        }
      }
      plans.forEach(plan => {
        // have to change code to StorageProtocol
        plan = {
          ...plan,
          "uploadDestination": uploadDestination,
          "endPointURl": endpointURL,
          "Code": cloudArray[0].code,
          "authType": cloudArray[0].authType,
          "storageProtocol": cloudArray[0].storageProtocol
        }
        this.$store.commit("updatePlan", plan);
      })
    },
    filterUploadDestination(filter) {
      return getUploadDestinationNameBasedOnSearchCriteria(
        filter,
        this.endPoints
      );
    },
    clearFilter() {
      //search field gets clear when click on 'X' (remove) icon.
      if (this.filter.name) {
        this.filter.name = "";
        return;
      }
    },
    handleFilters() {
      this.filter.destination = this.selectedCloud;
    },
    addLable() {
      let div = document.getElementsByClassName("p-multiselect-header"),
      p = document.createElement("p");
      p.classList.add("headerText");
      p.style.position = "absolute";
      p.style.top = "8px";
      p.style.left = "44px";
      p.style.fontSize = "12px";
      p.innerHTML = this.$t("SELECTALL");
      div[0].append(p);
    },
    windowOverflow() {
      document.body.style.overflow = "hidden";
    },
    clearModalData() {
      this.$store.state.showUploadDestinationDetails = {};
    },
    getEndpointId() {
      return this.getFilteredUploadDestination.find( destination =>
        (this.selectedUploadDestination.provider === destination.destination &&
        this.selectedUploadDestination.region === destination.region &&
        this.selectedUploadDestination.name === destination.name)
      )._id;
    }
  },
  async mounted() {
    this.currentStep = this.$store.state.currentStep;
    let accessToken = await this.$auth.getTokenSilently();
    accessToken = `Bearer ${accessToken}`;
    let user = { token: accessToken };
    this.cloudDestination = this.$store.state.masterConfigurationData.data;
    let uploadDestinationServiceV2 = new UploadDestinationServiceV2(user);
    uploadDestinationServiceV2.getUploadDestination().then((data) => {
      this.$store.commit("setUploadDestination", data.data);
    });
  },
};
</script>

<style lang="scss" scoped>
.projectFilterBox {
  .row {
    > div {
      border-left: 1px $white_color;
    }
  }
}
.devicesFilterBox {
  .row {
    > div {
      border-left: 1px $white_color;
    }
  }
}
.searchBoxCustomWidth {
  max-width: 360px !important;
}
.btn#btnAddUpdateDestination {
  .bi-plus-circle {
    font-size: 24px;
    color: $primary_color;
    margin-right: 8px;
    line-height: 21px;
  }
}
@media (max-width: 1599px) {
  .p-multiselect {
    &::v-deep {
      .p-multiselect-label-container {
        max-width: 238px !important;
      }
    }
  }
}
.projectStatus {
  max-width: 100% !important;
}
th {
  background: #E8E8E8;
  border: 3px solid #fff;
  padding-left: 20px !important;
  padding-right: 20px !important;
  vertical-align: middle;
}
thead {
  width: 100%;
  overflow: hidden;
}
td {
  vertical-align: middle;
}
#rightContent .uploadDestinationList input.radioBTN {
  border-radius: 50% !important;
  min-width: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.impName {
  display: inline-block;
  vertical-align: middle;
  word-break: break-all;
}
.cloudDestinationDescription {
  word-break: break-all;
}

</style>