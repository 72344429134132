<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body p-50">
            <slot name="body">
              <div>
                <span>
                  <img
                    class="mb-30"
                    :src="`${$CDN_URL}images/alert/processing-warning.svg`"
                    width="40"
                  />
                </span>
                <p class="font-size-18 sub-heading">{{ $t("SESSION_EXPIRE_HEADER") }}</p>
              </div>
              <div>
                <p class="message">{{ $t("SESSION_EXPIRE_MSG") }}</p>
              </div>

              <button
                class="btn btn-seagate-primary"
                @click="$emit('openPopup')"
              >
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.message {
	padding: 0px 0 30px 0;
	font-size: 14px;
	color: $msg_color;
	font-weight: 300;
}
.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
	height: auto !important;
	overflow-y: hidden;
}
.modal-container {
	width: 600px;
	margin: 0px auto;
	background-color: $background-color;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: $font-family;
}
.modal-body {
	.btn {
		margin-right: 0px;
	}
	margin: 20px 0;
	padding: 35px 100px;
	text-align: center;
	h5 {
		font-size: 18px !important;
		color: $title_color;
		font-weight: 400;
		padding-top: 30px;
	}
  .sub-heading {
    padding-top: 5px;
  }
}

</style>
