<template>
  <div>
    <section id="mainContent" class="landingPage">
      <div class="container">
        <div
          id="minHeightRow"
          class="
            row
            d-flex
            justify-content-between
            align-items-center
            welcomeContent
          "
        >
          <div class="col-md-10 col-lg-10 col-xl-10">
            <h1>
              <img
                class="PrimaryNav-logo"
                :src="`${$CDN_URL}images/lyve-logo.png`"
              />
            </h1>
            <!-- <h2 class="mt-1 mb-25">{{__('WELCOMETOTHELYVE')}}</h2> -->
            <h2 class="mt-1 mb-25">
              {{ __("WELCOMETEXT") }}
            </h2>
            <h6 class="mb-0">
              {{ __("GETSTARTED_BY_CREATING_ACCOUNT") }}
            </h6>
            <!-- <h6 class="mb-2">{{__('ONCEYOU_SETUP')}}</h6> -->
            <h6 class="mb-2">
              {{ __("ONCEYOU_SETUP") }}
            </h6>
            <ul class="landingPageList">
              <li>
                <span>{{ __("CREATE_PROJECTS") }}</span>
              </li>
              <li>
                <span>{{ __("MANAGE_SUBSCRIPTIONS") }}</span>
              </li>
              <li>
                <span>{{ __("ASSIGN_USER_PERMISSION") }}</span>
              </li>
            </ul>
            <a
              class="link"
              data-toggle="modal"
              data-target="#getStartedModal"
              >
              <button
                type="button"
                class="btn btn-primary btn-seagate-primary blackBg arrowOuter"
              >
                <span>{{ __("GETSTARTED") }}<i class="actualArrow"/></span>
              </button>
            </a>
            <button
              type="button"
              class="btn btn-outline-secondary blackBg heroButton arrowOuter"
              data-toggle="modal"
              data-target="#learnMoreModal"
            >
              <span>
                {{ __("LEARNMORE") }} <i class="actualArrow white"
              /></span>
            </button>
          </div>
        </div>
        <!-- Modal -->
        <div
          id="learnMoreModal"
          class="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="learnMoreModal"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title">
                  {{ __("DISCOVER_LYVE_SERVICE") }}
                </h3>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <div class="registerContentBoxPopup">
                    <a @click="productPageLink()">
                      <h4 class="titleText" style="cursor: pointer">
                        {{ __("LYVE_DATA_TRANSFER_SERVICE") }}
                        <span> <i class="titleArrow" /></span>
                      </h4>
                    </a>
                    <p class="mb-0">
                      {{ __("LEARNMORE_ABOUT_HOW_LYVESERVICE") }}
                    </p>
                    <p class="mt-25 talkToExpertOuter">
                      <a class="font16Bold" @click="requestMoreInfoLink()">
                        {{ __("LYVESERVICE_TALK_EXPERT") }}
                        <img
                          :src="`${$CDN_URL}images/icons/talkToExpert.svg`"
                          class="talkToExpertIcon ml-6"
                          width="24"
                        />
                        <img
                          :src="`${$CDN_URL}images/icons/talkToExpert-green.svg`"
                          class="talkToExpertIcon hover ml-6"
                          width="24"
                        />
                      </a>
                    </p>
                  </div>
                </div>
                <div class="col-md-12 pb-4 mt-20">
                  <div class="registerContentBoxPopup">
                    <a @click="sslsInfoLink()">
                      <h4 class="titleText" style="cursor: pointer">
                        <span
                          >{{ __("SEAGATE_SYSTEMS_LEASING") }}
                          <i class="titleArrow"
                        /></span></h4
                    ></a>
                    <p class="mb-0">
                      {{ __("SEAGATE_SYSTEMS_LEASING_DESCRIPTION") }}
                    </p>
                    <p class="mt-25 talkToExpertOuter">
                      <a class="font16Bold" @click="sslsTalkExpertLink()">
                        {{ __("SEAGATE_SYSTEMS_LEASING_TALK_EXPERT") }}
                        <img
                          :src="`${$CDN_URL}images/icons/talkToExpert.svg`"
                          class="talkToExpertIcon ml-6"
                          width="24"
                        />
                        <img
                          :src="`${$CDN_URL}images/icons/talkToExpert-green.svg`"
                          class="talkToExpertIcon hover ml-6"
                          width="24"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
var config = require('../services/settings.json');
import UploadDestinationServiceV2 from "../services/uploadDestination-service-v2.js";
export default {
  name: "HomeView",

  props: {
    msg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(), // 2020
      currentState: ""
    };
  },

  computed: {
    isHollidayBanner() {
      return false;
    },
    isLoggedIn() {
      return false;
    },
    regStep() {
      return "Review";
    },
    isAuthenticated() {
      return false;
    },
    learnMoreUrl() {
      let locale = "en-us";
      return `https://www.seagate.com/ca/fr/support/kb/lyve-transition-to-username-based-authentication-faq-and-process?stxLoc=${locale}`;
    },
  },

  async mounted() {
    if (this.$auth.isAuthenticated) {
      let accessToken = await this.$auth.getTokenSilently();
      if (accessToken) {
        this.loadProjectDetails();
      }
    }
  },

  methods: {
    __(item) {
      return this.$t(item);
    },
    hasPermission() {
      return true;
    },
    async redirectToCreateWorkflow(){
     await this.$router.push({ name: "create-workflow" });
    },
    async loadProjectDetails() {
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };

      let uploadDestinationServiceV2 = new UploadDestinationServiceV2(user);
        uploadDestinationServiceV2.getUploadDestination().then((data) => {
        this.$store.commit("setUploadDestination", data.data);
      });

      this.redirectToCreateWorkflow();
    },
    legalPrivacyLink() {
      let locale = "en-us";
      //let locale = this.$store.state.locale.current_locale;
      window.open(
        "https://www.seagate.com/legal-privacy?" + `stxLoc=${locale}`,
        "_blank"
      );
    },
    vulnerabilityLink() {
      let locale = "en-us";
      //let locale = this.$store.state.locale.current_locale;
      window.open(
        "https://www.seagate.com/legal-privacy/responsible-vulnerability-disclosure-policy?" +
          `stxLoc=${locale}`,
        "_blank"
      );
    },
    contactMeLink() {
      //let locale = "en-us";
    },
    requestMoreInfoLink() {
      let locale = "en-us";
      //let locale = this.$store.state.locale.current_locale;
      window.open(
        "https://www.seagate.com/promos/talk-to-a-services-expert/?contentSrc=lyve-hub?" +
          `stxLoc=${locale}`,
        "_blank"
      );
    },
    GetToken() {
      this.Auth.getTokenSilently().then((token) => {
        console.log(token);
      });
    },
    reload() {
      window.location.reload();
    },
    loginFromAuth0() {
      this.$auth.loginWithRedirect();
    },
    signupFromAuth0(isReseller) {
      console.log(isReseller);
    },
    logout() {
      localStorage.clear();
      sessionStorage.clear();
      this.$store.clear();
    },
    productPageLink() {
      window.open(config.productLink , "_blank");
    },
    sslsInfoLink() {
      window.open(config.systemLeassingLink , "_blank");
    },
    sslsTalkExpertLink() {
      window.open(config.sslsTalkExpertLink, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
	margin: 40px 0 0;
}
ul {
	list-style-type: none;
	padding: 0;
}
a {
	color: $green_title_color;
}
.locale-select {
	justify-content: space-between;
	font-size: 12px;
	outline: none;
	border: 0;
	cursor: pointer;
	transition: all 0.5s;
	background-color: $black_color;
	color: $white_color;
}
#languageSelectModal {
	li {
		cursor: pointer;
		strong {
			cursor: default;
		}
	}
}
.orText {
	font-size: 16px !important;
	font-weight: 500;
}
.landingPageMessage {
	img {
		display: inline-block;
		vertical-align: top;
	}
	span {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 80px) !important;
	}
}

</style>
