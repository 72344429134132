<template>
  <div class="blob-azure-view">
    <Form
      @submit="addEnterCredentials"
      :disabled="$store.state.currentStep === 2"
      :validation-schema="validateRules"
      v-slot="{ errors }"
    >
      <div class="col-12" v-if="config.labels && config.labels.length >= 1">
        <p class="controlHeader font16Bold">{{ config.labels[0].name }}</p>
        <span class="p-float-label" :class="errors.accessKey || suspended  ? 'error_label' : ''">
          <Field
            id="accessKeyId"
            name="accessKey"
            v-model="credentials.accessKey"
            type="password"
            class="form-control"
            :class="credentials.accessKey ? 'p-filled' : ''"
            @change="canEnableValidationCredentialsButton"
            @click="canHideSucessAndErrorMessage()"
          />
          <label for="accessKeyId" class="placeholder">{{ config.labels[0].placeHolder }}</label>
        </span>
      </div>
      <div class="col-12" v-if="config.labels && config.labels.length >= 2">
        <p class="controlHeader font16Bold">{{ config.labels[1].name }}</p>
        <span class="p-float-label" :class="errors.secretKey || suspended  ? 'error_label' : ''">
          <Field
            id="secretKey"
            name="secretKey"
            v-model="credentials.secretKey"
            type="password"
            class="form-control"
            :class="credentials.secretKey ? 'p-filled' : ''"
            @change="canEnableValidationCredentialsButton"
            @click="canHideSucessAndErrorMessage()"
          />
          <label for="secretKey" class="placeholder">{{ config.labels[1].placeHolder }}</label>
        </span>
      </div>
      <div class="col-12 pt-1" v-if="config.labels && config.labels.length >= 3">
        <p class="controlHeader font16Bold">{{ config.labels[2].name }}</p>
        <p class="pt-1 helpTextWidthbucket font-size-14">
          {{ $t("CONFIG_BUCKET_TEXT") }}
          <span v-if="config.Pre_Requisite_URL && config.Pre_Requisite_URL.trim() !== ''">{{ $t("CONFIG_BUCKET_TEXT_SEE") }}</span>
          <b id="accessguid" v-if="config.Pre_Requisite_URL && config.Pre_Requisite_URL.trim() !== ''">
            <a
              :href="config.Pre_Requisite_URL"
              target="_blank"
            >
              {{  $t("PREREQUISITES") }}.
              <img :src="`${$CDN_URL}images/OpenNewWindow.svg`" width="14" />
            </a>
          </b>
        </p>
        <span class="p-float-label" :class="errors.bucket || suspended  ? 'error_label' : ''">
          <Field
            id="bucket"
            name="bucket"
            v-model="credentials.bucket"
            type="text"
            class="form-control"
            :class="credentials.bucket ? 'p-filled' : ''"
            @change="canEnableValidationCredentialsButton"
            @click="canHideSucessAndErrorMessage()"
          />
          <label for="bucket" class="placeholder">{{ config.labels[2].placeHolder }}</label>
        </span>
      </div>
      <div v-if="!progressCompleted && !suspended && type === 'edit'" class="col-12 pt-3 pb-2 validationWrapper">
        <button
          type="button"
          :disabled="isDisabled"
          class="btn btn-outline-secondary"
          :class="!isDisabled ? 'sucessColor' : ''"
          id="btnvalidatecredentials"
          @click="ValidateCredentials()"
        >
          {{ $t("VADLIDATION_CREDENTIALS") }}
        </button>
        <span v-if="progressSpinner">
          <img
            :src="require(`@/assets/images/loadingicon.svg`)"
            @error="alert('Error')"
            class="status-spinner"
          />
        </span>
      </div>
      <!-- Validation Button sucess message-->
      <div v-if="progressCompleted && type==='edit'" class="col-12 pt-4 pb-3 validationWrapper font-size-14">
        <button
          type="button"
          class="btn btn-secondary"
          :class="!suspended && progressCompleted ? 'sucessColor' : ''"
          id="btnvalidatecredentialsMessage"
        >
          {{ $t("VADLIDATION_SUCESSFUL") }}
          <img
            class="ml-8"
            :src="`${$CDN_URL}images/status-icons/completed.svg`"
            width="24"
          />
        </button>
      </div>
      <div class="col-12 pt-1" v-if="config.labels && config.labels.length >= 4">
        <p class="controlHeader font16Bold">{{ config.labels[3].name }}</p>
        <p class="pt-1 helpTextWidth font-size-14">
          {{ $t("CONFIG_FOLDER_TEXT") }}
          <span v-if="config.Naming_Guideline_URL && config.Naming_Guideline_URL.trim() !== ''">{{ $t("CONFIG_FOLDER_TEXT_SEE") }}</span>
          <b id="accessguid" v-if="config.Naming_Guideline_URL && config.Naming_Guideline_URL.trim() !== ''">
            <a
              :href="config.Naming_Guideline_URL"
              target="_blank"
            >
              {{  $t("NAMING_GUIDELINES") }}.
              <img :src="`${$CDN_URL}images/OpenNewWindow.svg`" width="14" />
            </a>
          </b>
        </p>
        <span class="p-float-label" :class="errors.folder ? 'error_label' : ''">
          <Field
            id="folder"
            name="folder"
            v-model="credentials.folder"
            type="text"
            class="form-control"
            :class="credentials.folder ? 'p-filled' : ''"
          />
          <label for="folder" class="placeholder">{{ config.labels[3].placeHolder }}</label>
          <span class="optionalText pt-0 pb-0">{{ $t("OPTIONAL") }}</span>
        </span>
      </div>
      <!-- Validation Button -->
      <div v-if="!progressCompleted && !suspended && type === 'add'" class="col-12 pt-3 pb-2 validationWrapper">
        <button
          type="button"
          :disabled="isDisabled"
          class="btn btn-outline-secondary"
          :class="!isDisabled ? 'sucessColor' : ''"
          id="btnvalidatecredentials"
          @click="ValidateCredentials()"
        >
          {{ $t("VADLIDATION_CREDENTIALS") }}
        </button>
        <span v-if="progressSpinner">
          <img
            :src="require(`@/assets/images/loadingicon.svg`)"
            @error="alert('Error')"
            class="status-spinner"
          />
        </span>
      </div>
      <!-- Validation Button sucess message-->
      <div v-if="progressCompleted && type==='add'" class="col-12 pt-4 pb-3 validationWrapper font-size-14">
        <button
          type="button"
          class="btn btn-secondary"
          :class="!suspended && progressCompleted ? 'sucessColor' : ''"
          id="btnvalidatecredentialsMessage"
        >
          {{ $t("VADLIDATION_SUCESSFUL") }}
          <img
            class="ml-8"
            :src="`${$CDN_URL}images/status-icons/completed.svg`"
            width="24"
          />
        </button>
      </div>

      <div class="col-12 pt-3 pb-4" v-if="type === 'edit'">
        <div class="form-check">
          <input
            id="txtreadaccept"
            type="checkbox"
            v-model="validateBtnShow"
            class="custom-control-input"
            :disabled="errorTerms > 0"
            @change="checkReadAndAcceptCheckBox(config.Access_Guide_URL)"
          />
          <label
            class="custom-control-label ml-10 font-size-14"
            for="txtreadaccept"
          >
            {{ $t("READ_AND_ACCEPT") }}<span v-if="config.Access_Guide_URL && config.Access_Guide_URL.trim() !== ''">:</span>
          </label>
        </div>
        <span
          v-if="errorTerms > 0"
          class="error_messages pl-0 ml-28 pt-02 "
        >
          {{ $t("PLEACECLICK_SINGLE_LINKBEFOR_CHECKBOX") }}
        </span>
        <div class="ip-guid ml-30 pl-0 font-size-14" v-if="config.Access_Guide_URL && config.Access_Guide_URL.trim() !== ''">
          <b id="accessguid">
            <a
              v-on:click="
              openIPAddressAccessGuidURL(
                config.Access_Guide_URL,
                index
              )"
            >
              {{ $t("IP_ADDRESS_GUIDE") }}
              <img
                :src="`${$CDN_URL}images/OpenNewWindow.svg`"
                width="14" /></a
          ></b>
        </div>
      </div>

      <!-- validation Failed error message -->
      <div
        v-if="(editProject && editProject.projectID &&
          $store.getters.getProjectPlansStatus(editProject.projectID).includes('Validation Failed') &&
          (
            $store.getters.getProjectPlansFailedReason(editProject.projectID).includes('Invalid Credentials') ||
            $store.getters.getProjectPlansFailedReason(editProject.projectID).includes('Insufficient Permissions') ||
            $store.getters.getProjectPlansFailedReason(editProject.projectID).includes('Service Disruption')
          ) && showProjectValidationError
        ) || suspended" class="suspend col-12 pb-2 pt-2">
        <div class="p-3 pt-2">
          <p>
            <span
              class="
                pr-2
                validationFaieldText
                font-size-14 font-weight-medium
              "
              ><b>{{ $t("VADLIDATION_FAILED") }}</b></span
            >
            <img
              :src="`${$CDN_URL}images/status-icons/suspended.svg`"
              width="24"
            />
          </p>
          <p class="pt-1" v-if="type === 'add'">
            {{ $t("VADLIDATION_FAILED_ERROR_MESSAGE") }}
          </p>
          <p class="pt-1" v-else>
            {{ $t("PROJECT_VADLIDATION_FAILED_ERROR_MESSAGE") }}
          </p>
        </div>
      </div>

      <!-- READ AND ACCEPT field -->
      <div class="col-12 pt-3 pb-2" v-if="type === 'add'">
        <div class="form-check">
          <input
            id="txtreadaccept"
            type="checkbox"
            v-model="validateBtnShow"
            class="custom-control-input"
            :disabled="errorTerms > 0"
            @change="checkReadAndAcceptCheckBox(config.Access_Guide_URL)"
          />
          <label
            class="custom-control-label ml-10 font-size-14"
            for="txtreadaccept"
          >
            {{ $t("READ_AND_ACCEPT") }}:</label
          >
        </div>
        <span
          v-if="errorTerms > 0"
          class="error_messages pl-0 ml-28 pt-02 "
        >
          {{ $t("PLEACECLICK_SINGLE_LINKBEFOR_CHECKBOX") }}
        </span>
        <div class="ip-guid ml-30 pl-0 font-size-14" v-if="config.Access_Guide_URL && config.Access_Guide_URL.trim() !== ''">
          <b id="accessguid">
            <a
              v-on:click="
              openIPAddressAccessGuidURL(
                config.Access_Guide_URL,
                index
              )"
            >
              {{ $t("IP_ADDRESS_GUIDE") }}
              <img
                :src="`${$CDN_URL}images/OpenNewWindow.svg`"
                width="14" /></a
          ></b>
        </div>
      </div>

      <!-- Next And Cancel button -->
      <div class="col-12 pt-3 pb-2 text-left">
        <button v-if="type === 'add'"
          id="btnnextButton"
          :disabled="isDisabledNextButton"
          class="btn btn-primary"
        >
          {{ $t("NEXT") }}
        </button>
        <span v-else
          v-tooltip="{
            value: $t('RESUBMIT_PLAN_DISABLE_TOOLTIP'),
            class: 'resubmit-tooltip',
            disabled: !isDisabledNextButton,
          }"
        >
          <button
            id="btnnextButton"
            class="btn btn-primary"
            :disabled="isDisabledNextButton"
          >
            {{ $t("RESUBMIT_PLAN") }}
          </button>
        </span>
        <button
          id="btnsaveAsDraftButton"
          type="button"
          class="btn btn-outline-secondary"
          @click="closeModel()"
        >
          {{ $t("CANCEL") }}
        </button>
      </div>
    </Form>
  </div>
</template>
<script>
import CommonFunctions from "./common-function";
import * as yup from "yup";
import { Form, Field } from "vee-validate";
import EnterCredential from "@/services/enterCredentialsServiceV2";
import errorHandler from "@/utils/errorHandler/errorHandler";

export default {
  extends: CommonFunctions,
  components: {
    Form,
    Field
  },
  setup() {
    const validateRules = yup.object().shape({
      accessKey: yup.string().required(),
      secretKey: yup.string().required(),
      bucket: yup.string().required(),
    });
    return {
      validateRules
    };
  },
  data() {
    return {
      credentials: { accessKey: "", secretKey: "", bucket: "", folder: "" },
      config: {},
      showProjectValidationError: true,
    }
  },
  methods: {
    setPreFillEnterCredentialDetails() {
      let enterCredentialData = this.$store.state.enterCredentialValue;
      if (this.$store.state.enterCredentialValue && this.$store.state.enterCredentialValue.length) {
        this.credentials.accessKey = enterCredentialData[0].planaccountname;
        this.credentials.secretKey = enterCredentialData[0].planaccesskey;
        this.credentials.bucket = enterCredentialData[0].planbucketname;
        this.credentials.folder = enterCredentialData[0].planfolder;
      } else {
        this.credentials.accessKey = '';
        this.credentials.secretKey = '';
        this.credentials.bucket = '';
        this.credentials.folder = '';
      }
    },
    enableValidationCredentials() {
      if (
        (this.credentials.accessKey &&
        this.credentials.secretKey &&
        this.credentials.bucket &&
        this.defaultValidateCredentialsValue)
      ) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
        this.isDisabledNextButton = true;
        this.suspended = false;
        this.progressCompleted = false;
      }
    },
    async addEnterCredentials() {
      this.$emit('dataLoading', true);
      this.isDisabledNextButton = true;
      let payloadValue = {
        projectID: this.type === 'add' ? this.selectedProject.projectID : this.editProject.projectID,
        bucketInfo: {
          container: this.credentials.bucket,
          directory: this.credentials.folder,
        },
        credentials: {
          accountName: this.credentials.accessKey,
          accountKey: this.credentials.secretKey,
        },
      };

      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let addCredentials = new EnterCredential(user);

      addCredentials.saveConfigurePlan(payloadValue).then((data) => {
        if (data.type === "Success" && data.code === "200") {
          if (this.type === 'add') {
            this.isDisabledNextButton = false;
            let cs = this.$store.state.currentStep;
            this.$store.commit("setCurrentStep", eval(cs + 1));
            this.$store.commit("updatePlanBucketInfo", {
              ID: this.type === 'add' ? this.selectedProject.projectID : this.editProject.projectID,
              bucket: {
                bucketInfo: {
                  bucket: this.credentials.bucket,
                  directory: this.credentials.folder,
                },
              },
            });
            this.$store.commit("updateProjectAlertIcon", {
              ID: this.type === 'add' ? this.selectedProject.projectID : this.editProject.projectID,
            });
            this.$emit("currentStep-emit", { position: this.currentStep });
            let credentialsData ={
              ID: this.type === 'add' ? this.selectedProject.projectID : this.editProject.projectID,
              planaccountname: this.credentials.accessKey,
              planaccesskey: this.credentials.secretKey,
              planbucketname: this.credentials.bucket,
              planfolder: this.credentials.folder,
            };
            this.$store.commit("UpdateEnterCredentialConfigPlanData", [
              credentialsData,
            ]);
            this.$emit('dataLoading', false);
          } else if (
            this.$store.getters
              .getProjectPlansStatus(this.editProject.projectID)
              .includes("Validation Failed") &&
            (this.$store.getters
              .getProjectPlansFailedReason(this.editProject.projectID)
              .includes("Invalid Credentials") ||
              this.$store.getters
                .getProjectPlansFailedReason(this.editProject.projectID)
                .includes("Insufficient Permissions") ||
              this.$store.getters
                .getProjectPlansFailedReason(this.editProject.projectID)
                .includes("Service Disruption"))
          ) {
            this.updateConnectivity(this.editProject.projectID);
            this.isDisabledNextButton = false;
          } else {
            this.$emit('dataLoading', false)
            this.isDisabledNextButton = false;
            this.closeModel();
          }
        } else {
          this.$emit('dataLoading', false)
          this.isDisabledNextButton = false;
          errorHandler(this);
        }
      });
    },
    ValidateCredentials() {
      this.progressSpinner = true;
      let param = {
        Code: this.code,
        storageProtocol: this.storageProtocol,
        authType: this.authType,
        accountName: this.credentials.accessKey,
        accountKey: this.credentials.secretKey,
        container: this.credentials.bucket,
        directory: this.credentials.folder,
      };
      let plan = this.$store.getters.getPlansFromProjectId(
        this.type === 'add' ? this.selectedProject.projectID : this.editProject.projectID,
      );
      if (plan.length) {
        param.endpoint = plan[0].endPointURl ? plan[0].endPointURl : "";
      }
      this.commonValidation(param);
    }
  }
}
</script>
<style lang="scss" scoped>
.controlHeader {
  font-weight: 500;
}
.form-control {
  width: 75%;
}
.placeholder {
  width: 60%;
}
.optionalText {
  color: $optional_text_color !important;
  position: absolute;
  left: 0px;
  bottom: -16px;
}
.helpTextWidth {
  width: 90%;
}
.helpTextWidthbucket {
  width: 87%;
}
.btn-secondary.sucessColor {
  background: none !important;
  color: $primary_color !important;
  border: none;
  background-color: $grey_background_color !important;
}
.btn.btn-outline-secondary#btnvalidatecredentialsMessage {
  height: 40px;
  width: 100%;
  max-width: 214px;
}
.btn.btn-outline-secondary#btnvalidatecredentials {
  height: 40px;
  width: 100%;
  max-width: fit-content;
}
.suspend div{
  background-color: $red_background_color;
  &.add {
    width: 83%;
  }
}
.btn.btn-outline-secondary.sucessColor {
  background: none !important;
  color: $primary_color !important;
  border: 1px solid $primary_color !important;
}
.validationFaieldText {
  color: $error_color;
}
</style>