<template>
  <div class="project-details projectDetailsBox">
    <div class="grayOuterBox">
      <div class="borderBox">
        <div class="row">
          <div class="col-sm-7 col-md-6 borderRight">
            <div class="p-20">
              <div class="label mb-20">{{ $t('IMPORT_PLAN') }}</div>
              <div class="mb-20"><h5>{{ plan.projectInfo?.name }}</h5></div>
              <div> {{ plan.projectInfo?.description }}</div>
            </div>
          </div>
          <div class="col-sm-5 col-md-6">
            <div class="row">
              <div class="col-7 p-20">
                <div class="label mb-20">{{ $t('IMPORT_DESTINATION') }}</div>
                <div class="mb-20"> <h5>{{ plan.uploadDestination?.uploadDestinationInfo?.name }}</h5></div>
                <div class="font-weight-bold">
                  {{ $t('PLAN_VALIDATION')}}:
                  <span>
                    <img
                      class="text-top"
                      :src="`${$CDN_URL}images/status-icons/completed.svg`"
                      width="18"
                    />
                  </span>
                </div>
                <div class="font-weight-bold"> {{ $t('LAST_VALIDATION')}}: {{ plan.lastValidationOn ? $filters.dateFormat(plan.lastValidationOn) : "&#8212;"}}</div>
                <div class="font-weight-bold"> {{ $t('REGION')}}: {{ plan.uploadDestination?.region }}</div>
                <div class="font-weight-bold" v-if="plan.Code === 'azure'"> {{ $store.getters.getDestinationBuketLabel(plan?.uploadDestination?.provider) }}: {{ plan.bucketInfo?.container }}</div>
                <div class="font-weight-bold" v-else> {{ $store.getters.getDestinationBuketLabel(plan?.uploadDestination?.provider) }}: {{ plan.bucketInfo?.bucket }}</div>
              </div>
              <div class="col-5 p-20 providerWrapper">
                <img class="mr-10 icon" :src="logoUrl" width="180" @error="commonErrorImage">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logoUrl: ''
    }
  },
  props: ["plan"],
  async mounted() {
    this.logoUrl = this.$store.getters.getDestinationLogo(this.plan?.uploadDestination?.provider);
  }  
}
</script>
<style lang="scss" scoped>
.label {
  color: $label_color;
  font-size: 12px;
}
.grayOuterBox {
  background: $border-color_1;
  border: 1px solid $disabled_btn_color;
  padding: 20px;
  border-radius: 4px;
}
.text-top {
  vertical-align: sub;
}
.borderBox {
  border: 1px solid $light_grey_color;
  background: $white_color;
  border-radius: 4px;
  padding: 20px;
  .borderRight {
    border-right: 2px solid $light_grey_color;
  }
}
.providerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  .icon {
    width: 180px;
    min-height: 100px;
  }
}
</style>
