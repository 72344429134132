import {HotspotService} from './hotspot-service';
export default class UserServiceV2 extends HotspotService{

  async getUserPermissions() {
    try {
      let response = await this.axios.request({
        method: "get",
        url: `${this.API_BASE_URL}/api/v1/getUserPermissions`
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }
}
