<template>
  <div class="col-md-12 mb-0 mt-0 pl-0 pr-0">
    <slot name="body">
        <Form
          @submit="addUploadDestination"
          :validation-schema="validateRules"
          v-slot="{ errors }"
        >
          <div class="row">
            <div class="col-md-12 titleBox mb-0">
                <h5 class="font-size-16 font-weight-bold pt-2 mb-0">{{ $t("IMPORT_DESTINATION") }}</h5>
                <p class="messageFont font-weight-medium">{{$t("IMPORT_DESTINATION_MESSAGE")}}</p>
            </div>
            <div class="col-6">
              <label>{{ $t("NAME") }}</label>
              <div class="p-inputgroup">
                <span
                  :class="`p-float-label ${errors.name ? 'error_label' : ''}`"
                >
                  <Field
                    id="name"
                    name="name"
                    type="text"
                    v-model="name"
                    :class="`form-control ${name ? 'p-filled' : ''} `"
                  />
                  <label for="name" class="placeholder">
                    {{ $t("NAME") }}
                  </label>
                </span>
              </div>
            </div>
            <div class="col-6">
              <label>{{ $t("DESCRIPTION") }}</label>
              <div class="p-inputgroup">
                <span
                  :class="`p-float-label ${
                    errors.description ? 'error_label' : ''
                  }`"
                >
                  <Field
                    name="description"
                    id="description"
                    type="text"
                    v-model="description"
                    :class="`form-control ${description ? 'p-filled' : ''} `"
                  />
                  <label for="description" class="placeholder">
                    {{ $t("DESCRIPTION") }}
                  </label>
                </span>
              </div>
              <p class="optionalLable">{{ $t("OPTIONAL") }}</p>
            </div>
            <div class="col-md-12 titleBox mb-0">
                <h5 class="font-size-16 font-weight-bold pt-2 mb-0 ">{{ $t("CLOUD_DESTINATION") }}</h5>
                <p class="messageFont font-weight-medium">{{$t("CLOUD_DESTINATION_MESSAGE")}}</p>
            </div>
            <div class="col-6">
              <label>
                {{ $t("CLOUD_DESTINATION") }}
              </label>
              <div class="p-inputgroup">
                <span
                  :class="`p-float-label ${
                    errors.selectedDestination ? 'error_label' : ''
                  }`"
                >
                  <Field
                    v-slot="{ field }"
                    v-model="selectedDestination"
                    name="selectedDestination"
                    value="value"
                  >
                    <Dropdown
                      id="cboSelectDestination"
                      name="selectedDestination"
                      v-model="selectedDestination"
                      :options="cloudDestination"
                      optionLabel="name"
                      :model-value="field.value"
                      @change="
                        getSelectedCloudRegion(selectedDestination),
                          field.onChange.forEach((fn) => fn($event.value))
                      "
                      @input="field.onInput.forEach((fn) => fn($event.value))"
                      :class="{
                        'p-filled': errors.selectedDestination,
                      }"
                    />
                  </Field>
                  <label for="cboSelectDestination" class="placeholder">
                    {{ $t("PLEASE_SELECT") }}
                  </label>
                </span>
              </div>
            </div>
            <div class="col-6 ">
              <label>{{ $t("REGION") }}</label>
              <div class="p-inputgroup">
                <span
                  :class="`p-float-label ${
                    errors.selectedRegion ? 'error_label' : ''
                  }`"
                >
                  <Field
                    v-slot="{ field }"
                    v-model="selectedRegion"
                    name="selectedRegion"
                    value="value"
                  >
                    <Dropdown
                      id="cboSelectRegion"
                      name="selectedRegion"
                      v-model="selectedRegion"
                      :options="cloudRegion"
                      optionLabel="value"
                      :model-value="field.value"
                      @change="field.onChange.forEach((fn) => fn($event.value))"
                      :class="{ 'p-filled': errors.selectedRegion }"
                      @input="field.onInput.forEach((fn) => fn($event.value))"
                    />
                  </Field>

                  <label for="cboSelectRegion" class="placeholder">
                    {{ $t("PLEASE_SELECT") }}
                  </label>
                </span>
              </div>
            </div>
            <div class="col-md-12 mt-0 mb-0">
              <button class="backBtnShipping" @click="$emit('close'), windowOverflow()">
                <i class="lv-backArrow lv-icon mr-4" />
                  <span style="height:24px">{{ $t("BACK") }}</span>
                </button>
            </div>
          </div>
          <div class="col-6 mt-20 mb-0 p-0">
          <button class="btn btn-seagate-primary">
            {{ $t("NEXT") }}
          </button>
          <button
            class="btn btn-outline-secondary"
            @click="closeImportDestinationModel()"
          >
            {{ $t("CANCEL") }}
          </button>
        </div>
        </Form>

    </slot>
  </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import * as yup from "yup";
import { Form, Field } from "vee-validate";
import UploadDestinationServiceV2 from "../../services/uploadDestination-service-v2.js";
export default {
  components: {
    Dropdown,
    Form,
    Field,
  },
  emits: ["close", "openPopup"],
  setup() {
    const validateRules = yup.object().shape({
      name: yup.string().required().max(30),
      selectedDestination: yup.object().required(),
      selectedRegion: yup.object().required(),
      description: yup.string().min(0).max(300),
    });
    return {
      validateRules,
    };
  },
  data() {
    return {
      name: "",
      description: "",
      cloudRegion: [],
      selectedDestination: null,
      selectedRegion: null,
      descriptionRules: yup
        .string()
        .notRequired()
        .when("descriptionRules", {
          is: (value) => value.length,
          then: (rule) => rule.max(3),
        }),
    };
  },
  methods: {
    async addUploadDestination() {
      let uploaddestination = {
        uploadDestination: {
          name: this.name,
          destination: this.selectedDestination.name,
          region: this.selectedRegion.value,
          description: this.description,
        },
      };
      let getDetails = {
        uploadDestination: {
          name: this.name,
          destination: this.selectedDestination,
          region: this.selectedRegion,
          description: this.description,
        },
      };
      this.$store.commit("getEditUploadDestionation", getDetails);
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let uploadDestinationServiceV2 = new UploadDestinationServiceV2(user);
      let message = "";
      await uploadDestinationServiceV2
        .insertUploadDestination(uploaddestination)
        .then((data) => {
          if (
            data.messages[0] ===
            "UploadDestination details created successfully"
          ) {
            this.$store.commit("updateUploadDestination", data.data);
            message = {
              severity: "info",
              content:
                this.$t("CLOUD_DESTINATION_SAVED"),
            };
            this.$store.commit("setResponseMessage", message);
            this.$emit("close");
            this.windowOverflow();
            this.$emit("openPopup");
          } else {
            message = {
              severity: "error",
              content: data.messages[0],
            };
            this.$store.commit("setResponseMessage", message);
            this.$emit("close");
            this.windowOverflow();
            this.$emit("openPopup");
          }
        })
        .catch(() => {
          message = {
            severity: "error",
            content: this.$t("ERROR-DIALOG-BODY"),
          };
          this.$store.commit("setResponseMessage", message);
          this.$emit("close");
          this.$emit("openPopup");
          this.windowOverflow();
        });
    },
    getSelectedCloudRegion(selectedDestination) {
      let cloudArray = this.$store.state.masterConfigurationData.data.filter(
        (project) => project.name === selectedDestination.name
      );
      for (var i = 0; i < cloudArray.length; i++) {
        this.cloudRegion = cloudArray[i].regions;
      }
    },
    windowOverflow() {
      document.body.style.overflowY = "scroll";
    },
    closeAddModel(){
      this.$emit("closeAddDestinationtableModal",this.showModal=false);
    },
    closeImportDestinationModel(){
      this.$emit("closeAddDestinationtableModal", { position: (this.showModal = false) });
    },
  },
  async mounted() {
    if (this.editUploadDestination) {
      let storeData = this.editUploadDestination;
      this.name = storeData.name;
      this.description = storeData.description;
      this.selectedDestination = storeData.destination;
      this.selectedRegion = storeData.region;
    }
    document.body.style.overflow = "hidden";
  },
  watch: {
    selectedDestination() {
      this.getSelectedCloudRegion(this.selectedDestination);
    },
  },
  computed: {
    cloudDestination() {
      return this.$store.state.masterConfigurationData.data;
    },
    editUploadDestination() {
      return this.$store.state.showUploadDestinationDetails.uploadDestination;
    },
  },
};
</script>
<style lang="scss" scoped>
.formBody {
  width: 100%;
}
.p-dropdown-panel.p-component {
  z-index: 99999 !important;
}
.minHeightRow {
  #addUploadDestinationModal {
    .p-dropdown {
      .p-dropdown-label {
        padding: 0.5rem 0 0 !important;
      }
    }
  }
}
.required {
  &::before {
    content: " * ";
    color: $red_color;
  }
}
.input {
  .hasError {
    border-color: $red_color;
  }
}
.p-dropdown {
  .hasError {
    border-color: $red_color;
  }
}
.is-invalid {
  border: 1px solid $red_color !important;
}
.closePopup {
  .bi {
    font-size: 25px;
    margin-top: -15px;
    display: inline-block;
  }
}
.optionalLable {
  font-size: 11px;
  margin-top: -6px;
  padding-left: 15px;
  color: $option_label_color;
}
#rightContent {
  .p-dropdown {
    &::v-deep {
      .p-dropdown-trigger {
        background: $transparent_color !important;
        color: $transparent_color !important;
      }
    }
  }
}
.modal-body {
  margin: 0px auto;
  padding: 0px !important;
}
.modal-container {
  width: 600px;
  margin: 50px auto;
  position: relative;
}
.modal-wrapper {
  height: 100vh;
  overflow-y: auto;
  display: block;
}
.backBtnShipping{
  margin-left: 0px;
}
.messageFont{
  font-size: 11px !important;
}
</style>