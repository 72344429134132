<template>
  <div class="borderBox pt-0">
    <div class="row d-flex align-items-center">
      <div class="col-md-1"></div>
      <div class="col-md-4 font-weight-bold">
        <div class="deviceNameBox">
          <h5 class="mb-20">{{ plan.name }}</h5>
          <p class="mb-5">{{$t('SERIAL_NUMBER')}}: {{plan.deviceSerialNumber}}</p>
          <p>
            {{$t('TRACKING_NUMBER')}}:
            <a
              class="greenText"
              :href="
              'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' + plan.trackingNumber + '&requester=ST/'"
              target="_blank">
              {{plan.trackingNumber}}
              <img
                v-if="plan.trackingNumber"
                class="ml-5 oArraw"
                :src="`${$CDN_URL}images/OpenNewWindow.svg`"
                width="14"
              />
            </a>
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <img
          class="productImage"
          :src="`${$CDN_URL}images/devices/Mobile%20Array.png`"
          width="200"
        />
      </div>
    </div>
      <horizontalSteps :plan="plan"/>
  </div>
</template>
<script>
import horizontalSteps from "./HorizontalStepper.vue";
export default {
  data() {
    return {};
  },
  props: ["plan"],
  components: {
    horizontalSteps
  },
};
</script>
<style scoped>
.grayOuterBox {
  background: #f5f5f5;
  border: 1px solid #939191;
  padding: 20px;
  border-radius: 4px;
}
.borderBox {
  border: 1px solid #e2e2e2;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
}
.oArraw {
  position: relative;
  top: -2px;
}
.deviceNameBox {
  padding-left: 6px;
}
@media (min-width: 1599px) {
  .deviceNameBox {
    padding-left: 15px;
  }
}
</style>