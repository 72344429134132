import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    state() {
        return {
            count: 0,
            user: { token: '' },
            userrole:0,
            projects: [],
            plans: [],
            account: {},
            endpoints: [],
            messages: [],
            message: {},
            currentProject: null,
            responseMessage: {},
            cloudDestination: [],
            masterConfigurationData: [],
            uploadDestinations: [],
            showUploadDestinationDetails: {},
            currentStep: 1,
            showModal: false,
            sucessModalMessage: false,
            enterCredentialValue:null,
            showErrorDialog: false,
            errorDialogTitle: '',
            errorDialogBody: '',
            defaultCountry: 'United States',
            defaultLanguage: 'English',
            defaultLocale: 'en-us',
            selectedProject:[],
            defaultLptagLocale: 'us english'
        }
    },
    getters: {
        getDefaultCountry:(state) => {
            return state.defaultCountry;
        },
        getDefaultLanguage:(state) => {
            return state.defaultLanguage;
        },
        getDefaultLocale:(state) => {
            return state.defaultLocale;
        },
        getProjectPlansWithFirewallIssue: (state) => (projectId) => {
            return state.plans.filter(plan => (
                plan.projectID == projectId &&
                plan.status && plan.status == 'Validation Failed' &&
                plan.reason && plan.reason == 'Firewall Issue'));
        },
        getProjectPlansWithCredentialsDisruptionAndPermissionIssue: (state) => (projectId) =>{
            return state.plans.filter(plan => (
                plan.projectID == projectId &&
                plan.status && plan.status == 'Validation Failed' &&
                (
                    plan.reason && plan.reason == 'Invalid Credentials' ||
                    plan.reason && plan.reason == 'Insufficient Permissions' ||
                    plan.reason && plan.reason == 'Service Disruption'
                )));
        },
        getProjectPlansStatus: (state) => (projectId) => {
            return state.plans.filter(plan => (
                plan.projectID == projectId
            )).map(p => p.status);
        },
        getProjectPlansFailedReason: (state) => (projectId) => {
            return state.plans.filter(plan => (
                plan.projectID == projectId &&
                plan.status && plan.status == 'Validation Failed'
            )).map(p => p.reason);
        },
        getPlansFromProjectId: (state) => (projectId) => {
            return state.plans.filter(plan => (
                plan.projectID == projectId
            ))
        },
        getDestinationImage: (state) => (provider) => {
            if (state.masterConfigurationData && state.masterConfigurationData.data) {
                return state.masterConfigurationData.data.filter(config => (
                    config.name === provider
                )).map(c => c.Thumbnail);
            } else {
                return '';
            }
        },
        getDestinationLogo: (state) => (provider) => {
            if (state.masterConfigurationData && state.masterConfigurationData.data) {
                return state.masterConfigurationData.data.filter(config => (
                    config.name === provider
                )).map(c => c.Logo);
            } else {
                return '';
            }
        },
        getDestinationBuketLabel: (state) => (provider) => {
            if (state.masterConfigurationData && state.masterConfigurationData.data) {
                return state.masterConfigurationData.data.filter(config => (
                    config.name === provider
                )).map(c => c.labels[2].name)[0];
            } else {
                return '';
            }
        },
        getDestinationConfig: (state) => (authType, storageProtocol, code) => {
            if (state.masterConfigurationData && state.masterConfigurationData.data) {
                return state.masterConfigurationData.data.filter(config => (
                    config.authType.trim().toLowerCase() === authType &&
                    config.storageProtocol.trim().toLowerCase() === storageProtocol &&
                    config.code.trim().toLowerCase() === code
                ));
            } else {
                return [];
            }
        },
        getProjectById: (state) => (projectId) => {
            return state.projects.filter(project => (
                project.projectID == projectId
            ))
        },
        getPlanById: (state) => (planId) => {
            return state.plans.filter(plan => (
                plan._id == planId
            ))
        },
        getCloudCredentials:(state) => (projectId) =>{
            let plans = state.plans.filter(plan => (
                plan.projectID == projectId
            ))            
           
            if(!plans){
                return false;
            }
            let credential = state.enterCredentialValue[0];
            if(!credential) return false;

            let authType = plans[0].authType;
            let storageProtocol = plans[0].storageProtocol;
            let code  = plans[0].Code;
            switch (storageProtocol.toLowerCase()) {
                case 'blob'://possible values blob/S3
                switch (authType.toLowerCase()) {
                    case 'shared key':
                    switch (code.toLowerCase()) {
                        case 'azure':
                        return {accountName:credential.planaccountname,accountKey:credential.planaccesskey};
                        default:
                        return false;
                    }
                    default:
                    return false;
                }
                case 's3':
                switch (authType.toLowerCase()) {
                    case 'access key':
                        return {accessKey:credential.planaccesskey,secretKey:credential.plansecretkey};
                    case 'aws arn assumed role':
                        return {customerAccountId:credential.customeraccountid,roleName:credential.roleName};
                    default:
                        return false;
                }
                default:
                return false;
            }
        }
    },
    mutations: {
        setDefaultCountry(state, country) {
            state.defaultCountry = country;
        },
        setDefaultLanguage(state, lang) {
            state.defaultLanguage = lang;
        },
        setDefaultLocale(state, locale) {
            state.defaultLocale = locale;
        },
        setLptagLocale(state, locale) {
            state.defaultLptagLocale = locale;
        },
        increment(state) {
            state.count++
        },
        setUser(state, user) {
            state.user = user;
        },
        setUserRole(state, userrole) {
            state.userrole = userrole;
        },
        setProjects(state, projects) {
            state.projects = projects.map((project) => ({
                ...project,
                contentVisible: false,
                showAlertIcon: false,
                showConfigureButton: false,
                isDisabled: true
            }));
        },
        setPlans(state, plans) {
            state.plans = plans;
        },

        updateAllPlanStatus: function (state, payload) {
            for (var j = 0; j < state.plans.length; j++) {
                if (state.plans[j].projectID === payload.ID) {
                    state.plans[j].status = payload.status
                }
            }
        },
        updateConfigureButton(state, payload) {
            for (var i = 0; i < state.projects.length; i++) {
                if (state.projects[i].projectID === payload.ID) {
                    {
                        state.projects[i].showConfigureButton = false
                        state.projects[i].showAlertIcon = false
                        state.projects.splice(i, 1, state.projects[i]);
                        return;
                    }
                }
            }
        },
        updatePlanBucketInfo(state, payload) {
            for (var j = 0; j < state.plans.length; j++) {
                if (state.plans[j].projectID === payload.ID) {
                    let bucketInfo = payload.bucket.bucketInfo
                    state.plans[j] = { ...state.plans[j], bucketInfo }
                    state.plans.splice(j, 1, state.plans[j]);
                    return;
                }
            }
        },
        updateProjectAlertIcon(state, payload) {
            for (var i = 0; i < state.projects.length; i++) {
                if (state.projects[i].projectID === payload.ID) {
                    {
                        state.projects[i].showAlertIcon = true
                        state.projects.splice(i, 1, state.projects[i]);
                        return;
                    }
                }
            }
        },
        updatePlanStatus: function (state, payload) {
            for (var j = 0; j < state.plans.length; j++) {
                if (state.plans[j]._id === payload.ID) {
                    state.plans[j].status = payload.status
                    state.plans.splice(j, 1, state.plans[j]);
                }
            }
        },
        updatePlan: function (state, payload) {
            const index = state.plans.findIndex(plan => plan._id === payload._id);
            if (index !== -1) state.plans.splice(index, 1, {...state.plans[index], ...payload});
        },
        updateProject: function (state, payload) {
            const index = state.projects.findIndex(project => project._id === payload._id);
            if (index !== -1) state.projects.splice(index, 1, {...state.projects[index], ...payload});
        },

        SetEnterCredentialConfigPlanData(state,configData){
            state.enterCredentialValue = configData;
        },
        UpdateEnterCredentialConfigPlanData(state,configData){
            for (var j = 0; j < state.plans.length; j++) {
                if (state.plans[j].projectID === configData[0].ID) {
                    state.enterCredentialValue = configData;
                }
            }
        },
        setEndpoints(state, endpoints) {
            endpoints.forEach((obj) => {
                for (var i in obj) {
                    if (obj[i] === "") {
                        obj[i] = '-';
                    }
                }
            });
            state.endpoints = endpoints;
        },
        setCurrentProject(state, project) {
            state.currentProject = project;
        },
        setAccount(state, account) {
            state.account = account;
        },
        addMessage(state, message) {
            let messages = state.messages;
            messages.push(message);
            state.messages = messages;
        },
        setMessage(state, message) {
            state.message = message;
        },
        setResponseMessage(state, responseMessage) {
            state.responseMessage = responseMessage
        },
        setCloudDestination(state, cloudDestination) {
            state.cloudDestination = cloudDestination
        },
        setMasterConfiguration(state, masterConfigurationData) {
            let dataArr = masterConfigurationData.data.map(item=>{
                return [item.code, item]
            }); // creates array of array
            let maparr = new Map(dataArr); // create key value pair from array of array
            let result = [...maparr.values()];//converting back to array from mapobject
            masterConfigurationData.data = result
            state.masterConfigurationData = masterConfigurationData;
        },
        setUploadDestination(state, uploadDestinations) {
            state.uploadDestinations = uploadDestinations
        },
        setCurrentStep(state, currentStep) {
            state.currentStep = currentStep;
        },
        closeEnterCredentialModel(state, showModal) {
            state.showModal = showModal
        },
        sucessModalMessage(state, sucessMessage) {
            state.sucessModalMessage = sucessMessage
        },
        setErrorDialogVisibility(state, visibility) {
            state.showErrorDialog = visibility;
        },
        setErrorDialogTitle(state, title) {
            state.errorDialogTitle = title;
        },
        setErrorDialogBody(state, body) {
            state.errorDialogBody = body;
        },
        updateUploadDestination(state,payload){
            state.uploadDestinations.push(payload)
        },
        getEditUploadDestionation(state,payload){
            state.showUploadDestinationDetails = payload

        },
        SetEnterCredentialselectedProject(state,selectedProject){
            state.selectedProject = selectedProject
        }
    },
    plugins: [createPersistedState()],
})

export default store;