<template>
  <div>
    <section id="mainContent" class="innerPages" v-if="showApp">
      <CommonHeader v-if="showHeaderRoutes.includes($route.name) "/>
      <router-view></router-view>
      <CommonFooter v-if="showHeaderRoutes.includes($route.name)"/>
      <ErrorDialog
        v-if="$store.state.showErrorDialog"
        @closeModel="closeErrorDialog"
      ></ErrorDialog>
      <div class="auto-logout">
        <AutoLogout></AutoLogout>
      </div>
    </section>
  </div>
</template>
<script>
import CommonHeader from "./controls/CommonHeader.vue";
import CommonFooter from "./controls/CommonFooter.vue";
import ErrorDialog from "./controls/ErrorDialog.vue";
export default {
  name: "App",
  data() {
    return {
      showApp: false,
      showHeaderRoutes: ['home', 'create-workflow', 'plan-details']
    }
  },
  components: {
    CommonHeader,
    CommonFooter,
    ErrorDialog
  },
  async mounted() {
    try {
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      if (accessToken) {
        this.showApp = true;
      } else {
        window.location.href = process.env.VUE_APP_LYVE_URL;
      }
    } catch (error) {
      window.location.href = process.env.VUE_APP_LYVE_URL;
    }
    this.$store.commit('setErrorDialogVisibility', false);
  },
  watch: {
    "$store.state.defaultLocale"() {
      this.updateLptagLocale(this.$store.state.defaultLocale);
    }
  },
  computed: {
    isLoggedIn() {
      return this.$auth ? this.$auth.isAuthenticated.value : false;
    }
  },
  methods: {
    closeErrorDialog() {
      this.$store.commit('setErrorDialogVisibility', false);
    },
    updateLptagLocale(locale) {
      let lptagLocale = ''
      switch (locale) {
        case 'en-us':
          lptagLocale = 'us english';
          break;
        case 'en-gb':
          lptagLocale = 'uk english';
          break;
        case 'it-it':
          lptagLocale = 'italian';
          break;
        case 'nl-nl':
          lptagLocale = 'dutch';
          break;
        case 'pl-pl':
          lptagLocale = 'polish';
          break;
        case 'es-es':
          lptagLocale = 'spanish';
          break;
        case 'de-de':
          lptagLocale = 'german';
          break;
        case 'fr-fr':
          lptagLocale = 'french';
          break;
      }
      this.$store.commit('setLptagLocale', lptagLocale);
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  font-family: $font-family;
}
.messages {
  width: 30rem;
  z-index: 1;
  position: absolute;
  right: 0%;
  text-align: left;
}
</style>
