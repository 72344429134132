<template>
  <div class="ml-35">
    <ul id="verticalPrgressStepper" class="md-stepper-vertical">
      <li
        v-for="(step, index) in getSteps()"
        :key="index"
        :class="{
          'md-step': true,
          pending: currentStep < step.step,
          current: currentStep === step.step ? true : false,
          completed: currentStep > step.step,
        }"
      >
        <div class="stepper-alignment-wrapper">
          <span class="md-step-circle" @click="getCurentStep()">
            <svg
              width="1.5em"
              height="1.5em"
              viewBox="0 0 16 16"
              class="bi bi-check"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
              />
            </svg>
            <span>{{ index + 1 }}</span>
          </span>
          <p class="md-step-title clickable" v-if="step.clickable" @click="goToStep(step.step)">{{ step.name }}</p>
          <p class="md-step-title" v-else>{{ step.name }}</p>
        </div>
        <div class="md-step-bar"></div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["currentStep"],
  data() {
    return {
      steps: [{
				name: this.$t("IMPORT_DESTINATIONS"),
				route: "",
				current: false,
				pending: false,
				completed: false,
				clickable: true,
				step: 0,
			},
			{
				name: this.$t("ENTER_CREDENTIALS"),
				route: "",
				current: false,
				pending: false,
				completed: false,
				clickable: false,
				step: 1,
			},
			{
				name: this.$t("REVIEW_AND_SUBMIT"),
				route: "",
				current: false,
				pending: false,
				completed: false,
				clickable: false,
				step: 2,
			}],
			amazonSteps : [{
				name: this.$t("IMPORT_DESTINATIONS"),
				route: "",
				current: false,
				pending: false,
				completed: false,
				clickable: true,
				step: 0,
			},
			{
				name: this.$t("PREREQUISITES_STEP"),
				route: "",
				current: false,
				pending: false,
				completed: false,
				clickable: false,
				step: 1,
			},
			{
				name: this.$t("ACCESS_DETAILS"),
				route: "",
				current: false,
				pending: false,
				completed: false,
				clickable: false,
				step: 2,
			},
			{
				name: this.$t("REVIEW_AND_SUBMIT"),
				route: "",
				current: false,
				pending: false,
				completed: false,
				clickable: false,
				step: 3,
			}]
	};
  },
  methods: {
		getSteps() {
			let plan = this.$store.getters.getPlansFromProjectId(this.$store.state.selectedProject.projectID);
			if (
					plan &&
					plan.length &&
					plan[0].authType.trim().toLowerCase() === 'aws arn assumed role' &&
					plan[0].Code.trim().toLowerCase() === 'aws' &&
					plan[0].storageProtocol.trim().toLowerCase() === 's3'
				) {
				return this.amazonSteps;
			} else {
				return this.steps;
			}
		},
    goToStep(step) {
			this.$store.commit("setCurrentStep", step);
    }
  }
};
</script>
<style lang="scss" scoped>
.plan-stepper-wrapper {
	padding: 30px 30px;
	border-radius: 5px;
	border-radius: 5px;
	.plan-stepper {
		border-right: 2px solid $stepper_right_border_color;
		display: flex;
		align-items: center;
		.stepper-alignment-wrapper {
			display: flex;
			align-items: center;
		}
	}
	.form-wrapper {
		width: 100%;
		min-height: 56vh;
		display: flex;
		align-items: flex-end;
		justify-content: right;
	}
}
.md-step-circle {
	height: 33px;
	width: 33px;
	border-radius: 50%;
	border-radius: 50%;
	text-align: center;
	font-weight: 600;
	color: $msg_color;
	position: relative;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	.disabled {
		border: 2px solid $stepper_disabled_border_color;
		background: transparent;
	}
}
.md-step-circle.pending {
	border: 2px solid $primary_color;
}
.md-step.completed {
	.md-step-circle {
		border: 2px solid $primary_color;
	}
	.md-step-bar {
		border: 2px solid $primary_color;
		border-bottom: 0px;
		border-top: 0px;
		border-right: 0px;
	}
}
.md-step.current {
	.md-step-circle {
		background-color: $primary_color;
	}
}
.activated {
	box-shadow: 0px 0px 3px 2px rgba(194, 255, 194, 0.8);
}
.md-stepper-vertical {
	.md-step-bar {
		height: 90px;
		margin-left: 15px;
		border: 2px solid $stepper_step_border_color;
		border-bottom: 0px;
		border-top: 0px;
		border-right: 0px;
	}
	.md-step-bar-bottom {
		height: 30px;
		width: 1px;
		margin-left: 14px;
	}
	.md-step-bar-top {
		height: 30px;
		width: 1px;
		margin-left: 14px;
	}
	li {
		&:last-child {
			.md-step-bar {
				height: 0px;
				width: 0px;
				display: none;
			}
		}
	}
	.md-step.completed {
		.md-step-circle {
			svg {
				display: block;
			}
			span {
				display: none;
			}
		}
	}
	.md-step {
		.md-step-circle {
			svg {
				display: none;
			}
		}
	}
}
.md-step.pending {
	.md-step-bar {
		border: 2px solid $primary_color;
		border-bottom: 0px;
		border-top: 0px;
		border-right: 0px;
	}
	.md-step-circle {
		border: 2px solid $primary_color;
	}
}
.md-step.md-step.current {
	.md-step-circle {
		background-color: $primary_color;
	}
}
li.md-step {
	.md-step-title {
		font-weight: 500;
		margin-left: 15px;
		&.clickable {
			cursor: pointer;
		}
	}
}
li.md-step.completed {
	.md-step-title {
		color: $msg_color;
		font-weight: 500;
	}
}
li.md-step.current {
	.md-step-title {
		color: $msg_color;
		font-weight: 500;
	}
}
li {
	list-style: none;
}
.primary-color {
	color: $primary_color;
}
svg {
	&:not(:root) {
		overflow: hidden;
	}
}

</style>