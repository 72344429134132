<template>
  <section id="mainContent" class="innerPages shippingLabel">
    <div class="imageWrapper">
      <img :src="`data:image/gif;base64, ${imageData}`" />
    </div>
  </section>
</template>
<script>
import PlanConnectivityServiceV2 from "../services/planConnectivityServiceV2.js";
export default {
  data() {
    return {
      imageData: ''
    }
  },
  async mounted() {
    let planID = this.$route.params.id;
    let accessToken = await this.$auth.getTokenSilently();
    accessToken = `Bearer ${accessToken}`;
    let user = { token: accessToken };
    let planConnectivityServiceV2 = new PlanConnectivityServiceV2(user);
    planConnectivityServiceV2.rePrintShippingLabel(planID).then((data) => {
      if (data && data.code === "200") {
        this.imageData = data.data[0].shippingLabel.GraphicImage;
      }
    });
  }
}
</script>
<style lang="scss" scoped>
.imageWrapper {
	width: 100%;
	padding: 50px;
}
img {
	transform: scale(.6) rotate(90deg);
	display: block;
	width: 100%;
	height: auto;
}
</style>