import { createApp } from 'vue'
import App from './App.vue'
import store from './store/store';
import i18n from './localization/i18n';
import router from './router'
import PrimeVue from 'primevue/config';
import authConfigDev from '../auth_config.json'
import authConfigQa from '../auth_config_qa.json'
import authConfigStage from '../auth_config_stage.json'
import authConfigProduction from '../auth_config_production.json'
import authConfigLocal from '../auth_config_local.json'
import Auth from './auth/index.js';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import Tooltip from 'primevue/tooltip';
import "../src/assets/scss/style.scss";
import AutoLogout from "@/components/common/AutoLogout.vue"
const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.use(PrimeVue);
app.component('AutoLogout', AutoLogout);
app.directive('tooltip', Tooltip);
function callbackRedirect(appState) {
  router.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : '/'
  );
}


let authConfig = authConfigDev

if(process.env.VUE_APP_ENV == 'qa') {
  authConfig = authConfigQa;
} else if(process.env.VUE_APP_ENV == 'stage') {
  authConfig = authConfigStage;
} else if(process.env.VUE_APP_ENV == 'production') {
  authConfig = authConfigProduction;
} else if(process.env.VUE_APP_ENV == 'local') {
  authConfig = authConfigLocal;
}
//get these values from environment pipeline
authConfig.domain = process.env.VUE_APP_AUTH_DOMAIN;
authConfig.client_id = process.env.VUE_APP_AUTH_CLIENT_ID;
//global properties
app.config.globalProperties.$CDN_URL = process.env.VUE_APP_CDN_URL;

app.config.globalProperties.$filters = {
  dateFormat(value) {
    if (!value) return ''
    let date = new Date(value);
    return (
      ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/'
      + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))
      + '/' + date.getFullYear()
    )
  }
}

Auth.setupAuth(authConfig, callbackRedirect).then((auth) => {
  app.use(auth).mount('#app')
})