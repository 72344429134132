<template>
  <transition name="modal commonModal">
    <div class="modal-mask confirmImportModal">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="confirm-import" v-if="!importSuccess">
                <span class="bi bi-exclamation-triangle d-block mb-20"></span>
                <div class="title mb-15">{{ $t("CONFIRM_IMPORT") }}</div>
                <div class="pl-60 pr-60">
                  <p>
                    {{ $t("CONFIRM_IMPORT_DESCRIPTION") }}
                  </p>
                </div>
                <div class="form-check mt-30 ml-50 mr-50">
                  <input
                    id="confirm-input"
                    type="checkbox"
                    v-model="checkboxChecked"
                    class="custom-control-input"
                    @change="!checkboxChecked"
                  />
                  <label class="custom-control-label" for="confirm-input">
                    {{ $t("CONFIRM_IMPORT_CHECKBOX") }}
                  </label>
                </div>
                <button
                  class="btn mx-auto d-block mt-30 btn-seagate-primary"
                  :disabled="!checkboxChecked"
                  @click="confirmImport(selectedPlanDetails)"
                >
                  {{ $t("CONFIRM_IMPORT") }}
                </button>
                <button
                  class="btn mx-auto d-block closeBtn"
                  @click="closeModel"
                >
                  {{ $t("CANCEL") }}
                </button>
                <p class="pl-5 pr-5">
                  {{ $t("CONFIRM_IMPORT_ASSITANT_DESCRIPTION") }}
                   <a :href="LyveSupportCenter" class="text-decoration-underline" target="_blank">{{ $t("LYVE_SUPPORT_CENTER") }}</a>.
                </p>
              </div>
              <div class="import-success" v-else>
                <span>
                  <img
                    class="mb-20"
                    :src="`${$CDN_URL}images/status-icons/completed.svg`"
                    width="40"
                  />
                </span>
                <div class="title mb-15">
                  {{ $t("CONFIRM_IMPORT_SUCCESS") }}
                </div>
                <p class="confirm-success-msg">
                  {{ $t("CONFIRM_IMPORT_SUCCESS_DESCRIPTION") }}
                </p>
                <button
                  class="btn mx-auto d-block mt-30 btn-seagate-primary"
                  @click="closeModel"
                >
                  {{ $t("OK") }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import PlanServiceV2 from "../../services/plan-service-v2.js";
const config = require("@/services/settings.json");
export default {
  props: ["selectedPlanDetails"],
  data() {
    return {
      checkboxChecked: false,
      importSuccess: false,
      LyveSupportCenter: config.LYVE_SUPPORT_CENTER
    };
  },
  methods: {
    async confirmImport(planId) {
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let payload = {
        _id: planId,
        status: "Ready for Cryptographic Erase",
      };
      let planServiceV2 = new PlanServiceV2(user);
      planServiceV2.uploadAcknoledgement(payload).then((data) => {
        if (data.type === "Success" && data.code === "200") {
          this.importSuccess = true;
          this.$store.commit("updatePlanStatus", {
            ID: payload._id,
            status: "Ready for Cryptographic Erase",
          });
        }
      });
    },
    closeModel() {
      this.$emit("closeModel");
    }
  },
};
</script>
<style lang="scss" scoped>
.confirm-import {
	padding: 19px;
	text-align: center;
	.custom-control-label {
		&::before {
			left: -20px;
		}
		&::after {
			left: -20px;
		}
	}
}
.title {
	font-size: 18px;
	font-weight: normal;
}
.bi-exclamation-triangle {
	color: $alert_color;
	font-size: 35px;
}
.confirmImportModal {
	.modal-container {
		.virtual-agent {
			color: $primary_color;
			cursor: pointer;
		}
		.form-check {
			padding-left: 35px;
		}
	}
}
.import-success {
	padding: 19px;
	text-align: center;
}
.closeBtn {
	padding-top: 20px;
	padding-bottom: 30px;
}

</style>