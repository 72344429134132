<template>
  <div class="reviewOrder row pl-3">
    <div class="col-12 pb-2 pl-4 wrapper" @click="importDestinationPencilIcon()">
      <h5 class="font-size-16 font-weight-medium">
        {{ $t("IMPORT_DESTINATION_DETAILS") }}
        <b class="ml-8 pointer" width="16"
          ><img :src="require(`@/assets/images/pencil.svg`)" />
        </b>
      </h5>
    </div>

    <div class="col-6 pl-4">
      <label>{{ $t("SOURCE_PROJECT") }}</label>
      <p id="lblSourceProject">{{ selectedProject?.projectInfo?.name }}</p>
    </div>
    <div class="col-6 pb-2">
      <label>{{ $t("NAME") }}</label>
      <p id="lblName">
        {{ selectedProject?.uploadDestination?.uploadDestinationInfo?.name }}
      </p>
    </div>

    <div class="col-6 pl-4">
      <label>{{ $t("CLOUD_DESTINATION") }}</label>
      <p id="lblCloudDestination">
        {{ selectedProject?.uploadDestination?.provider }}
      </p>
    </div>
    <div class="col-6 pb-2">
      <label>{{ $t("REGION") }}</label>
      <p id="lblRegion">
        {{ selectedProject?.uploadDestination?.region }}
      </p>
    </div>

    <div class="col-12 pl-4 pb-3">
      <label>{{ $t("DESCRIPTION") }}</label>
      <p id="lblDescription">
        {{
          selectedProject?.uploadDestination?.uploadDestinationInfo?.description ? selectedProject?.uploadDestination?.uploadDestinationInfo?.description : "&#8212;"
        }}
      </p>
    </div>

    <div class="col-12 ml-10 divider"></div>

    <div class="col-12 pt-3 pb-2 pl-4 wrapper" @click="editCredential()">
      <h5 class="font-size-16 font-weight-medium">
        {{ (config.code.trim().toLowerCase() === 'aws' && config.authType.trim().toLowerCase() === 'aws arn assumed role' && config.storageProtocol.trim().toLowerCase() === 's3') ? $t("ACCESS_DETAILS") : $t("CREDENTIAL_DETAILS") }}
        <b class="ml-8 pointer" width="16"
          ><img :src="require(`@/assets/images/pencil.svg`)" />
        </b>
      </h5>
    </div>
    <div class="row" v-if="(config.code.trim().toLowerCase() === 'aws' && config.authType.trim().toLowerCase() === 'aws arn assumed role' && config.storageProtocol.trim().toLowerCase() === 's3')">
      <div class="col-6 pl-4">
        <label> {{ config?.labels[4]?.name }}</label>
        <p class="font-size-12" id="lblAccessKey" >
          {{ $store.state.enterCredentialValue[0].customeraccountid }}
        </p>
      </div>
      <div class="col-6 pl-2">
        <label>{{ config?.labels[2]?.name }}</label>
        <p id="lblBucket">{{ $store.state.enterCredentialValue[0].planbucketname }}</p>
      </div>
      <div class="col-6 pl-4">
        <label>{{ config?.labels[1]?.name }}</label>
        <p class="font-size-12" id="lblSecretKey">
          {{ awsRoleName }}
        </p>
      </div>
      <div class="col-6 pl-2">
        <label>{{ config?.labels[0]?.name }}</label>
        <p class="font-size-12" id="lblSecretKey">
          {{  $store.state.enterCredentialValue[0].ID }}
        </p>
      </div>
      <div class="col-6 pl-4">
        <label>{{ config?.labels[3]?.name }}</label>
        <p id="lblDirectory">{{ $store.state.enterCredentialValue[0].planfolder ? $store.state.enterCredentialValue[0].planfolder : "&#8212;" }}</p>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-6 pl-4" >
        <label> {{ config?.labels[0]?.name }}</label>
        <p class="font-size-12" id="lblAccessKey">
          &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;
        </p>
      </div>
      <div class="col-6 pl-2">
        <label>{{ config?.labels[1]?.name }}</label>
        <p class="font-size-12" id="lblSecretKey">
          &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;
        </p>
      </div>
      <div class="col-6 pl-4">
        <label>{{ config?.labels[2]?.name }}</label>
        <p id="lblBucket">{{ $store.state.enterCredentialValue[0].planbucketname }}</p>
      </div>
      <div class="col-6 pl-2">
        <label>{{ config?.labels[3]?.name }}</label>
        <p id="lblDirectory">{{ $store.state.enterCredentialValue[0].planfolder ? $store.state.enterCredentialValue[0].planfolder : "&#8212;" }}</p>
      </div>
    </div>
    <div class="col-12 pt-2 pb-2 pr-3 pl-4">
      <div class="form-check">
        <input
          id="txtreadaccept"
          type="checkbox"
          v-model="checkboxValue"
          class="custom-control-input"
          :disabled="errorTerms > 0"
          @change="checkReadAndAcceptCheckBox(config.Compatibility_Guide_URL)"
        />
        <label
          class="custom-control-label ml-10 font-size-14"
          for="txtreadaccept"
        >
          {{ $t("READ_AND_ACCEPT") }}<span v-if="config.Compatibility_Guide_URL && config.Compatibility_Guide_URL.trim() !== ''">:</span>
          </label>
      </div>
      <span v-if="errorTerms > 0" class="error_messages pl-0 ml-28 pt-02">
        {{ $t("PLEACECLICK_SINGLE_LINKBEFOR_CHECKBOX") }}
      </span>
      
      <div class="ip-guid ml-30 pl-0 font-size-14" v-if="config.Compatibility_Guide_URL && config.Compatibility_Guide_URL.trim() !== ''">
        <b id="accessguid"
          ><a
            v-on:click="
              openCompatibilityGuide(
                config.Compatibility_Guide_URL,
                index
              )
            "
          >
            <span>
              {{ config.Cloud_Provider_Short_Name }}{{ $t("IMPORT_COMPATIBILITY_GUIDE") }}
            </span>
            <img
              class="ml-1"
              :src="`${$CDN_URL}images/OpenNewWindow.svg`"
              width="14" /></a
        ></b>
      </div>
       <span v-if="errorSubmit > 0" class="error_messages pl-0 ml-28 pt-02">
        {{ errorSubmitMsg }}
      </span>
    </div>

    <div class="col-12 pt-2 pb-2 pr-3 pl-4 text-left">
      <button
        :disabled="isDisabled"
        class="btn btn-primary"
        id="btnSubmitPlan"
        @click="saveSubmitPlan(selectedProject)"
      >
        {{ $t("SUBMIT_PLAN") }}
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        id="btnSaveAsDraft"
        @click="closeModel()"
      >
        {{ $t("CANCEL") }}
      </button>
    </div>
  </div>
</template>

<script>
import EnterCredential from "../../services/enterCredentialsServiceV2";

export default {
  props: ["code", "authType", "storageProtocol"],
  data() {
    return {
      disabled: false,
      modelClose: true,
      currentStep: this.$store.state.currentStep,
      showModal: true,
      showSucessModal: false,
      awsRoleName: process.env.VUE_APP_AWS_ROLE,
      errorTerms: -1,
      errorSubmit:-1,
      errorSubmitMsg:'',
      clickedTNC: [],
      selectedProject: [],
      config: {}
    };
  },
  beforeMount() {
    let configData = this.$store.getters.getDestinationConfig(this.authType, this.storageProtocol, this.code);
    if (configData && configData.length) {
      this.config = configData[0];
      
    }
  },
  computed: {
    isDisabled() {
      return !this.disabled;
    }
  },
  created() {
    this.selectedProject = this.$store.state.selectedProject
  },
  methods: {
    closeModel() {
      this.$emit("closeSubmitModel", { position: (this.showModal = false) });
    },
    async saveSubmitPlan(selectedProject) {
      this.errorSubmit = -1;
      let credential = this.$store.getters.getCloudCredentials(selectedProject.projectID); 

      if(!credential){         
         this.errorSubmitMsg = 'Cloud bucket validation failed';
         this.errorSubmit = 1;  
         return false;      
      }
      

      let payloadValue = {
        projectID: selectedProject.projectID,
        status: "Plan Accepted",
        credential:credential
      };
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };

      let submitEnterCredential = new EnterCredential(user);
      submitEnterCredential
        .submitConfigurePlanStep2(payloadValue)
        .then((data) => {
          if (data.type === "Success" && data.code === "200") {
            this.$store.commit(
              "sucessModalMessage",
              (this.showSucessModal = true)
            );
            this.$store.commit("updateAllPlanStatus", {
              ID: selectedProject.projectID,
              status: "Plan Accepted",
            });
            this.$store.commit("updateConfigureButton", {
              ID: selectedProject.projectID,
            });
            this.$emit("closeReviewOrderModel", (this.showModal = false));
            this.$store.commit("sucessModalMessage", data.messages[0]);
          }
          else{
            this.errorSubmit = 1;
            this.errorSubmitMsg = 'Request submission failed! Error occured while processing request.';
            if(data.messages)
              this.errorSubmitMsg = data.messages[0];
          }
        });
    },
    openCompatibilityGuide(guideUrl, index) {
      this.clickedTNC.includes(index) ? "" : this.clickedTNC.push(index);
      window.open(guideUrl);
      this.errorTerms = -1;
    },
    editCredential() {
      this.currentStep = 1;
      this.$store.commit("setCurrentStep", this.currentStep);
      this.$emit("editCredentialNavigation", this.currentStep);
    },
    importDestinationPencilIcon() {
      this.currentStep = 0;
      this.$store.commit("setCurrentStep", this.currentStep);
      this.$emit("editPencilIconOnReviewPageImportDestination", this.currentStep);
    },
    closeSucessPopup() {
      this.showSucessModal = false;
      this.$emit("closeSubmitModel", { position: (this.showModal = false) });
    },
    checkReadAndAcceptCheckBox(url) {
      if (url && url.trim() !== '') {
        this.enableSumbmitButton();
      } else {
        this.clickedTNC.includes(url) ? "" : this.clickedTNC.push(url);
        this.errorTerms = -1;
        this.enableSumbmitButton();
      }
    },
    enableSumbmitButton() {
      if (this.clickedTNC.length > 0) {
        this.errorTerms = -1;
        if (this.checkboxValue) {
          this.disabled = true;
        } else if (!this.checkboxValue) {
          this.disabled = false;
        }
      } else {
        document.getElementById("txtreadaccept").checked = false;
        this.checkboxValue = false;
        this.disabled = false;
        this.errorTerms = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
  margin: 0 !important;
}
.controlHeader {
	font-weight: 300;
}
.pageHeader {
	font-weight: 600;
	font-size: medium;
}
.divider {
	height: 1px;
	width: 100%;
	background-color: $light_grey_color;
}
.font-size-12 {
	font-size: 12px !important;
}
.pointer {
	cursor: pointer;
}
.error_messages {
  line-height: 11px;
}
</style>