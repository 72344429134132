<template>
  <div class="reviewOrder1 col-md-12 mb-0 mt-0">
    <div class="titleBox">
      <h5>{{ $t("IMPORT_DESTINATION_DETAILS") }}</h5>
    </div>
    <div class="row">
      <div class="col-6">
        <label>{{ $t("SOURCE_PROJECT") }}</label>
        <p id="lblSourceProject">{{ selectedProject?.projectInfo?.name }}</p>
      </div>
      <div class="col-6">
        <label>{{ $t("NAME") }}</label>
        <p id="lblName">
          {{ selectedProject?.uploadDestination?.uploadDestinationInfo?.name }}
        </p>
      </div>

      <div class="col-6">
        <label>{{ $t("CLOUD_DESTINATION") }}</label>
        <p id="lblCloudDestination">
          {{ selectedProject?.uploadDestination?.provider }}
        </p>
      </div>
      <div class="col-6">
        <label>{{ $t("REGION") }}</label>
        <p id="lblRegion">
          {{ selectedProject?.uploadDestination?.region }}
        </p>
      </div>

      <div class="col-12">
        <label>{{ $t("DESCRIPTION") }}</label>
        <p id="lblDescription">
          {{
            selectedProject?.uploadDestination?.uploadDestinationInfo
              ?.description ? selectedProject?.uploadDestination?.uploadDestinationInfo
              ?.description : '-'
          }}
        </p>
      </div>
    </div>
    <div>
      <TableUploadDestinations
        :selectedProject="selectedProject"
        @closeImportDestinationtableModal="closeImportDestinationModal"
        @currentStepFromUploadDestinationTable="updateCurrentStep"
      />
    </div>
  </div>
</template>
  <script>
import TableUploadDestinations from "./UploadDestinationsTable.vue";
export default {
  name: "ViewEndPoints",
  components: {
    TableUploadDestinations,
  },
  data() {
    return {
      currentStep:0,
      selectedProject: this.$store.state.selectedProject
    };
  },
  methods:{
    closeImportDestinationModal(){
    this.$emit("closeImportDestination",this.showModal=false);
    },
    // clearCredentials() {
    //   this.$emit("clearCredentials", true);
    // },
    updateCurrentStep(){
      this.currentStep = this.$store.state.currentStep;
      this.$emit("updateCurrentStepFromImportDestination", { position: this.currentStep });
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.controlHeader {
	font-weight: 300;
}
.pageHeader {
	font-weight: 600;
	font-size: medium;
}
.divider {
	height: 1px;
	width: 100%;
	background-color: $light_grey_color;
}
.font-size-12 {
	font-size: 12px !important;
}
.pointer {
	cursor: pointer;
}
</style>
