<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body p-50">
            <slot name="body">
              <div class="topSize">
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 25 25"
                  >
                    <g id="default" transform="translate(-4 -4)">
                      <path
                        id="Path_1189"
                        data-name="Path 1189"
                        d="M16.5,28A11.5,11.5,0,1,0,5,16.5,11.5,11.5,0,0,0,16.5,28Z"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#6ebe49"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <path
                        id="Path_1190"
                        data-name="Path 1190"
                        d="M11,14.7l2.7,2.7L19.1,12"
                        transform="translate(1.451 1.8)"
                        fill="none"
                        stroke="#6ebe49"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                    </g></svg
                ></span>
              </div>

              <div class="col-12 midSize">
                <h5 class="font-size-18 font-weight-medium">
                  {{ $t(message) }}
                </h5>
              </div>
              <div class="col-12 font-size-14 midSize">
                <p> {{ $t("PLAN_SUBMIT_SUCESS_MESSAGE") }} </p>
              </div>
              <div class="bottomSize">
                <button
                  class="btn btn-primary size"
                  @click="closeSucessModal()"
                >
                  <b>{{ $t("OK") }}</b>
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      sucessModalMessage: false,
      showModal: false
    };
  },
  props: ["sucessMessage"],
  computed: {
    message() {
      return this.$store.state.sucessModalMessage;
    },
  },
  methods: {
    closeSucessModal() {
      this.$emit("closeSubmitModel", this.closeSucessModel);
    },
  },
};
</script>
<style lang="scss" scoped>
.size {
	width: 100px;
	height: 47px;
}
.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
	height: auto !important;
	overflow-y: hidden;
}
.modal-container {
	width: 600px;
	margin: 0px auto;
	background-color: $white_color;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: $font-family;
}
.modal-body {
	.bi-check-circle {
		color: $primary_color;
	}
	margin: 20px 0;
	padding: 30px 50px;
	text-align: center;
}
.midSize {
	padding-top: 15px;
	padding-bottom: 15px;
}
.topSize {
	padding-top: 35px;
	padding-bottom: 15px;
}
.bottomSize {
	padding-top: 15px;
	padding-bottom: 30px;
}
</style>
