<template>
  <transition name="modal commonModal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="troubleshoot-modal" v-if="!retryValidation">
                <span>
                  <img
                    class="mb-30"
                    :src="`${$CDN_URL}images/status-icons/suspended.svg`"
                    width="40"
                  />
                </span>
                <div class="title mb-15">
                  {{ $t("CLOUD_CONNECTION_FAILED") }}
                </div>
                <p>
                  {{ $t("CLOUD_CONNECTION_DESCRIPTION") }}
                </p>
                <p class="mt-30 mb-30">
                  {{ $t("CLOUD_CONNECTION_DESCRIPTION_IP") }}
                  <b>
                    <a
                      :href="config.IP_ADDRESS_ACCESS_GUIDE_URL"
                      target="_blank"
                    >
                      {{ $t("IP_ADDRESS_ACCESS_GUIDE") }}
                      <img
                        :src="`${$CDN_URL}images/OpenNewWindow.svg`"
                        width="14"
                      />
                    </a>
                  </b>
                </p>
                <button
                  class="btn mx-auto d-block m-3 btn-seagate-primary"
                  @click="retryValidationAction"
                >
                  {{ $t("RETRY_VALIDATION") }}
                </button>
                <a class="mx-auto d-block cancel-link" @click="closeModel">
                  {{ $t("CANCEL") }}
                </a>
              </div>
              <div class="retry-success" v-else>
                <span>
                  <img
                    class="mb-30"
                    :src="
                      require(`@/assets/images/troubleshoot-in-progress.svg`)
                    "
                    width="40"
                  />
                </span>
                <div class="title mb-15">
                  {{ $t("VALIDATING_CLOUD_CONNECTION") }}
                </div>
                <p>
                  {{ $t("VALIDATING_DESCRIPTION") }}
                </p>
                <button
                  class="btn mx-auto d-block mt-4 btn-seagate-primary"
                  @click="closeModel"
                >
                  {{ $t("OK") }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
  <script>
var config = require("@/services/settings.json");
import PlanConnectivityServiceV2 from "@/services/planConnectivityServiceV2.js";

export default {
  props: ["troubleshootPlanId"],
  data() {
    return {
      config: config,
      retryValidation: false,
    };
  },
  methods: {
    async retryValidationAction() {
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let payload = {
        _id: this.troubleshootPlanId,
        status: "Cloud Validation",
      };
      let planConnectivityServiceV2 = new PlanConnectivityServiceV2(user);
      planConnectivityServiceV2.updatePlanStatus(payload).then((data) => {
        if (data) {
          this.$store.commit("updatePlanStatus", {
            ID: this.troubleshootPlanId,
            status: "Cloud Validation",
          });
          this.retryValidation = true;
        }
      });
    },
    closeModel() {
      this.$emit("closeModel");
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-container {
	width: 600px;
}
.troubleshoot-modal {
	padding: 19px 42px;
	text-align: center;
}
.title {
	font-size: 18px;
	font-weight: 600;
}
.bi-exclamation-triangle {
	color: $alert_color;
	font-size: 33px;
}
.retry-success {
	padding: 19px 90px;
	text-align: center;
}
.cancel-link {
	width: max-content;
	padding: 0 10px;
	margin-top: 20px;
}
</style>