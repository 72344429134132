<template>
  <div>
    <div class="md-stepper-horizontal green">
      <div
        v-for="(step, index) in steps"
        :key="index"
        :class="{
          'md-step': true,
          active: step.activeStates.includes(plan.status),
          done: step.doneStates.includes(plan.status)
        }"
      >
        <div class="md-step-circle">
          <svg
            width="2.2em"
            height="2.2em"
            viewBox="0 0 16 16"
            class="bi bi-check"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
            ></path>
          </svg>
          <svg
            class="disableSvg"
            xmlns="http://www.w3.org/2000/svg"
            width="1.8em"
            height="1.8em"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
          <span>{{ parseInt(index) + 1 }}</span>
        </div>
        <div class="md-step-title">
          {{ $t(step.name) }}
          <span
            v-tooltip.bottom="{
              value: step.toolTipTitle,
              class: 'custom-tooltip-bottom'
            }"
            ><i class="bi bi-info-circle"></i
          ></span>
          <div class="mt-10" v-if="step.name == 'IMPORT_STARTED'">
            <span v-if="inProgressStatus.includes(plan.status)" class="font-weight-400">{{plan.importStartedOn ? $filters.dateFormat(plan.importStartedOn) : "&#8212;"}}</span>
            <span v-else class="font-weight-400">&#8212;</span>
          </div>
          <div
            class="mt-10"
            v-if="step.name == 'VALIDATION_OF_IMPORT_COMPLETION'"
          >
            <button id="btnnextButton" class="btn btn-primary" v-if="plan.status ==='Awaiting Confirmation'" @click="showConfirmImportModal = true">
              {{ $t("CONFIRMATION_NEEDED") }}
            </button>
            <button class="confirm-button confirm-btn-color" v-else-if="plan.status ==='Ready for Cryptographic Erase' || plan.status === 'Crypto Error' || plan.status === 'Completed'">
              {{$t("CONFIRMED")}}
            </button>
            <span v-else class="font-weight-400"
              >{{"&#8212;"}}</span
            >
          </div>
          <div
            class="mt-10"
            v-if="step.name == 'READY_FOR_CRYPTOGRAPHIC'"
          >
            <span v-if="plan.status ==='Completed'" class="font-weight-400"
              >{{$t("CRYPTOGRAPHIC_ERASE_CONFIRMATION")}}</span
            >
            <span v-else class="font-weight-400"
              >{{"&#8212;"}}</span
            >
          </div>
          <div class="mt-10" v-if="step.name == 'IMPORT_CLOSED'">
            <span v-if="plan.status === 'Completed'" class="font-weight-400">{{plan.completedOn ? $filters.dateFormat(plan.completedOn) : "&#8212;"}}</span>
            <span v-else class="font-weight-400" else>&#8212;</span>
          </div>
        </div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
    </div>
    <ConfirmImportModal
      v-if="showConfirmImportModal"
      :selectedPlanDetails="plan._id"
      @closeModel="showConfirmImportModal = false"
    ></ConfirmImportModal>
  </div>
</template>
<script>import ConfirmImportModal from "../project/ConfirmImportModal.vue";
export default {
  data() {
    return {      
      steps: [
        {
          name: "IMPORT_STARTED",
          activeStates: ['In Progress'],
          doneStates: ['Awaiting Confirmation', 'Crypto Error', 'Ready for Cryptographic Erase', 'Completed'],
          toolTipTitle: this.$t("IMPORT_STARTED_TOOLTIP")
        },
        {
          name: "VALIDATION_OF_IMPORT_COMPLETION",
          activeStates: ['Awaiting Confirmation'],
          doneStates: ['Ready for Cryptographic Erase', 'Crypto Error', 'Completed'],
          toolTipTitle: this.$t("VALIDATION_OF_IMPORT_COMPLETION_TOOLTIP")
        },
        {
          name: "READY_FOR_CRYPTOGRAPHIC",
          activeStates: ['Ready for Cryptographic Erase', 'Crypto Error'],
          doneStates: ['Completed'],
          toolTipTitle: this.$t("READY_FOR_CRYPTOGRAPHIC_TOOLTIP")
        },
        {
          name: "IMPORT_CLOSED",
          activeStates: [],
          doneStates: ['Completed'],
          toolTipTitle: this.$t("IMPORT_CLOSED_TOOLTIP")
        },
      ],
      showConfirmImportModal: false,
      inProgressStatus: ['In Progress', 'Awaiting Confirmation', 'Crypto Error', 'Ready for Cryptographic Erase', 'Completed']
    };
  },
  components: {
    ConfirmImportModal,
  },
  props: ["plan"]  
};
</script>
<style scoped>
.md-stepper-horizontal .md-step.active .md-step-circle {
  background: #6ebe49;
}
.md-stepper-horizontal .md-step.active .md-step-circle,
.md-stepper-horizontal .md-step.md-step.done .md-step-circle {
  border: 3px solid  #6ebe49;
}
.md-stepper-horizontal .md-step .md-step-circle {
  border: 4px solid #e2e2e2;
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right{
  border-top: 4px solid #e2e2e2;
}
.md-stepper-horizontal .md-step.done .md-step-bar-right,
.md-stepper-horizontal .md-step.active .md-step-bar-left,
.md-stepper-horizontal .md-step.done .md-step-bar-left {
  border-top: 4px solid #6ebe49;
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
  top: 26px;
}
.md-stepper-horizontal .md-step .md-step-circle {
  border-width: 3px;
  width: 40px;
  height: 40px;
  line-height: 32px;
}
.md-stepper-horizontal .md-step .md-step-circle span {
  font-size: 18px;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
  margin-left: 20px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
  margin-right: 20px;
}
.md-stepper-horizontal .md-step.done .md-step-circle svg {
  top: 1px;
  left: 1px;
}
.confirm-button{
  pointer-events: none;
}
.confirm-btn-color{
  background-color: #ecf6e6;
  padding: 15px 30px;
  border-radius: 5px;
}
</style>
