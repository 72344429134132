import {HotspotService} from './hotspot-service';
export default class PlanConnectivityServiceV2 extends HotspotService{

  async updatePlanStatus(payload) {
    try {
      let response = await this.axios.request({
        method: "put",
        url: `${this.API_BASE_URL}/api/v1/Plan/Connectivity`,
        data: {
          "payload" : payload
        },
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }

  async printShippingLabel(payload){
    try {
      let response = await this.axios.request({
        method: "post",
        url: `${this.API_BASE_URL}/api/v1/Shipping/Label`,
        data: payload,
      });
      return response.data;
    } catch (error) {
      return(error.response.data);
    }
  }

  async rePrintShippingLabel(planId){
    try {
      let response = await this.axios.request({
        method: "get",
        url: `${this.API_BASE_URL}/api/v1/RePrint/Shipping/Label/${planId}`
      });
      return response.data;
    } catch (error) {
      return(error);
    }
  }
}