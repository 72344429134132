<template>
  <footer>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <ul class="footerMenus">
            <li>
              <a
                @click="openLink(url.LEGAL)"
              >{{ $t("LEGAL") }}
            </a>
            </li>
            <li>
              <a
                @click="openLink(url.PRIVACY)"
              >{{ $t("PRIVACY") }}</a>
            </li>
            <li>
              <a
                @click="openLink(url.VULNERABILITYDISCLOSURE)"
              >{{ $t("VULNERABILITYDISCLOSURE") }}</a>              
            </li>
            <li>
              <a id="ot-sdk-btn" href="#" class="ot-sdk-show-settings">{{
                $t("COOKIESSETTINGS")
              }}</a>
            </li>
            <li>
              <a
                @click="openLink(url.CONTACTME)"
              >{{ $t("CONTACTME") }}</a>    
            </li>
            <li class="float-right">
              &#169; {{ currentYear }} &nbsp;{{
                $t("GLOBL_SEGATETECHNOLOGY_LLC")
              }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
var config = require("../services/settings.json");
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(), // 2022
      url: config.FOOTER_URLS,
    };
  },
  methods: {
    openLink(link) {
      let locale = this.$store.state.defaultLocale;
      window.open(link + `stxLoc=${locale}`, '_blank');
    },
  }
};
</script>
<style lang="scss" scoped>
footer {
	text-align: left;
	color: $white_color;
}
</style>
