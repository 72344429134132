<template>
  <header>
    <div
      id="topBar"
      class="withTopBarLogo"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <img
              :src="`${$CDN_URL}images/seagate-logo.png`"
            />
          </div>
          <div class="col d-flex justify-content-end align-items-center pl-0">
            <ul class="rightMenus">
              <li class="languageButton">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#languageSelectModal"
                >
                  <i class="bi bi-globe"></i>
                  <span
                    >{{ $store.getters.getDefaultCountry }} ({{ $store.getters.getDefaultLanguage }})</span
                  >
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      id="mainHeaderBar"
      class="withTopBarLogo"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-2 col-lg-3 pr-0">
            <a class="PrimaryNav-logo" @click="reload"
              ><img
                class="PrimaryNav-logo"
                :src="`${$CDN_URL}images/lyve-logo.png`"
            /></a>
          </div>
          <div
            class="
              col-md-10 col-lg-9
              d-flex
              justify-content-end
              align-items-center
            "
          >
            <div class="projectCreationMenu" v-if="userRole">
              <ul class="rightSideMenu">
                <li>
                  <a :href="lyveservices_url">{{ $t("LYVE_SERVICES") }}</a>
                </li>
              </ul>
              <ul class="cartMenu">
                <li class="userIcon">
                  <div class="dropdown">
                    <button
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="bi bi-person" />
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a class="dropdown-item" :href="myaccount_url">
                        {{ $t("ACCOUNT") }}
                      </a>
                      <a v-if="canAccessBilling.includes(userRole) && !isMarketPlace" class="dropdown-item" :href="billing_url">
                        {{ $t("BILLING") }}
                      </a>
                      <a v-if="canAccessUserManagement.includes(userRole)" class="dropdown-item" :href="user_management_url">
                        {{ $t("USERMANGEMENT") }}
                      </a>
                      <a class="dropdown-item" @click="logout">
                        {{ $t("GLOBAL_SIGNOUT") }}
                      </a>
                    </div>
                  </div>
                </li>
                <li class="pl-15">
                  <div class="dropdown">
                    <button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="bi bi-question-circle" />
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" :href="lyve_management_portal_url" target="_blank">
                        {{ $t("LYVE_MANAGEMENT_PORTAL") }}
                      </a>
                      <a class="dropdown-item" :href="cloud_import_service_guide_url" target="_blank">
                        {{ $t("CLOUD_IMPORT_SERVICE_TITLE") }}
                      </a>
                      <a class="dropdown-item" :href="lyve_cloud_help_url" target="_blank">
                        {{ $t("LYVE_CLOUD") }}
                      </a>
                      <a class="dropdown-item" :href="lyve_mobile_products_url" target="_blank">
                        {{ $t("LYVE_MOBILE_PRODUCTS") }}
                      </a>
                      <a class="dropdown-item" :href="LyveSupportCenter" target="_blank">
                        {{ $t('LYVE_SUPPORT_CENTER') }}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      id="getStartedModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="getStartedModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{{ $t("GETSTARTED") }}</h3>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" data-v-8dc7cce2="">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12">
              <div class="registerContentBoxPopup">
                <h4
                  class="titleText"
                  style="cursor: pointer"
                  @click="signupFromAuth0('yes')"
                >
                  {{ $t("LANDING_REGISTER_AS_A_MSP_RESELLER") }}
                  <span><i class="titleArrow mSP" /></span>
                </h4>
                <p>
                  {{ $t("CREATEPRO_BEHALFOF_YOUR_CUSTOMER") }}
                </p>
              </div>
            </div>
            <div class="col-md-12">
              <div class="registerContentBoxPopup">
                <h4
                  class="titleText"
                  style="cursor: pointer"
                  @click="signupFromAuth0('no')"
                >
                  {{ $t("LANDING_REGISTER_AS_A_BUSINESS") }}
                  <span><i class="titleArrow" /></span>
                </h4>
                <p>
                  {{ $t("CREATE_PRO_BEHALFOF_YOUR_COMPANY") }}
                </p>
              </div>
            </div>
            <div class="col-md-12">
              <div class="alreadySignIn">
                <span
                  >{{ $t("LYVE_PORTAL_CUSTOMER_EXIST") }}
                  <a class="link" @click="loginFromAuth0">{{
                    $t("GLOBAL_SIGN_IN")
                  }}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <!-- language selector Modal-->
    <div
      id="languageSelectModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="languageSelectModal"
      aria-hidden="true"
    >
      <language-selector></language-selector>
    </div>
    <!-- language selector Modal-->
  </header>
</template>
<script>
import UserService from "@/services/user-service-v2";
import LanguageSelector from '@/controls/LanguageSelector';
const config = require("@/services/settings.json");
export default {
  components: {LanguageSelector},
  data() {
    return {
      home_url: process.env.VUE_APP_HOME_URL,
      lyveservices_url: process.env.VUE_APP_LYVESERVICES_URL,
      projects_url: process.env.VUE_APP_PROJECTS_URL,
      user_management_url: process.env.VUE_APP_USERMANGEMENT_URL,
      billing_url: process.env.VUE_APP_BILLING_URL,
      myaccount_url: process.env.VUE_APP_MYACCOUNT_URL,
      userRole: 0,
      canAccessUserManagement: [1, 2],
      canAccessBilling: [1, 2, 4, 6],
      cloud_import_service_guide_url: config.CLOUD_IMPORT_SERVICE_GUIDE,
      lyve_management_portal_url: config.LYVE_MANAGEMENT_PORTAL,
      lyve_cloud_help_url: config.LYVE_CLOUD_HELP,
      lyve_mobile_products_url: config.LYVE_MOBILE_PRODUCTS,
      LyveSupportCenter: config.LYVE_SUPPORT_CENTER,
      isMarketPlace: false
    };
  },
  created() {
    this.getUserPermissions();
  },
  methods: {
    async getUserPermissions() {
      let accessToken = await this.$auth.getTokenSilently();
      accessToken = `Bearer ${accessToken}`;
      let user = { token: accessToken };
      let userService = new UserService(user);
      await userService.getUserPermissions().then((data) => {
        if (data.code === "200" && data.type === "Success") {
          this.userRole = data.data.roleID;
          this.isMarketPlace = data.data.isMarketPlace && (data.data.isMarketPlace === true || data.data.isMarketPlace === 'true') ? true : false;
          this.$store.commit("setUserRole", data.data.roleID);
        }
      });
    },
    loginFromAuth0() {
      this.$auth.loginWithRedirect();
    },
    signupFromAuth0(isReseller) {
      var source =
        this.$route.query.source &&
        this.$route.query.source != null &&
        this.$route.query.source != "" &&
        this.$route.query.source.toLowerCase() == "ingram"
          ? "LyveHub_Ingram"
          : "";
      if (source != "") localStorage.setItem("source", source);
      else localStorage.removeItem("source");
      var lang = "en-US";
      this.$auth.loginWithRedirect({
        action: "signup",
        isReseller,
        lang: lang.locale,
      });
    },
    async logout() {
      await this.$auth.logout();
    },
    reload() {
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
header {
	text-align: left;
	#topBar.withTopBarLogo.not-authenticated {
		padding: 15px 0 15px;
		height: 80px;
		display: flex;
		align-items: center;
	}
  #mainHeaderBar .dropdown .dropdown-menu {
    padding: 0px;
    border-radius: 3px;
    overflow: inherit;
    border: 0px;
    top: 25px !important;
    left: 20px!important;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    max-width: 280px;
    min-width: 280px;
  }
  #mainHeaderBar .dropdown .dropdown-menu::before {
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f8f9fa;
    content: "";
    position: absolute;
    top: -10px;
    right: 20px;
  }
}

</style>
