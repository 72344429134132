const axios = require("axios").default;

export class HotspotService {
  constructor(user) {
    this.user = user;
    this.axios = axios;
    this.API_BASE_URL = process.env.VUE_APP_HOTSPOT_API_DOMAIN;
    //send account id in header
    this.accountId = this.getCookie('accountId');

    axios.interceptors.request.use((request) => {
      request.headers =  {
        "content-type": "application/json",
        Authorization: this.user.token,
        accountId: this.accountId
      };
      return request;
    });
  }

  getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
      let [key,value] = el.split('=');
      cookie[key.trim()] = value;
    })
    return cookie[cookieName];
  }
}