<template>
  <div class="container-fluid landing-page-wrapper">
    <div class="row text-left d-flex">
      <div class="col-md-6 mt-30 mb-30">
        <h3 class="mt-30">
          {{ $t("WHAT_IS_STORAGE_SERVICE") }}
        </h3>
        <p class="font-size-16">{{ $t("WHEN_YOU_SELECT_STORAGE_SERVICE") }}</p>
        <h3 class="mt-30">
          {{ $t("HOW_DOES_IT_WORK") }}
        </h3>
        <p class="font-size-16">{{ $t("TO_START_A_CLOUD_IMPORT_PLAN") }}</p>
        <p class="font-size-16">
          {{ $t("TO_LEARN_MORE") }}
          <a
            class="urlLink"
            :href="cloud_guide_url"
            target="_blank"
          >
            {{ $t("CLOUD_IMPORT_SERVICE") }}
            <img
              class="ml-5 mb-6"
              :src="`${$CDN_URL}images/OpenNewWindow.svg`"
              width="14"
            />
          </a>
        </p>
        <div class="buttonBox mt-40">
          <button
            type="button"
            class="btn btn-seagate-primary blackBg arrowOuter"
            @click="goToProjectPage(get_started_url)"
            v-if="isVisible"
          >
            <span>{{ $t("GETSTARTED") }} <i class="actualArrow"></i></span>
          </button>
        </div>
      </div>
      <div class="col-md-6 landingPageImgBox">
        <img :src="hotspotLadingImage" @error="alert('Error')" />
      </div>
    </div>
  </div>
</template>
<script>
var config = require('../services/settings.json');
export default {
  computed: {
    isVisible() {
      return ![3, 5].includes(this.$store.state.userrole)
    }
  },
  data() {
    return {
      hotspotLadingImage: require(`../assets/images/hotspot_import_plan.jpg`),
      cloud_guide_url: config.CLOUD_IMPORT_SERVICE_GUIDE,
      get_started_url:  process.env.VUE_APP_GET_STARTED_URL
    };
  },
  methods:{
    goToProjectPage(url) {
      window.open(url, "_self");
    }
  }
};
</script>
<style lang="scss" scoped>
@media (min-width: 1600px) {
	.container {
		max-width: 1400px;
	}
}
</style>