<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body p-50">
            <slot name="body">
              <div v-if="message.severity === 'info'">
                <i class="bi bi-check-circle"></i>
                <p class="font-size-18 sub-heading">
                  {{ $t("IMPORT_DESTINATION_SUCC") }}
                </p>
              </div>
              <div v-if="message.severity === 'error'">
                <i class="bi bi-x-circle"></i>
                <p class="font-size-18 sub-heading">{{ $t("ERROR") }}</p>
              </div>
              <div>
                <p class="message">{{ $t(message.content) }}</p>
              </div>

              <button
                class="btn btn-seagate-primary"
                v-if="message.severity === 'info'"
                @click="$emit('openPopup')"
              >
                OK
              </button>
              <div v-if="message.severity === 'error'">
                <button
                  class="btn btn-seagate-primary"
                  @click="$emit('openPopup')"
                >
                  {{ $t("CANCEL") }}
                </button>
                <p class="pt-20">
                  <a @click="$emit('close', 'openPopup')">{{ $t("EDIT") }}</a>
                </p>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  emits: ["openPopup"],
  computed: {
    message() {
      return this.$store.state.responseMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
	padding: 0px 0 30px 0;
	font-size: 14px;
	color: $msg_color;
	font-weight: 300;
}
.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
	height: auto !important;
	overflow-y: hidden;
}
.modal-container {
	width: 600px;
	margin: 0px auto;
	background-color: $background-color;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: $font-family;
}
.modal-body {
	.btn {
		margin-right: 0px;
	}
	.bi {
		font-size: 40px;
	}
	.bi-check-circle {
		color: $primary_color;
	}
	.bi-x-circle {
		color: $red_color;
	}
	margin: 20px 0;
	padding: 35px 100px;
	text-align: center;
	h5 {
		font-size: 18px !important;
		color: $title_color;
		font-weight: 400;
		padding-top: 30px;
	}
}
.modal-default-button {
	float: right;
}
.pt-20 {
	padding-top: 20px;
}

</style>
