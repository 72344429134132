<template>
  <transition name="modal commonModal">
    <div class="modal-mask confirmImportModal">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="error-body">
                <div class="title mb-15">
                  {{ $store.state.errorDialogTitle }}
                </div>
                <div class="body m-10">{{ $store.state.errorDialogBody }}</div>
                <button
                  class="btn mx-auto d-block mt-30 btn-seagate-primary"
                  @click="closeModel"
                >
                  {{ $t("Close") }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  methods: {
    closeModel() {
      this.$emit("closeModel");
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-weight: 500;
  font-size: 18px;
}
</style>